import React from 'react'
import PropTypes from 'prop-types'

import {
  CustomIcon,
  Div,
  DefaultParagraph as Paragraph,
  Span,
  Button,
  DefaultImage as Image
} from '../../components'

import { ReactComponent as IconCalendar } from '../../assets/icon_calendar.svg'

import {
  Wrapper
} from './styles'

const getStatusLabel = (status = '') => {
  return status
}

const Steps = (props) => {
  const {
    total,
    current
  } = props

  return total ? <Div className='steps'>
    {Array.from({ length: total }, (v, k) => k).map((mappedItem, index) => {
      const key = `steps-item-${index}-${new Date().getTime()}`

      return <Div key={key} className={`${index + 1 <= current ? 'active' : ''}`}></Div>
    })}
  </Div> : null
}

export function Order(data) {
  const {
    id,
    storeLogo,
    storeName,
    time,
    status,
    modalityId,
    stepsTotal,
    currentStep,
    handleOrder,
    schedule,
    scheduling
  } = data

  const modality = modalityId === 2 ? 'Retirada' : 'Delivery'

  const statusLabel = getStatusLabel(status)

  return <Wrapper className={`${status === 'PEDIDO PRONTO' ? 'ready' : ''}`} id={`home-order-tracking-items-item-${id}`}>
    <Div className='first'>
      <Image src={storeLogo} alt={storeName} />
    </Div>

    <Div className='second'>
      <Paragraph>{scheduling ? `#${id}` : modality} | <Span>{scheduling ? 'Pedido Confirmado' : statusLabel}</Span></Paragraph>
      <Paragraph>{scheduling ? <>
        <IconCalendar />{schedule} - {storeName} </> : <>
        {time} - {storeName}</>}
      </Paragraph>
      <Steps total={scheduling ? 1 : stepsTotal} current={scheduling ? 1 : currentStep} />
    </Div>

    <Div className='third'>
      <Button onClick={() => {
        handleOrder({
          id,
          modalityId
        })
      }}>
        <CustomIcon name='AngleRight' />
      </Button>
    </Div>
  </Wrapper>
}

Steps.propTypes = {
	total: PropTypes.number,
	current: PropTypes.number
}
