import { actionTypes } from '.'

const localStorageKey = 'loading'

const initialState = {
	loading: null
}

const reducer = (state = initialState, action) => {
  const localStorageData = JSON.parse(localStorage.getItem(localStorageKey)) || {}
  const loadedState = {
    ...state,
    ...localStorageData
  }

  const newState = {
    value: loadedState
  }

  switch (action.type) {
    case actionTypes.SET_LOADING:
      if (!action.payload) {
        Object.assign(newState, {
          value: {
            ...newState.value,
            loading: []
          }
        })
      }

      if (typeof action.payload === 'string' && !!action.payload) {
        const loading = newState.value.loading && newState.value.loading.length ? [...newState.value.loading, action.payload] : [action.payload]

        Object.assign(newState, {
          value: {
            ...newState.value,
            loading
          }
        })
      }

      if (action.payload === false) {
        Object.assign(newState, {
          value: {
            ...newState.value,
            loading: []
          }
        })
      }

      if (typeof action.payload === 'object') {
        const loading = newState.value.loading || []

        Object.assign(newState, {
          value: {
            ...newState.value,
            loading: loading.filter(filteredItem => filteredItem !== action.payload.item)
          }
        })
      }

      break

    default:
  }

  localStorage.setItem(localStorageKey, JSON.stringify(newState.value))

  return newState.value
}

export default reducer
