import React, {
	useCallback,
	useEffect,
	useState
} from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'

import history from '../../../history'

import { ReactComponent as FavoriteAddressIcon } from '../../assets/icons/icon_favorite_address.svg'
import { ReactComponent as MoreOptionsIcon } from '../../assets/icons/icon_more_options.svg'
import { ReactComponent as EditIcon } from '../../assets/icons/icon_edit.svg'
import { ReactComponent as DeleteIcon } from '../../assets/icons/icon_delete.svg'
import { ReactComponent as HomeCategoryIcon } from '../../assets/icons/icon_address_home_category.svg'
import { ReactComponent as WorkCategoryIcon } from '../../assets/icons/icon_address_work_category.svg'

import {
	pipe,
	GetContent
} from '../../../domain/helpers'

import {
	useCustomLayout
} from '../../hooks'

import {
	CustomLoading,
} from '../../components'

import {
	handleGTM,
	setNotification,
	setUserHistory,
	fetchStoreFees,
	removeProduct,
	addProduct
} from '../../../redux/actions/main'

import {
	Wrapper,
	ContentWrapper,
	LoadingContainer,
	AddressList,
	AddressCard,
	AddressCardSideIcon,
	AddressCardContent,
	AddressCardActions,
	AddressAdditional,
	AddressState,
	AddressValue,
	NumberAddress,
	StreetAddress,
	AddressCardEditionActions,
	MobileHeaderWrapper,
	H1,
	CloseButton
} from './styles'

import { ReactComponent as IconArrowLeft } from '../../assets/icons/icon_arrow_left.svg'

import { CustomTitle } from './../../components/CustomTitle'
import { NewAddress } from '../NewAddress'
import { CustomModalNotification } from '../../components/CustomModalNotification'
import {
	userAddress,
	loading,
	store,
	addressActions,
	menuActions,
	store as storeActions,
} from '../../../state'

import { AddressPostalCode } from '..'
import { MULTI_STORE } from '../../../config'
import { useSnackbar } from 'react-simple-snackbar'
import { errorOptions, successOptions } from '../../../infra/utils/styleNotification'

export const Context = React.createContext({})

export function AddressesPage(props) {
	const {
		loading,
		setLoading,
		device,
		address,
		userAddresses,
		deleteUserAddress,
		fetchUserAddresses,
		setAddress,
		setCEP,
		setUserHistory,
		handleCloseDialogAddress,
		removeUserAddressesItem,
		fetchStore,
		fetchStoreFees,
		modalityId,
		userHistory,
		noAddressesList,
		addProduct,
		removeProduct,
		userCallback,
		setSelectedProduct,
		setAddressRequest,
		setNoAddressesList,
		fetchGeolocationByAddress,
		cartClicked,
		accessToken
	} = props

	const [selectedItem, setSelectedItem] = useState('')
	const [deleteAddressModal, setDeleteAddressModal] = useState(false)
	const [deleteAddressModalEgual, setDeleteAddressModalEqual] = useState(false)
	const [openSuccessSnackbar] = useSnackbar(successOptions({ modal: false }))
	const [openErrorSnackbar] = useSnackbar(errorOptions({ modal: false }))

	const dispatch = useDispatch()
	const setStoresListRequest = (args) => dispatch(storeActions.setStoresListRequest(args))

	function handleCloseDialogDeleteAddress() {
		setDeleteAddressModal(false)
	}

	function handleCloseDialogDeleteEqual() {
		setDeleteAddressModalEqual(false)
	}

	const handleAddressEdit = ({
		id,
		number,
		address,
		neighborhood,
		complement,
		reference,
		postalCode,
		city,
		state,
		category
	}) => {
		setUserHistory({
			back: 'addresses',
			nex: 'home'
		})

		setAddress(null)
		setAddress({
			id,
			main: address,
			number,
			neighborhood,
			complement,
			reference,
			cep: postalCode,
			city,
			state,
			type: category
		})
	}

	const handleAddressDelete = ({
		id
	}) => {
		setLoading('addresses')
		setAddress({
			id
		})

		setSelectedItem(null)

		deleteUserAddress([{
			name: 'delete-user-address',
			type: 'error',
			callback: () => {
				window.alert('Ocorreu um erro ao excluir o endereço selecionado.')
			}
		}, {
			name: 'delete-user-address',
			type: 'success',
			callback: () => {
				removeUserAddressesItem({ id })
			}
		}, {
			name: 'delete-user-address',
			type: 'data',
			data: {
				id
			}
		}])
	}

	const postCEP = useCallback((args) => {
		setCEP(args)
	}, [
		setCEP
	])

	const fetchCategoryAddress = (category) => {
		if (category === 1) {
			return <WorkCategoryIcon />
		} else if (category === 2) {
			return <FavoriteAddressIcon />
		} else {
			return <HomeCategoryIcon />
		}
	}

	async function handleStore(id) {
		await fetchStore(() => {
			setLoading({ item: 'store', delete: true })
		})

		if (id === 4) {
			getStoreFees()
		}
	}

	async function getStoreFees(callback) {
		const callbackErrorFilter = callback && callback.filter(filteredItem => filteredItem.type === 'error')
		const callbackErrorItem = callbackErrorFilter && callbackErrorFilter[0]
		const callbackError = callbackErrorItem && callbackErrorItem.callback

		const callbackSuccessFilter = callback && callback.filter(filteredItem => filteredItem.type === 'success')
		const callbackSuccessItem = callbackSuccessFilter && callbackSuccessFilter[0]
		const callbackSuccess = callbackSuccessItem && callbackSuccessItem.callback

		await fetchGeolocationByAddress([{
			name: 'geolocation-by-address',
			type: 'error',
			callback: () => {
				if (callbackSuccessItem.name !== 'store-fees') {
					callbackError && callbackError()
				}
			}
		}, {
			name: 'geolocation-by-address',
			type: 'success',
			callback: async () => {
				if (callbackSuccessItem.name !== 'store-fees') {
					callbackSuccess && callbackSuccess()
				}

				await fetchStoreFees(callback)
			}
		}])
	}

	useCustomLayout({
		device,
		backgroundColor: '#fff'
	})

	useEffect(() => {
		setTimeout(() => {
			fetchUserAddresses()
		}, 1000)

		return () => { }
	}, [
		fetchUserAddresses
	])

	const selectAddressUser = async (data) => {
		const {
			id,
			address: main,
			number,
			neighborhood,
			city,
			latitude,
			longitude,
			cep,
			state
		} = data

		setAddress({
			main,
			number,
			neighborhood,
			verified: true,
			city,
			latitude,
			longitude,
			id,
			cep,
			state
		})

		await getStoreFees([{
			name: 'store-fees',
			type: 'error',
			callback: () => {
				openErrorSnackbar('Endereço fora da área de serviço! :(', 7000)
				setAddress({})
				setAddressRequest(true)
			}
		}, {
			name: 'store-fees',
			type: 'success',
			callback: () => {
				if (MULTI_STORE === 'true') {
					setAddress({
						main,
						number,
						neighborhood,
						verified: true,
						city,
						latitude,
						longitude,
						id
					})
					openSuccessSnackbar('Sucesso ao selecionar o endereço!', 7000)

					handleCloseDialogAddress()
					setAddressRequest(false)
					setStoresListRequest(true)
				} else {
					setAddress({
						main,
						number,
						neighborhood,
						verified: true,
						city,
						latitude,
						longitude,
						id
					})
					openSuccessSnackbar('Sucesso ao selecionar o endereço!', 7000)

					if (userCallback && userCallback.name && userCallback.name === 'add-product') {
						removeProduct(userCallback.data)
						addProduct(userCallback.data)

						cartClicked()
						setSelectedProduct(null)
						history.push('/')
					}

					setAddressRequest(false)
					setNoAddressesList(false)

					const {
						back
					} = userHistory

					if (handleCloseDialogAddress && typeof handleCloseDialogAddress === 'function') {
						handleCloseDialogAddress()
					} else if (back && back !== 'addresses') {
						history.push(`/${back}`)
					} else {
						history.push('/')
					}
				}
			}
		}])
	}
	return <Wrapper>
		{loading.includes('user-addresses') &&
			<LoadingContainer>
				<CustomLoading
					type={'spin'}
					id='default-loading'
					height={40}
					width={40}
				/>
			</LoadingContainer>}

		{!loading.includes('user-addresses') && <ContentWrapper>
			{address && !address.id && <MobileHeaderWrapper>
				<CloseButton onClick={() => {
					if (handleCloseDialogAddress && typeof handleCloseDialogAddress === 'function') {
						handleCloseDialogAddress()
					} else {
						const {
							back
						} = userHistory

						if (back && back !== 'addresses') {
							history.push(`/${back}`)
						} else {
							history.push(`/`)
						}
					}
				}}><IconArrowLeft className='none' /></CloseButton>
				<H1>Endereço</H1>
			</MobileHeaderWrapper>}

			{userAddresses.length > 0 && <CustomTitle title="Local de entrega:"></CustomTitle>}
			{userAddresses && userAddresses.length > 0 && !noAddressesList ? <>
				<AddressList>
					{userAddresses && userAddresses.map((data, index) => {
						const key = `addresses-list-item-${index}-${data.id}`

						const {
							id,
							address: main,
							number,
							neighborhood,
							complement,
							reference,
							cep,
							city,
							state,
							category,
						} = data

						return (
							<AddressCard key={key} id={key} className={`list-item ${address && address.id === id ? 'active-address' : ''}`}>
								<AddressCardSideIcon onClick={() => selectAddressUser(data)}>
									{fetchCategoryAddress(category)}
								</AddressCardSideIcon>
								<AddressCardContent onClick={() => selectAddressUser(data)}>
									<AddressValue>
										<StreetAddress>{main},</StreetAddress>
										<NumberAddress>{number}</NumberAddress>
									</AddressValue>
									<AddressState>{neighborhood ? `${neighborhood} - ` : ''} {city ? `${city} - ` : ''} {state}</AddressState>
									<AddressAdditional>{complement ? `${complement} - ` : ''} {reference ? `${reference} - ` : ''} </AddressAdditional>
								</AddressCardContent>
								<AddressCardActions>
									{!selectedItem || selectedItem !== key ?
										<MoreOptionsIcon onClick={() => {
											setSelectedItem(key)
										}} /> :
										<AddressCardEditionActions>
											<EditIcon onClick={() => {
												handleAddressEdit({
													id,
													main,
													number,
													neighborhood,
													complement,
													reference,
													cep,
													city,
													state,
													category
												})
											}} />
											<DeleteIcon onClick={() => {
												postCEP('')
												if (address.id === id) {
													setDeleteAddressModalEqual(true)

													return
												}

												setDeleteAddressModal(true)
											}} />
											<CustomModalNotification
												openModal={deleteAddressModalEgual}
												content={'Você não pode excluir o endereço que está usando no momento!'}
												handleCloseModal={handleCloseDialogDeleteEqual}
												size={'xs'}
												fullWidth={true}
												actions={[{
													label: 'Entendi',
													classes: 'primary',
													handleClick: () => {
														handleCloseDialogDeleteEqual(false)
													}
												}]}
											/>
											<CustomModalNotification
												openModal={deleteAddressModal}
												content={'Tem certeza que deseja excluir esse endereço?'}
												handleCloseModal={handleCloseDialogDeleteAddress}
												size={'xs'}
												fullWidth={true}
												actions={[{
													label: 'Sim',
													classes: 'primary',
													handleClick: () => {
														handleAddressDelete({ id })
													}
												}, {
													label: 'Não',
													classes: 'secondary',
													handleClick: () => {
														handleCloseDialogDeleteAddress(false)
													}
												}]}
											/>
										</AddressCardEditionActions>
									}
								</AddressCardActions>
							</AddressCard>
						)
					})}
				</AddressList>
			</> : null}

			{address && !address.id ? <NewAddress
				closeButton={true}
				handleCloseDialogAddress={handleCloseDialogAddress}
				modalityId={modalityId}
				handleStore={handleStore}
				getStoreFees={getStoreFees}
				cartClicked={cartClicked}
			/> : <AddressPostalCode
				title={accessToken ? 'Alterar local de entrega?' : ''}
				handleCloseDialogAddress={handleCloseDialogAddress}
			/>}
		</ContentWrapper>}
	</Wrapper>
}

AddressesPage.propTypes = {
	loading: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string])),
	setLoading: PropTypes.func,
	device: PropTypes.string,
	address: PropTypes.shape({
		id: PropTypes.number
	}),
	userAddresses: PropTypes.arrayOf(PropTypes.shape({})),
	deleteUserAddress: PropTypes.func,
	fetchUserAddresses: PropTypes.func,
	setAddress: PropTypes.func,
	setCEP: PropTypes.func,
	setUserHistory: PropTypes.func,
	handleCloseDialogAddress: PropTypes.func,
	removeUserAddressesItem: PropTypes.func,
	fetchStore: PropTypes.func,
	fetchStoreFees: PropTypes.func,
	modalityId: PropTypes.number,
	userHistory: PropTypes.shape({
		back: PropTypes.string
	}),
	noAddressesList: PropTypes.bool,
	addProduct: PropTypes.func,
	removeProduct: PropTypes.func,
	userCallback: PropTypes.shape({
		name: PropTypes.string,
		data: PropTypes.shape({})
	}),
	setOpenCart: PropTypes.func,
	setSelectedProduct: PropTypes.func,
	setAddressRequest: PropTypes.func,
	setNoAddressesList: PropTypes.func,
	fetchGeolocationByAddress: PropTypes.func,
	cartClicked: PropTypes.func
}

const mapStateToProps = (state) => {
	return {
		accessToken: state.user.accessToken,
		userAddresses: state.userAddress.userAddresses || [],
		address: state.address.address,
		noAddressesList: state.address.noList,
		userHistory: (state.main.userHistory) || {},
		postalCode: state.address.cep,
		device: (state.main.params && state.main.params.device) || '',
		loading: state.loading.loading || [],
		modalityId: state.main.modality && state.main.modality.id,
		URLParameters: (state.main.URLParameters) || '',
		userCallback: (state.user.callback) || {}
	}
}

const GetConnection = connect(mapStateToProps, {
	fetchStore: store.fetch,
	setLoading: loading.setLoading,
	deleteUserAddress: userAddress.remove,
	removeUserAddressesItem: userAddress.removeUserAddressesItem,
	fetchUserAddresses: userAddress.fetch,
	addUserAddress: userAddress.add,
	setAddress: addressActions.setAddress,
	setCEP: addressActions.setCEP,
	setAddressRequest: addressActions.setAddressRequest,
	setNoAddressesList: addressActions.setNoAddressesList,
	fetchGeolocationByAddress: addressActions.fetchGeolocationByAddress,
	setSelectedProduct: menuActions.setSelectedProduct,
	fetchStoreFees,
	handleGTM,
	setNotification,
	setUserHistory,
	addProduct,
	removeProduct
})

export const Addresses = React.memo(pipe(
	GetConnection,
	GetContent({ context: Context, id: 'addresses' })
)(AddressesPage))
