export default ({
  storeRepository,
  maps,
  auth
}) => {
  return async (payload) => {
    const {
      onSuccess,
      onError,
			onEmpty,
      data,
      getState
    } = payload

    try {
      const store = await storeRepository.fetch({
        data,
        maps,
        auth: auth(),
        getState
      })

			if (store) {
				onSuccess({store})
			} else {
				onEmpty()
			}
    } catch(e) {
      console.log(e)

      onError()
    }
  }
}
