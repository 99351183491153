import React from 'react'

import { ReactComponent as IconCoupon } from '../../assets/icon_coupon.svg'

import {
  Div,
  DefaultParagraph as Paragraph,
  H2
} from '../../components'

import {
  Wrapper
} from './styles'

export function EmptyContent({
  data,
	modal
}) {
  const {
    content
  } = data

  return <Wrapper modal={modal}>
    <Div className='header'>
      <IconCoupon />
    </Div>
    <Div className='content'>
      <H2>{content && content.title}</H2>
      <Paragraph>{content && content.text}</Paragraph>
    </Div>
    <Div className='icon-content'>
      <IconCoupon />
    </Div>
  </Wrapper>
}
