import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  min-width: 100%;
  max-width: 100%;
  background: #fff;
  font-size: .875rem;
  min-height: 100%;

  & #store-coupon{
    min-width: 100%;
    max-width: 100%;
  }

  > div {
    &.view-all-products {
      max-width: calc(100% - 2rem);
      min-width: calc(100% - 2rem);
    }
  }
`

export const StoreWrapper = styled.div`
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.secondaryColor) || '#333'};
  background: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
  margin-bottom: 1.875rem;
  padding: 1.25rem;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  min-width: 100%;
  max-width: 100%;
  padding-right: 0;
  font-size: 1rem;
  font-weight: bold;

  > div {
    flex: 1;
    text-align: left;

    &:first-child {
      text-align: right;
      box-sizing: border-box;
    }
  }

  > p {
    flex: 1;
    font-size: .875rem;
    margin-left: .313rem;
    margin-right: .625rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    
    &.info {
      min-width: 13.75rem;
    }
  }
`

export const PageTitle = styled.h4`
  margin: 0;
  padding: 0;
  font-size: 1.125rem;
  color:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.secondaryColor) || '#CCC'};
  text-align: center;
`

export const BackButton = styled.button`
  margin: 0;
  padding: 0;
  background: none;
  border: 0 none;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.secondaryColor) || '#000'};
  font-size: 1rem;
  position: absolute;
  cursor: pointer;
  left: 1.25rem;
  top: 1.25rem;
  z-index: 1;
  top: 50%;
  transform: translate(0, -50%);
`

export const WrapperHeader = styled.header`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 5rem;
  background:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#CCC'};
  color:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.secondaryColor) || '#CCC'};
  margin: 0 0 1.2rem 0;
  padding: 1rem 0;

  @media (min-width: 60rem) {
    display: none;
  }
`

export const StoreNameWrapper = styled.div`
  min-width: 100%;
  max-width: 100%;
  max-height: 13.75rem;
  min-height: 13.75rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: .938rem;
  margin-bottom: 1.25rem;
`

export const Paragraph = styled.p`
  margin: 0;
  padding: 0;
  font-size: .938rem;
`

export const H1 = styled.h1`
  min-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  //border-bottom: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#e2e2e2'} .063rem solid;
  padding-bottom: .725rem;
  margin: 1rem 1.3rem 0.225rem 1.3rem;  
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#333'};
  font-size: 1.125rem;
  flex: none;
  position: relative;

  > span {
    font-size: 1rem;
    line-height: 1.25rem;
    position: absolute;
    top: 50%;
    right: 1.25rem;
    transform: translate(0, -1.25rem);
  }
`

export const MobileHeaderWrapper = styled.header`
  width: 100%;

  > button {
    &.close {
      background: none;
      padding: 0;
      margin: 0;
      position: absolute;
      right: 1.25rem;
      top: 1.25rem;
      font-size: 1.25rem;
      font-weight: 100;
    }
  }

  @media (min-width: 50rem) {
    display: none;
  }
`

export const CloseButton = styled.button`
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  background: none;
  border: 0 none;
  padding: 0;
  margin: 0;
`

export const Space = styled.div`
	min-height: 4rem;
  min-height: 4rem;
	width: 100%;
`
