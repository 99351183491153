export default ({
  apiService
}) => {
  const strategy = {
    send: async ({
      data,
      auth,
      dispatch,
      actions,
      maps
    }) => {
      const {
        setLoading
      } = actions

      const {
        loading
      } = data

      const {
        mapContactUsData,
        mapContactUsRequest
      } = maps

      if (loading && loading.includes('send-contact-us')) {
        return false
      }

      dispatch(setLoading('send-contact-us'))
  
      const url = `FaleConosco/Enviar`
      const result = await apiService.post(url, mapContactUsRequest(data), auth)
      const mappedResult = mapContactUsData(result.data)
  
      return mappedResult
    }
  }

  return {
    sendContactUs: async (payload) => {
      return await strategy['send'](payload)
    }
  }
}
