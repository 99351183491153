import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  min-width: 100%;
  max-width: 100%;

  > p {
    margin-bottom: .938rem;
  }
`
export const ContactWrapper = styled.div`
  width: 100%;
  border-bottom: 1px solid #c2c2c2;
`

export const Contact = styled.div`
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000'};
  padding: 1rem;

	@media (min-width: 60rem) {
    padding: 1rem 2rem;
  }
`
export const ScheduleWrapper = styled.div`
  width: 100%;
  border-bottom: 1px solid #c2c2c2;

  @media (min-width: 60rem) {
    box-sizing: border-box
  }
`
export const Contened = styled.div`
  @media (min-width: 60rem) {
    display: flex;
    flex-direction: row;

    &.payment{
      flex-grow: 1
    }
  } 
`

export const Schedule = styled.div`
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000'};
  padding: 1rem;

	@media (min-width: 60rem) {
    padding: 1rem 2rem;
  }
`

export const PaymentWrapper = styled.div`
  width: 100%;
  border-bottom: 1px solid #c2c2c2;

  @media (min-width: 60rem) {
    box-sizing: border-box
  }
`
export const PaymentOnline = styled.div`
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000'};
  padding: 1rem;

	@media (min-width: 60rem) {
    padding: 1rem 2rem;
  }

  > img {
    margin: 0 .7rem 1rem 0
  }
`

export const PaymentOffline = styled.div`
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000'};
  padding: 1rem;

	@media (min-width: 60rem) {
    padding: 1rem 2rem;
  }

  > img {
    margin: 0 .7rem 1rem 0
  }
`

export const Title = styled.h3`
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000'};
`
export const Paragraph = styled.p`
  min-width: 90px;

  ${props => props.today ?
    `color: ${(props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
    font-weight: bold;` :
    `color: ${(props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000'};
    font-weight: 500;`}
  
  &.label{
    font-weight: bold;
    margin: .5rem .6rem .5rem 0;
  }

  &.itens{
    margin: .5rem 0;
  }

  &.phone{
    min-width: 30rem;
  }
`
