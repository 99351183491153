import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 1.5rem 1.5rem;
  border-bottom: .15rem solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.grey00) || '#EEE'};

  @media (min-width: 50rem) {
    padding: 0;
    border-bottom: 0 none;
  }
`

export const CouponTitle = styled.h3`
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.375rem;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  min-width: 100%;
  margin: 0 0 1rem 0;
`

export const CouponForm = styled.form`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 0 1rem 0;

  button {
    width: auto;
    height: 3rem;
    cursor: pointer;
  }
`

export const CouponInputContainer = styled.div`
  position: relative;
  flex: 0 1 70%;
  display: flex;
  align-items: center;
  margin: 0 1rem 0 0;

  input {
    flex: 1;
    padding: .5rem .5rem .5rem 2.75rem;
    background: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textFifthColor) || '#EEE' };
    border: 0.15rem solid ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.grey00) || '#CCC' };
    min-height: 3rem;
    max-height: 3rem;
    box-sizing: border-box;
  }

  svg {
    position: absolute;
    left: .5rem;
    width: 1.75rem;
    top: 50%;
    transform: translate(0, -50%);

    path {
      fill: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#CCC' };
      stroke: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#CCC' };
    }
  }
`

export const ShowCouponsLink = styled.a`
  font-weight: 700;
  font-size: .875rem;
  line-height: 1.25rem;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
  flex: 1;
  text-decoration: underline;
  text-underline-offset: 2px;
  text-decoration-thickness: 1.5px;
  cursor: pointer;
  min-width: 100%;
  text-align: center;
`

export const Notification = styled.div`
  border-radius: 1rem;
  padding: 1rem;
  background: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textFifthColor) || '#EEE' };
  border: 0.15rem solid ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.grey00) || '#CCC' };
  min-height: 3rem;
  max-height: 3rem;
  min-width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#333' };
  margin-bottom: 1rem;

  > img {
    width: 1rem;
    height: auto;
    min-width: 1rem;
    max-width: 1rem;
    min-height: auto;
    max-height: unset;
    margin-left: 1rem;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
    min-width: 1.5rem;
    min-height: 1.5rem;
    background: #fd5b5d;
    border-radius: 50%;
    padding: .25rem;
    box-sizing: border-box;
    margin: 0 0 0 1rem;
  }
`

export const Message = styled.div`
  padding: .5rem .85rem;
  margin: 0 0 1.5rem 0; 
  background-color: #f4f4f4;
  min-height: 3rem;
  max-height: 7rem;
  box-sizing: border-box;
  border-radius: 1.15rem;
  border: 1px solid #dedede;
  color: #b9babc;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1rem;

  span {
    max-width: 100%;
    position: relative;
    max-height: calc(1rem * 3);
    overflow: hidden;
    padding-right: .5rem;
    text-overflow: ellipsis;
  }

  &.success{
    svg {
      width: 1.5rem;
      height: 1.5rem;
      min-width: 1.5rem;
      min-height: 1.5rem;
      background: #31bf6e;
      border-radius: 50%;
      padding: .25rem;
      box-sizing: border-box;
      margin: 0 0 0 1rem;
    }
  }

  &.error{
    svg {
      width: 1.5rem;
      height: 1.5rem;
      min-width: 1.5rem;
      min-height: 1.5rem;
      background: #fd5b5d;
      border-radius: 50%;
      padding: .25rem;
      box-sizing: border-box;
      margin: 0 0 0 1rem;
    }
  }
`
export const Div = styled.div`
  width: 100%;
`
