import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  min-width: 100%;
  max-width: 100%;

  &.horizontal {
		padding: 2rem 1rem 5rem 1rem;

    @media (min-width: 60rem) {
      padding: 2rem;
    }
  }
`

export const LoadingContainer = styled.div`
  height: 500px;
  min-height: 500px;
  width: 100%;
  display:flex;
  flex: 1;
`
