const theme = require("./theme.json");
const Theme = theme;

module.exports = {
	APP_VERSION: process.env.REACT_APP_VERSION || "1.2.8",
	ORIGIN: process.env.REACT_APP_ORIGIN || "https://www.voceqpad.com.br",
	STORE_NAME: process.env.REACT_APP_STORE_NAME || "Papila Deli",
	URL: process.env.REACT_APP_URL || "https://www.papiladelivery.com.br",
	TITLE: process.env.REACT_APP_TITLE || "Papila Deli",
	DESCRIPTION:
		process.env.REACT_APP_DESCRIPTION ||
		"Faça seu pedido pelo site do Papila Deli",
	API_URL:
		process.env.REACT_APP_API_URL ||
		"https://app-voceqpad-stage.azurewebsites.net/api/api/",
	//'https://voceqpad-dev.azurewebsites.net/api/api/'
	//"https://www.voceqpad.com.br/api/api/",
	//"https://voceqpad-staging.azurewebsites.net/api/api/",
	APP_ID: process.env.REACT_APP_APP_ID || 4,
	ESTABELECIMENTO_ID: process.env.REACT_APP_ESTABELECIMENTO_ID || 10,
	PLATAFORM_ID: process.env.REACT_APP_PLATAFORM_ID || 3,
	ANDROID_APP_URL: process.env.REACT_APP_ANDROID_APP_URL,
	IPHONE_APP_URL: process.env.REACT_APP_IPHONE_APP_URL,
	WEBSITE_QRCODE_URL: process.env.REACT_APP_WEBSITE_QRCODE_URL,
	THEME: Theme,

	FAVICON_URL:
		process.env.REACT_APP_FAVICON_URL ||
		"https://static-images.voceqpad.com.br/estabelecimentos/insalata/apple-touch-icon.png",
	ICON_192_URL:
		process.env.REACT_APP_ICON_192_URL ||
		"https://static-images.voceqpad.com.br/estabelecimentos/insalata/android-chrome-192x192.png",
	ICON_512_URL:
		process.env.REACT_APP_FAVICON_URL ||
		"https://static-images.voceqpad.com.br/estabelecimentos/insalata/android-chrome-512x512.png",

	// Feature toggle settings
	MULTI_STORE: process.env.REACT_APP_MULTI_STORE || "false",
	MULTI_STORE_SELECT_FIRST_STORE:
		process.env.REACT_APP_MULTI_STORE_SELECT_FIRST_STORE || "false",
	CARDAPIO_CONSULTA: process.env.REACT_APP_CARDAPIO_CONSULTA || "false",
	CARDAPIO_CARD: process.env.REACT_APP_CARDAPIO_CARD || "true",
	GELADEIRA_PEGUEEPAGUE: process.env.REACT_APP_GELADEIRA_PEGUEEPAGUE || "false",
	SPLASH_HOME: process.env.REACT_APP_SPLASH_HOME || "false",
	ADDRESS_REQUEST: process.env.REACT_APP_ADDRESS_REQUEST || "false",
	BALCAO: process.env.REACT_APP_BALCAO || "false",
	MESA: process.env.REACT_APP_MESA || "false",
	AMPM: process.env.REACT_APP_AMPM || "false",
	FLOW: process.env.REACT_APP_FLOW || "2",
	DELIVERY_SCHEDULING: process.env.REACT_APP_DELIVERY_SCHEDULING || "true",
	MINIMALIST_STORE_LIST: process.env.REACT_APP_MINIMALIST_STORE_LIST || "false",
	WHATSAPP: process.env.REACT_APP_WHATSAPP || "false",
	WHATSAPP_NUMBER: process.env.REACT_APP_WHATSAPP_NUMBER || "5511999310914",
	IMAGE_ELEVATE: process.env.REACT_APP_IMAGE_ELEVATE || "true",
	LIMITE_STORES: process.env.REACT_APP_LIMITE_STORES || "15",
	PRODUCT_IMAGE_SCALE: process.env.REACT_APP_PRODUCT_IMAGE_SCALE || "true",
	ALTERNATIVE_STYLE_MULTI_STORE:
		process.env.REACT_APP_ALTERNATIVE_STYLE_MULTI_STORE || "true",
	URL_DOWNLOAD_APP:
		process.env.REACT_APP_URL_DOWNLOAD_APP ||
		"https://papiladelivery.page.link/app",
	APP_SHOW_BUTTON: process.env.REACT_APP_SHOW_BUTTON || "false",

	// Integration settings
	GOOGLE_API_KEY:
		process.env.REACT_APP_GOOGLE_API_KEY ||
		"AIzaSyBSoH6SJnB7YfoolnrGiTsDB4q9BNgUDvw",
	GOOGLE_ANALYTICS: process.env.REACT_APP_GOOGLE_ANALYTICS || "false",
	GOOGLE_ANALYTICS_ID: process.env.REACT_APP_GOOGLE_ANALYTICS_ID || "",
	FACEBOOK_APP_ID: process.env.REACT_APP_FACEBOOK_APP_ID || "369363108676529",
	GOOGLE_CLIENT_ID:
		process.env.REACT_APP_GOOGLE_CLIENT_ID ||
		"524693294684-1d8iurl0l8qipfv7bbreas5jo1ul2nud.apps.googleusercontent.com",
	GTM_KEY: process.env.REACT_APP_GTM_KEY || "false",
	SENTRY: process.env.REACT_APP_SENTRY || "false",
	SENTRY_DSN:
		process.env.REACT_APP_SENTRY_DSN ||
		"https://2e297670e9ea40edb474639d423e9278@o559881.ingest.sentry.io/5695087",
	SENTRY_PROJECT: process.env.REACT_APP_SENTRY_PROJECT || "voceqpad",
	SENTRY_RATE: process.env.REACT_APP_SENTRY_RATE || "1.0",
	LOGROCKET: process.env.REACT_APP_LOGROCKET || "true",
	LOGROCKET_PROJECT:
		process.env.REACT_APP_LOGROCKET_PROJECT || "g5snio/papiladeli",
	MUNDIPAGG_PUBLIC_KEY:
		process.env.REACT_APP_MUNDIPAGG_PUBLIC_KEY || "pk_nwBRe4zcBs1qba2d",
	KEY_FACEBOOK_DOMAIN:
		process.env.REACT_APP_KEY_FACEBOOK_DOMAIN ||
		"yu3iitteljtf0ermf862ymzljpv2nn",

	//Bonus settings
	HAS_CASHBACK: process.env.REACT_APP_HAS_CASHBACK || "false",
	HAS_LOYALTY: process.env.REACT_APP_HAS_LOYALTY || "false",
	ENABLE_SCHEDULE_INTERVAL:
		process.env.REACT_APP_ENABLE_SCHEDULE_INTERVAL || "false",
};
