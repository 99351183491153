import { actionTypes } from '.'

const localStorageKey = 'cart'

export const containerMode = {
	default: 'container',
	headerFullContent: 'header-full-content--opened',
	sideFullContent: 'side-full-content--opened'
}

const initialState = {
	products: [],
	isCartOpen: false,
	sideMode: {
    isOpen: false,
    mode: containerMode.default
  },
	above: false
}

const reducer = (state = initialState, action) => {
  const localStorageData = JSON.parse(localStorage.getItem(localStorageKey)) || {}
  const loadedState = {
    ...state,
    ...localStorageData
  }

  const newState = {
    value: loadedState
  }

  switch (action.type) {
    case actionTypes.NEW_CART:
			Object.assign(newState, {
				value: {
					...action.payload
				}
			})

			break

		case actionTypes.UPDATE_CART:
			Object.assign(newState, {
				value: {
					...newState.value,
					...action.payload
				}
			})

			break

		case actionTypes.CLEAN_CART:
			Object.assign(newState, {
				value: {}
			})

			break

		case actionTypes.SET_COUPON:
			Object.assign(newState, {
				value: {
					...newState.value,
					coupon: action.payload
				}
			})

			break
			case actionTypes.SET_COUPON_ABOVE:
				Object.assign(newState, {
					value: {
						...newState.value,
						above: action.payload
					}
				})
	
				break
		case actionTypes.SET_OPENED:
			Object.assign(newState, {
				value: {
					...newState.value,
					isCartOpen: action.payload
				}
			})

			break

			case actionTypes.SET_CART_COUPON:
				Object.assign(newState, {
					value: {
						...newState.value,
						coupon: action.payload
					}
				})
	
				break

		case actionTypes.SET_SIDE_MODE:
			Object.assign(newState, {
				value: {
					...newState.value,
					sideMode: action.payload
				}
			})

			break

    default:
  }

  localStorage.setItem(localStorageKey, JSON.stringify(newState.value))

  return newState.value
}

export default reducer
