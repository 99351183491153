import { actionTypes } from '..'
import { loading } from '../loading'

export const setCoupons = (coupons) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_COUPONS,
    payload: coupons
  })
}

export const setCoupon = (coupon) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_COUPON,
    payload: coupon
  })
}

export const setCouponValueAbove = (coupon) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_COUPON_ABOVE,
    payload: coupon
  })
}

export const setSelectedCoupon = (coupon) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_SELECTED_COUPON,
    payload: coupon
  })
}

export const fetchCoupons = (callback) => (dispatch, getState, container) => {
  const done = () => {
    dispatch(loading.setLoading({ item: 'coupons', delete: true}))
  }

  try {
    const stateCoupons = getState().main.coupons || {}
    const data = {}
    const actions = {
      setLoading: loading.setLoading
    }

    return container.fetchCoupons({
      onSuccess: ({ coupons}) => {
        if (coupons && coupons.coupons) {
          dispatch(setCoupons({
            ...stateCoupons,
            coupons: coupons.coupons
          }))

          done()

          return
        }

        done()

        dispatch(setCoupons({
          coupons: []
        }))
      },
      onError: () => {
        dispatch(setCoupons({
          coupons: []
        }))

        done()
      },
      onEmpty: () => {
        dispatch(setCoupons({
          coupons: []
        }))

        done()
      },
      dispatch,
      data,
      actions
   })
  } catch (e) {
    console.log(e)

    dispatch(setCoupons({
      coupons: []
    }))

    done()
  } finally {
    callback && callback()
  }
}

export const addCoupon = (callback) => (dispatch, getState, container) => {
  const callbackError = callback && callback[0] && typeof callback[0] === 'function' ? callback[0] : () => {}
  const callbackSuccess = callback && callback[0] && typeof callback[1] === 'function' ? callback[1] : () => {}

  try {
    const coupon = getState().main && getState().main.couponField

    const data = {coupon}
    const actions = {
      setLoading: loading.setLoading
    }

    return container.addCoupon({
      onSuccess: ({ coupon }) => {
        if (!coupon.success) {
          callbackError && callbackError({
            ...coupon
          })

          return
        }

        if (coupon.success) {
          callbackSuccess && callbackSuccess({
            ...coupon
          })

          return coupon
        }
      },
      onError: () => {
        callbackError && callbackError()
      },
      onEmpty: () => {
        callbackError && callbackError()
      },
      dispatch,
      data,
      actions
   })
  } catch (e) {
    console.log(e)

    callbackError && callbackError()
  } finally {
    
  }
}
