import styled from 'styled-components'

export const Wrapper = styled.div`
  flex: 1;
  min-width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  min-height: 5rem;
  max-height: 5rem;
  border: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' } .125rem solid;
  border-radius: .938rem;
  flex-wrap: nowrap;
  overflow: hidden;

  @media(min-width:60rem){
    margin: 0;
    min-width: calc(50% - .5rem);
    max-width: calc(50% - .5rem);
  }

  &.ready {
    border: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textGreenColor) || '#000' } .125rem solid;
  }

  > div {
    flex: 1;

    &.first {
      min-width: 3.125rem;
      max-width: 3.125rem;
      margin-right: .313rem;
      max-height: 3.125rem;
      min-height: 3.125rem;

      > img {
        width: 3.125rem;
        height: auto;
        max-height: 3.125rem;
      }
    }

    &.second {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      max-height: 3.125rem;
      min-height: 3.125rem;

      > p {
        color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.grey02) || '#000' };
        margin-bottom: .188rem;
        font-size: .75rem;
        font-weight: bold;
        white-space: nowrap;
        text-overflow: ellipsis;

        > span {
          color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textGreenColor) || '#000' };
        }

        svg{
          width: .8rem;
          margin: 0 6px 0 0;
          padding: 4px 0 0 0;

          path{
            stroke: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.grey02) || '#000' };
          }
        }
      }

      > div {
        &.steps {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          min-width: 100%;
          max-width: 100%;
          margin-top: .188rem;

          > div {
            flex: 1;
            margin-right: .125rem;
            min-height: .188rem;
            max-height: .188rem;
            border-radius: 1.5px;
            background-color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textSecondaryColor) || '#000' };

            &.active {
              background-color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }

    &.third {
      min-width: 1.25rem;
      max-width: 1.25rem;
      text-align: right;

      > button {
        color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
        background: none;
      }
    }
  }
`
