import styled from 'styled-components'

import { device } from '../../../../domain/enum/responsive'
import CheckedIconSuccess from '../../../assets/icon_checked_success.png'
import OnlyCheckIcon from '../../../assets/icon_only_check.svg'
import UncheckedIcon from '../../../assets/icon_unchecked.png'
import Loading from './../../../assets/receipt_loading.gif'

export const OrderStatusTitle = styled.h2`
    font-weight: 7000;
    font-size: 1.25rem;
    line-height: 1.688rem;
    color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
    margin: 0 0 2rem 0;
`

export const ProgressBar = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  min-width: 100%;
  max-width: 100%;
  list-style: inside none;
  padding: 0;
  margin: 0;
  margin-bottom: 3rem;


  @media ${device.desktop} {
    max-width: unset;
    min-width: unset;
    margin-bottom: 3.125rem;
  }
`

export const ProgressBarItem = styled.li`
  background: #B1B1B1;
  flex: 1;
  max-width: 24%;
  min-width: 24%;
  min-height: .313rem;
  max-height: .313rem;
  border-radius: .125rem;
  padding: 0;
  margin: 0;

  &.active {
    background: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#999'};
  }

  @media ${device.desktop} {
    max-height: .375rem;
    min-height: .375rem;
    border-radius: 4px;
  }
`

export const StatusStepper = styled.ul`
  position: relative;
  list-style: none;
  padding: 0 0 2rem;
  box-sizing: border-box;
  border-bottom: 1px solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'};
  margin: 0;

  * {
    box-sizing: border-box;
  }
`

export const StatusTitle = styled.span` 
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.375rem;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#000' };
  margin: 0 0 0 2rem;
`

export const StepperItemBorder = styled.span`
  border-left: 2px solid #E2E2E2;
  position: absolute;
  top: 20px;
  bottom: -2px;
  left: 10px;
`

export const StatusStepperItem = styled.li`
  position: relative;
  padding: 0 0 3rem;
  margin: 0;
  display: flex;
  flex-direction: column;
  max-height: 4.375rem;
  /* margin: 0 0 0 .5rem; */

  &:before {
    content: '';
    width: 8px;
    height: 8px;
    background: #B1B1B1;
    border-radius: 50%;
    position: absolute;
    left: 7.35px;
    top: 8px;
  }

  &:last-child {
    border: 0;
    padding-bottom: 0;
  }

  &.active {    
    &:before {
      content: '';
      width: 24px;
      height: 24px;
      background: #3ABE71;
      left: 0;
      top: 0;
      background-image: url('${OnlyCheckIcon}');
      background-repeat: no-repeat;
      background-position: center;
    }

    ${StepperItemBorder} {
      border-left: 2px solid #3ABE71;
    }

    ${StatusTitle} {
      color: #3ABE71;
    }

    ${StepperItemBorder} {
      top: 30px;
      left: .65rem;
    }

    &.next-active {
      ${StepperItemBorder} {
        bottom: 5px;
      }
    }
  }
`

export const StatusHour = styled.span` 
  font-weight: 600;
  font-size: .875rem;
  line-height: 1.188rem;
  margin: 0 0 0 2rem;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#000' };
`

export const Stepper = styled.div`
  min-width: 280px;
  max-width: 280px;
`

export const StepperItem = styled.div`
  background: ${ props => {
    if(props.status){
      return `url('${ Loading }') no-repeat 0 .313rem;`
    }

    return `url('${ UncheckedIcon }') no-repeat 0 .313rem;`
  }};
  min-width: 280px;
  max-width: 280px;
  line-height: 2rem;
  min-height: 2rem;
  padding-left: 1.875rem;
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-size: 1.5rem;
  padding-bottom: 2rem;
  color: ${ props => {
    if(props.status){
      return `#B1B1B1`
    }

    return `#B1B1B1`
  }};
  font-size: ${ props => {
    if(props.status){
      return `18px;`
    }

    return `16px;`
  }};
  font-weight: bold;
  
  @media ${ device.desktop } {
    font-size: ${ props => {
      if(props.status){
        return `18px;`
      }
  
      return `16px;`
    }};
    padding-top: 0;
    padding-bottom: 2rem;
  }

  &.active {
    background-image: url('${ CheckedIconSuccess }');
    background-size: 1.25rem;
    padding-bottom: 2rem;
    color: ${ props => (props.theme && props.theme.colors && props.theme.colors.positive) || '#999' };
    font-weight: bold;

    &:before {
      content: '';
      position: absolute;
      left: 9px;
      top: 1.75rem;
      border-left: ${ props => (props.theme && props.theme.colors && props.theme.colors.positive) || '#999' } .120rem solid;
      height: 25px;
      width: .063rem;
  
      @media ${ device.desktop } {
        height: 2rem;
      }
    }
  }

  &:before {
    content: '';
    position: absolute;
    left: .7rem;
    top: 2.1rem;
    border-left: #E2E2E2 .063rem solid;
    height: 2rem;
    width: .063rem;
  }

  &:last-child {
    padding-bottom: 0;

    &:before {
      display: none;
    }
  }

  > span {
    display: inline-block;
    font-size: .875rem;
    color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#000' };
    font-weight: normal;
    padding-left: .313rem;
  }
`
