import React from 'react'
import styled from 'styled-components'

import { ReactComponent as ArrowBottomSvg } from '../../assets/arrow_bottom.svg'
import { ReactComponent as arrowButtomLocationSvg } from '../../assets/arrow_buttom_location.svg'
import { ReactComponent as CalendarSvg } from '../../assets/calendar.svg'
import { ReactComponent as CashBackSvg } from '../../assets/cash_back.svg'
import { ReactComponent as CestaSvg } from '../../assets/cesta.svg'
import { ReactComponent as ClockSvg } from '../../assets/clock.svg'
import { ReactComponent as Vertical3DotsSvg } from '../../assets/dots_vertical_circle_outline.svg'
import { ReactComponent as EditSvg } from '../../assets/edit.svg'
import { ReactComponent as IconArrowLeftSvg } from '../../assets/icons/icon_arrow_left.svg'
import { ReactComponent as IconArrowRightSvg } from '../../assets/icons/icon_arrow_right.svg'
import { ReactComponent as IconCartSvg } from '../../assets/icons/icon_cart.svg'
import { ReactComponent as IconCheckSvg } from '../../assets/icons/icon_check.svg'
import { ReactComponent as IconCheckoutAddressSvg } from '../../assets/icons/icon_checkout_address.svg'
import { ReactComponent as IconCheckoutPaymentSvg } from '../../assets/icons/icon_checkout_payment.svg'
import { ReactComponent as IconCheckoutTimeSvg } from '../../assets/icons/icon_checkout_time.svg'
import { ReactComponent as IconCheckoutTotalSvg } from '../../assets/icons/icon_checkout_total.svg'
import { ReactComponent as IconCloseSvg } from '../../assets/icons/icon_close.svg'
import { ReactComponent as IconCreditCardSvg } from '../../assets/icons/icon_credit_card.svg'
import { ReactComponent as IconCurrentLocation } from '../../assets/icons/icon_current_location.svg'
import { ReactComponent as IconDeleteSvg } from '../../assets/icons/icon_delete.svg'
import { ReactComponent as IconDeliverySvg } from '../../assets/icons/icon_delivery.svg'
import { ReactComponent as IconEditSvg } from '../../assets/icons/icon_edit.svg'
import { ReactComponent as IconErrorSvg } from '../../assets/icons/icon_error.svg'
import { ReactComponent as IconFbSvg } from '../../assets/icons/icon_fb.svg'
import { ReactComponent as IconGoogleSvg } from '../../assets/icons/icon_google.svg'
import { ReactComponent as IconInfoSvg } from '../../assets/icons/icon_info.svg'
import { ReactComponent as IconLessInfoSvg } from '../../assets/icons/icon_less_info.svg'
import { ReactComponent as IconLoginSvg } from '../../assets/icons/icon_login.svg'
import { ReactComponent as IconLoginDarkSvg } from '../../assets/icons/icon_login_dark.svg'
import { ReactComponent as IconMailSvg } from '../../assets/icons/icon_mail.svg'
import { ReactComponent as IconMinValueSvg } from '../../assets/icons/icon_min_value.svg'
import { ReactComponent as IconMinusSvg } from '../../assets/icons/icon_minus.svg'
import { ReactComponent as IconMoreInfoSvg } from '../../assets/icons/icon_more_info.svg'
import { ReactComponent as IconPlusSvg } from '../../assets/icons/icon_plus.svg'
import { ReactComponent as IconSearchSvg } from '../../assets/icons/icon_search.svg'
import { ReactComponent as IconStoreSvg } from '../../assets/icons/icon_store.svg'
import { ReactComponent as IconTagSvg } from '../../assets/icons/icon_tag.svg'
import { ReactComponent as IconTimeSvg } from '../../assets/icons/icon_time.svg'
import { ReactComponent as AddBlueSvg } from '../../assets/ipiranga/addblue.svg'
import { ReactComponent as CashSvg } from '../../assets/ipiranga/cash.svg'
import Cenexao from '../../assets/ipiranga/conecxao.png'
import CouponIconIpiranga from '../../assets/ipiranga/coupon_icon.png'
import { ReactComponent as DividerSvg } from '../../assets/ipiranga/divider.svg'
import { ReactComponent as DividerGreySvg } from '../../assets/ipiranga/divider_grey.svg'
import { ReactComponent as FavoriteSvg } from '../../assets/ipiranga/favorite.svg'
import Fechado from '../../assets/ipiranga/fechado.png'
import { ReactComponent as HomeSvg } from '../../assets/ipiranga/home.svg'
import { ReactComponent as HomeGreySvg } from '../../assets/ipiranga/home_grey.svg'
import { ReactComponent as LogoutSvg } from '../../assets/ipiranga/logout.svg'
import Manutecao from '../../assets/ipiranga/manutencao.png'
import NoAddressCity from '../../assets/ipiranga/no-address-city.png'
import { ReactComponent as plusWhiteButtomSvg } from '../../assets/ipiranga/plus_white.svg'
import { ReactComponent as RemoveBlueSvg } from '../../assets/ipiranga/removeblue.svg'
import { ReactComponent as subWhiteButtomSvg } from '../../assets/ipiranga/sub_white.svg'
import TimeFromPngIpiranga from '../../assets/ipiranga/time-from-icon.png'
import { ReactComponent as trashButtomSvg } from '../../assets/ipiranga/trash_white.svg'
import ValueFromPngIpiranga from '../../assets/ipiranga/value-from-icon.png'
import { ReactComponent as WorkSvg } from '../../assets/ipiranga/work.svg'
import { ReactComponent as LocalizationSvg } from '../../assets/localization.svg'
import { ReactComponent as LocalizationWhiteSvg } from '../../assets/localization_white.svg'
import { ReactComponent as NewAltTrashSvg } from '../../assets/new_alt_trash.svg'
import { ReactComponent as NewAroowLeftSvg } from '../../assets/new_arrow_left.svg'
import { ReactComponent as NewCalendarSvg } from '../../assets/new_calendar.svg'
import { ReactComponent as NewTimeSvg } from '../../assets/new_time.svg'
import { ReactComponent as NoAddressSvgIpiranga } from '../../assets/no_address_ip.svg'
import { ReactComponent as NoAddressSvgSeara } from '../../assets/no_address_sea.svg'
import IconNoCard from '../../assets/seara/no_card.png'
import { DefaultImage } from '../Image'
import StoreOff from '../../assets/icons/store-off.svg'
import StoreUnavailable from '../../assets/icons/store-unavailable.svg'
import StoreNoConnection from '../../assets/icons/store-no-connection.svg'
import {ReactComponent as IconGeolocalization } from '../../assets/icons/icon_geolocalization.svg'

/* IPIRANGA */

const icons = {
  delivery: IconDeliverySvg,
  minValue: IconMinValueSvg,
  store: IconStoreSvg,
  time: IconTimeSvg,
  info: IconInfoSvg,
  login: IconLoginSvg,
  loginDark: IconLoginDarkSvg,
  edit: IconEditSvg,
  delete: IconDeleteSvg,
  search: IconSearchSvg,
  moreInfo: IconMoreInfoSvg,
  lessInfo: IconLessInfoSvg,
  cart: IconCartSvg,
  close: IconCloseSvg,
  plus: IconPlusSvg,
  minus: IconMinusSvg,
  mail: IconMailSvg,
  facebook: IconFbSvg,
  google: IconGoogleSvg,
  tag: IconTagSvg,
  check: IconCheckSvg,
  error: IconErrorSvg,
  arrowLeft: IconArrowLeftSvg,
  arrowRight: IconArrowRightSvg,
  checkoutTotal: IconCheckoutTotalSvg,
  checkoutPayment: IconCheckoutPaymentSvg,
  checkoutAddress: IconCheckoutAddressSvg,
  checkoutTime: IconCheckoutTimeSvg,
  creditCard: IconCreditCardSvg,
  localization: LocalizationSvg,
  newArrowLeft: NewAroowLeftSvg,
  cashBack: CashBackSvg,
  arrowButtomLocation: arrowButtomLocationSvg,
  trashButtom: trashButtomSvg,
  plusWhiteButtom: plusWhiteButtomSvg,
  subWhiteButtom: subWhiteButtomSvg,
  home: HomeSvg,
  divider: DividerSvg,
  dividerGrey: DividerGreySvg,
  homeGrey: HomeGreySvg,
  work: WorkSvg,
  favorite: FavoriteSvg,
  cash: CashSvg,
  addBlue: AddBlueSvg,
  removeBlue: RemoveBlueSvg,
  vertical3Dots: Vertical3DotsSvg,
  edit2: EditSvg,
  logout: LogoutSvg,
  localizationWhite: LocalizationWhiteSvg,
  cesta: CestaSvg,
  arrowBottom: ArrowBottomSvg,
  calendar: CalendarSvg,
  newCalendar: NewCalendarSvg,
  newTime: NewTimeSvg,
  newAltTrash: NewAltTrashSvg,
  noAddressIpiranga: NoAddressSvgIpiranga,
  noAddressSvgSeara: NoAddressSvgSeara,
  valueFromPngIpiranga: ValueFromPngIpiranga,
  clock: ClockSvg,
  timeFromPngIpiranga: TimeFromPngIpiranga,
  noAddressCity : NoAddressCity,
  fechado: Fechado,
  manutecao: Manutecao,
  conexao: Cenexao,
  coupon: CouponIconIpiranga,
  currentLocation: IconCurrentLocation,
  noCard: IconNoCard,
  storeOff: StoreOff,
  storeUnavailable: StoreUnavailable,
  storeNoConnection: StoreNoConnection,
	geolocalization: IconGeolocalization,
}

// To-Do: Fix stroke
const IconWraper = styled.span`
  display: inline-block;
  vertical-align: middle;
  & svg {
    width: ${props => props.width || "auto"};
    height: ${props => props.height || "auto"};
    margin-right: ${props => props.margin || "auto"};
    & path {
      ${props => props.fill && `fill: ${props.theme.colors[props.fill] || props.fill}`};
      ${props => props.stroke && `stroke: ${props.theme.colors[props.stroke] || props.stroke}`};
    }
  }
`

const Icon = ({name, fill, stroke, width, height, margin}) => {
  const Component = icons[name]

  if (!Component) return null

  if (typeof Component === 'string') {
    return (
      <IconWraper className='icon' fill={fill} stroke={stroke} width={width} height={height} margin={margin}>
        <DefaultImage src={ Component } />
      </IconWraper>
    )
  }

  return (
    <IconWraper className='icon' fill={fill} stroke={stroke} width={width} height={height} margin={margin}>
      <Component />
    </IconWraper>
  )
}

export default Icon
