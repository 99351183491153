import React from 'react'
import PropTypes from 'prop-types'
import { Image, Shimmer } from 'react-shimmer'
import Lottie from "lottie-react";
import motoAnimation from "../../../view/assets/animations/moto-anim.json";

import {
  Wrapper,
  Background,
  H2,
  StoreInfoWrapper,
  Span,
  ImageLogo,
  NameWrapper,
  Distance,
	BoxFreeShipping,
	FreeShippingLabel
} from './styles'
import { Cashback } from '../../store/Header/Cashback/Cashback'
import { HAS_CASHBACK } from '../../../config'

const StoreInfo = ({
  address
}) => {
  return <StoreInfoWrapper>
    <Span className='distance'>{`${address}`}</Span>
  </StoreInfoWrapper>
}

export function StoreAlternative(props) {
  const {
    handles,
    data
  } = props

  const {
    id,
    coupon = {},
    name,
    distance,
    category,
    address,
    delivery,
    logo,
    highlight,
    pickup,
    cashback,
		minForFreeShipping
  } = data

  const { handleStore } = handles

  const hasCashback = JSON.parse(HAS_CASHBACK);

  return <Wrapper onClick={() => {
    handleStore({
      id,
      coupon,
      modality: delivery ? 4 : 2,
      name,
      modalities: {
        delivery,
        pickup
      }
    })
  }}>
    {(highlight && logo) ? (
      <Background>
        {
          (hasCashback && !!cashback) && (
            <Cashback cashback={cashback} smallSizeOnly top />
          )
        }
        <Image className='banner' src={highlight} fallback={<Shimmer width={800} height={235} />} />
        <ImageLogo className='logo' src={logo} />
      </Background>
    ) : (
      <>
        {
          (hasCashback && !!cashback) && (
            <Cashback cashback={cashback} smallSizeOnly />
          )
        }
      </>
    )
    }

    <NameWrapper>
      <H2>{name}</H2>
      <Distance>{`${distance} km`}</Distance>
    </NameWrapper>

    <StoreInfo address={address} category={category} />
		{minForFreeShipping && (<BoxFreeShipping>
		<div className="icon">
			<Lottie animationData={motoAnimation} />
		</div>
		<FreeShippingLabel amount={minForFreeShipping.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}></FreeShippingLabel>
		</BoxFreeShipping>)}
  </Wrapper>
}

StoreAlternative.propTypes = {
  handles: PropTypes.shape({
    handleStore: PropTypes.func
  }),
  selectedStoreId: PropTypes.number,
  data: PropTypes.shape({
    id: PropTypes.number,
    coupon: PropTypes.shape({}),
    name: PropTypes.string,
    logo: PropTypes.string,
    distance: PropTypes.string,
    selectedStoreId: PropTypes.number,
    category: PropTypes.string,
    pickup: PropTypes.bool,
    delivery: PropTypes.bool,
    store: PropTypes.shape({
      id: PropTypes.number
    }),
    cashback: PropTypes.number
  })
}
