import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import {
  Wrapper,
  HeaderBox,
  H1
} from './styles'
export function FixedHeader(props) {
  const {
    name
  } = props
  const [fixed, setFixed] = useState(false)
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 400 && !fixed) {
        setFixed(true)
      } else if (window.scrollY < 400 && !!fixed) {
        setFixed(false)
      }
    }
    window.addEventListener('scroll', handleScroll, true)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [
    fixed
  ])
  return <Wrapper id='fixed-header' fixed={fixed}>
    <HeaderBox>
      <H1>{name}</H1>
    </HeaderBox>
  </Wrapper>
}

FixedHeader.propTypes = {
	name: PropTypes.string
}
