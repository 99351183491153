import { MULTI_STORE } from "../../config"

interface AddressValidationRequest {
	Latitude: number
	Longitude: number
}

interface AddressValidationData {
	success: boolean
	message: string
	valid: boolean
}

interface StoreDataProductsAdditionals {
	name: string
	minimum: number
	maximum: number
	checkbox: boolean
	calcType: number
	items: StoreDataProductsAdditionalsItems[]
	groupId: number
}

interface StoreDataProductsAdditionalsItems {
	name: string
	code: number
	value: number
	packPrice: number
	delivery: boolean
	counter: boolean
	description: string
	quantity: number
	groupId: number
	calcType: number
}

interface StoreDataProducts {
	id: number
	name: string
	description: string
	value: number
	image: string
	rewardImage: string
	additionals: StoreDataProductsAdditionals[]
}

interface StoreDataCategories {
	id: number
	name: string
	description: string
	image: string
}

interface StoreDataImages {
	logo: string
	headerBackgroundImage: string
}

interface StoreDataContact {
	ddd: String
	phone: String
	email: String
}

interface StoreDataOpenign {
	day: String
	today: Boolean
	hour: String
	schedule: String
}

interface StoreDataSearaAvailableDates {
	day: string
	date: string
	schedules: StoreDataSearaAvailableDatesSchedules[]
}

interface StoreDataLoyaltyPoints {
	loyaltyModelPointsId: number,
	modelPoints: number,
	points: number,
	minValue: number
}

interface StoreDataSearaAvailableDatesSchedules {
	id: number
	time: string
}

interface StoreData {
	aisle: boolean
	products: StoreDataProducts
	allCategories: StoreDataCategories
	categories: StoreDataCategories
	images: StoreDataImages
	id: number
	name: string
	address: string
	opened: boolean
	status: number
	logo: string
	adHoc: boolean
	selectedCategory: number
	cart: any
	searaAvailableDates: StoreDataSearaAvailableDates[],
	pickup: any,
	delivery: any,
	scheduling: Boolean,
	contact: StoreDataContact,
	openingHours: StoreDataOpenign,
	onlineFlags: any,
	presentialFlags: any,
	modalities: any,
	deliveryMinimumValue: any
	pickupMinimumValue: any
	deliveryTime: any,
	loyaltyPoints: StoreDataLoyaltyPoints
	minForFreeShipping: number | null
}

interface StoresRequestGeolocation {
	Latitude: string | number
	Longitude: string | number
}

interface StoresRequest {
	Texto: string
	CidadeId: number
	Localizacao: StoresRequestGeolocation
	ModalidadeRetiradaId: number
	AglomeracaoId: number
	TotalRegistros: number
	PermitirDelivery: Boolean
}

function StoresClustersItemsDetailMap(mappedItem: any) {
	let pickup = false
	let delivery = false

	const images = {
		highlight: mappedItem['Imagem']['Empresa'],
		logo: mappedItem['Imagem']['Logo']
	}

	const modalities = mappedItem['ModalidadesRetirada']
	modalities && modalities.forEach((modalitiesFilteredItem: any) => {
		if (modalitiesFilteredItem['ModalidadeRetirada'] === 2) {
			pickup = true
		}
	})

	modalities && modalities.forEach((modalitiesFilteredItem: any) => {
		if (modalitiesFilteredItem['ModalidadeRetirada'] === 4) {
			delivery = true
		}
	})

	const coupon = (mappedItem['Cupom'] && {
		quickDescription: mappedItem['Cupom']['BreveDescricao'],
		id: mappedItem['Cupom']['CupomId'],
		createdAt: mappedItem['Cupom']['DataAdicionado'],
		expirationDate: mappedItem['Cupom']['DataExpiracao'],
		description: mappedItem['Cupom']['Descricao'],
		expired: mappedItem['Cupom']['Expirado'],
		name: mappedItem['Cupom']['Nome'],
		valueType: mappedItem['Cupom']['TipoValorCupom'],
		pickupValueType: mappedItem['Cupom']['TipoValorCupomModalidadeRetirada'],
		value: mappedItem['Cupom']['Valor'],
		pickupValue: mappedItem['Cupom']['ValorCupomModalidadeRetirada'],
		maxValue: mappedItem['Cupom']['ValorMaximo'],
		pickupMaxValue: mappedItem['Cupom']['ValorMaximoCupomModalidadeRetirada'],
		minValue: mappedItem['Cupom']['ValorMinimo'],
		pickupMinValue: mappedItem['Cupom']['ValorMinimoCupomModalidadeRetirada'],
		valueModalityDelivery: mappedItem['Cupom']['ValorCupomModalidadeRetirada'],
		valueMaxModalityDelivery: mappedItem['Cupom']['ValorMaximoCupomModalidadeRetirada'],
		valueMinModalityDelivery: mappedItem['Cupom']['ValorMinimoCupomModalidadeRetirada'],
		typeValueModalityDelivery: mappedItem['Cupom']['TipoValorCupomModalidadeRetirada']
	}) || {}

	return {
		category: mappedItem['Categoria'],
		id: mappedItem['Id'],
		images,
		coupon,
		name: mappedItem['Nome'],
		distance: mappedItem['Localizacao'] && mappedItem['Localizacao']['Distancia'] && Number(mappedItem['Localizacao']['Distancia']).toFixed(1),
		range: mappedItem['Localizacao'] && mappedItem['Localizacao']['Raio'],
		fullAddress: mappedItem['Localizacao'] && mappedItem['Localizacao']['Endereco'],
		status: mappedItem['StatusEstabelecimento'],
		pickup,
		delivery
	}
}

function StoresClustersItemsMap({
	cluster,
	modalityId
}: any) {
	return (mappedItem: any) => {
		const filteredItems = mappedItem[cluster].filter((filteredItem: any) => {
			const modalities = filteredItem['ModalidadesRetirada']
			const filteredModalities = modalities

			if (filteredModalities && filteredModalities.length) {
				return true
			}

			return false
		})

		if (filteredItems && filteredItems.length) {
			return filteredItems.map(StoresClustersItemsDetailMap)
		}
	}
}

function StoresListItemsMap(mappedItem: any) {
	const modalities = mappedItem['ModalidadesRetirada']

	const coupon = (mappedItem['Cupom'] && {
		quickDescription: mappedItem['Cupom']['BreveDescricao'],
		id: mappedItem['Cupom']['CupomId'],
		createdAt: mappedItem['Cupom']['DataAdicionado'],
		expirationDate: mappedItem['Cupom']['DataExpiracao'],
		description: mappedItem['Cupom']['Descricao'],
		expired: mappedItem['Cupom']['Expirado'],
		name: mappedItem['Cupom']['Nome'],
		valueType: mappedItem['Cupom']['TipoValorCupom'],
		pickupValueType: mappedItem['Cupom']['TipoValorCupomModalidadeRetirada'],
		value: mappedItem['Cupom']['Valor'],
		pickupValue: mappedItem['Cupom']['ValorCupomModalidadeRetirada'],
		maxValue: mappedItem['Cupom']['ValorMaximo'],
		pickupMaxValue: mappedItem['Cupom']['ValorMaximoCupomModalidadeRetirada'],
		minValue: mappedItem['Cupom']['ValorMinimo'],
		pickupMinValue: mappedItem['Cupom']['ValorMinimoCupomModalidadeRetirada'],
		valueModalityDelivery: mappedItem['Cupom']['ValorCupomModalidadeRetirada'],
		valueMaxModalityDelivery: mappedItem['Cupom']['ValorMaximoCupomModalidadeRetirada'],
		valueMinModalityDelivery: mappedItem['Cupom']['ValorMinimoCupomModalidadeRetirada'],
		typeValueModalityDelivery: mappedItem['Cupom']['TipoValorCupomModalidadeRetirada']
	}) || {}

	const loyaltyModel = mappedItem['FidelidadeModelosPontos'];
	const cashback = (loyaltyModel && loyaltyModel[0]) && loyaltyModel[0].Pontos || 0;

	return {
		category: mappedItem['Categoria'],
		id: mappedItem['Id'],
		logo: mappedItem['Logo'],
		highlight: mappedItem['Empresa'],
		address: mappedItem['Endereco'],
		coupon,
		name: mappedItem['Nome'],
		distance: (mappedItem['Distancia'].toFixed(1)).replace('.', ','),
		status: mappedItem['StatusEstabelecimento'],
		pickup: modalities.includes(2),
		delivery: modalities.includes(4),
		minForFreeShipping: mappedItem['ValorMinimoPedidoFreteGratis'],
		cashback
	}
}

export const mapStoresList = ({
	data
}: any): StoreData[] | [] => {
	const restaurants = data['Estabelecimentos']
	const stores = restaurants && restaurants.length && restaurants.map(StoresListItemsMap)

	return stores && stores.length && stores.reduce((total: any, amount: any) => {
		return total.concat(amount)
	}, [])
}

export const mapPickupStoresList = (data: any): StoreData[] | [] => {
	const cluster = data['Clusters'] && data['Clusters'] && data['Clusters']['Titulo']
	const stores = (data['Clusters'] && data['Clusters'] && data['Clusters']['Itens'] && data['Clusters']['Itens'].length && data['Clusters']['Itens'].map(StoresClustersItemsMap({
		cluster,
		modalityId: 2
	}))) || []

	return stores && stores.length && stores.reduce((total: any, amount: any) => {
		return total.concat(amount)
	}, [])
}

export const mapDeliveryStoresList = (data: any): StoreData[] | [] => {
	const restaurants = data['Restaurantes']
	const stores = restaurants && restaurants.length && restaurants.map(StoresListItemsMap)

	return stores && stores.length && stores.reduce((total: any, amount: any) => {
		return total.concat(amount)
	}, [])
}

export const mapStoresRequest = (data: any): StoresRequest => {
	const {
		searchText,
		cityId,
		latitude,
		longitude,
		agglomerationId,
		modalityId,
		paginationOffset
	} = data

	return {
		Texto: searchText,
		CidadeId: cityId,
		Localizacao: {
			Latitude: latitude,
			Longitude: longitude
		},
		PermitirDelivery: true,
		ModalidadeRetiradaId: modalityId,
		AglomeracaoId: agglomerationId,
		TotalRegistros: paginationOffset
	}
}

const mapStoreDataProducts = (data: any) => {
	const additionals = data['Acompanhamentos'] && data['Acompanhamentos'].length && data['Acompanhamentos'].map((additionalsMappedItem: any) => {
		const additionalsItems = additionalsMappedItem['Itens'] && additionalsMappedItem['Itens'].length && additionalsMappedItem['Itens'].map((additionalsItemsMappedItem: any) => {
			return {
				counter: additionalsItemsMappedItem['Balcao'],
				delivery: additionalsItemsMappedItem['Delivery'],
				description: additionalsItemsMappedItem['Descricao'],
				name: additionalsItemsMappedItem['Nome'],
				value: additionalsItemsMappedItem['Valor'],
				packPrice: additionalsItemsMappedItem['ValorEmbalagem'],
				code: additionalsItemsMappedItem['Codigo'],
				calcType: additionalsMappedItem['TipoCalculoCustomizacao'],
				groupId: additionalsItemsMappedItem['GrupoId'],
				minimum: additionalsMappedItem['Minimo'],
				maximum: additionalsMappedItem['Maximo'],
			}
		})

		return {
			name: additionalsMappedItem['Nome'],
			minimum: additionalsMappedItem['Minimo'],
			maximum: additionalsMappedItem['Maximo'],
			checkbox: additionalsMappedItem['Checkbox'],
			calcType: additionalsMappedItem['TipoCalculoCustomizacao'],
			promotional: additionalsMappedItem['Promocional'],
			groupId: additionalsItems[0].groupId,
			items: additionalsItems
		}
	})

	const tags = (data['Tags'] && data['Tags'].length && data['Tags'].map((mappedItem: any) => {
		return {
			fontColor: mappedItem['CorFonteTag'],
			backgroundColor: mappedItem['CorFundoTag'],
			format: mappedItem['FormatoTag'],
			text: mappedItem['TextoTag'],
			type: mappedItem['TipoTag']
		}
	})) || []

	return {
		id: data['Id'],
		name: data['Nome'],
		description: data['Descricao'],
		value: data['Valor'],
		image: data['Imagem'],
		rewardImage: data['ImagemPremio'],
		promotionalValue: data['ValorPromocional'],
		showFromValueText: data['ExibirApartirDe'],
		fromValue: data['ValorApartirDe'],
		additionals,
		tags,
		loyaltyModelPointsId: data['FidelidadeModeloPontosId'],
		loyaltyPoints: data['Pontos']
	}
}

export const mapStoreData = (data: any, index: number, selectedPickup: number, selectedDelivery: number): StoreData => {
	let pickup = false
	let delivery = false
	let scheduling = false

	const products = data['Categorias'] && data['Categorias'][index] && data['Categorias'][index]['Produtos'] && data['Categorias'][index]['Produtos'].length && data['Categorias'][index]['Produtos'].map(mapStoreDataProducts)

	const categories = data['Categorias'] && data['Categorias'].length && data['Categorias'].map((mappedItem: any) => {
		const products = mappedItem['Produtos'].length && mappedItem['Produtos'].map(mapStoreDataProducts)

		return {
			id: mappedItem['Id'],
			name: mappedItem['Nome'],
			products
		}
	})

	const onlineFlags = data['ListaBandeiras']

	const credit = data['ListaBandeirasPresenciais'] && data['ListaBandeirasPresenciais']['Credito']
	const debt = data['ListaBandeirasPresenciais'] && data['ListaBandeirasPresenciais']['Debito']
	const others = data['ListaBandeirasPresenciais'] && data['ListaBandeirasPresenciais']['Outros']
	const vales = data['ListaBandeirasPresenciais'] && data['ListaBandeirasPresenciais']['Vales']
	const presentialFlags = (data['ListaBandeirasPresenciais'] && [
		...credit,
		...debt,
		...others,
		...vales
	]) || []

	const contact = (data['Contato'] && {
		ddd: data['Contato']['DDD'],
		phone: data['Contato']['Telefone'],
		email: data['Contato']['Email']
	}) || {}

	const openingHours = data['HorarioFuncionamentoTexto'] && data['HorarioFuncionamentoTexto'].map((mappedItem: any) => {
		return {
			day: mappedItem['Dia'],
			today: mappedItem['Hoje'],
			hour: mappedItem['Hora'],
			schedule: mappedItem['Horario']
		}
	})

	const searaAvailableDates = data['HorariosDisponiveisSeara'] && data['HorariosDisponiveisSeara'].map((mappedItem: any) => {
		const day = mappedItem['Dia']
		const date = mappedItem['Data']
		const schedules = mappedItem['Horarios'] && mappedItem['Horarios'].map((mappedItem: any) => {
			const id = mappedItem['Id']
			const time = mappedItem['Hora']

			return {
				id,
				time
			}
		})

		return {
			day,
			date,
			schedules
		}
	})

	const images = (data['Imagem'] && {
		logo: data['Imagem']['Logo'],
		headerBackgroundImage: data['Imagem']['Empresa']
	}) || {}


	const modalities = data['ModalidadesRetirada']
	modalities && modalities.forEach((modalitiesFilteredItem: any) => {
		if (modalitiesFilteredItem['ModalidadeRetirada'] === 2) {
			pickup = true
		}
	})

	modalities && modalities.forEach((modalitiesFilteredItem: any) => {
		if (modalitiesFilteredItem['ModalidadeRetirada'] === 4) {
			delivery = true
		}
		if (modalitiesFilteredItem['Agendamento'] && modalitiesFilteredItem['Agendamento']['Obrigatorio']) {
			scheduling = true
		}
	})

	const loyaltyPoints = data['FidelidadeModelosPontos'] && data['FidelidadeModelosPontos'].map((mappedItem: any) => {
		const loyaltyModelPointsId = mappedItem['FidelidadeModeloPontosId']
		const modelPoints = mappedItem['ModeloPontos']
		const points = mappedItem['Pontos']
		const minValue = mappedItem['ValorMinimo']

		return {
			loyaltyModelPointsId,
			modelPoints,
			points,
			minValue
		}
	})

	const modalityPickup = (modalities.filter((filteredItem: any) => filteredItem.ModalidadeRetirada === 2))
	const modalityDelivery = (modalities.filter((filteredItem: any) => filteredItem.ModalidadeRetirada === 4))

	const pickupMinimumValue = modalityPickup && modalityPickup[0] && modalityPickup[0].ValorMinimoPedido
	const deliveryMinimumValue = modalityDelivery && modalityDelivery[0] && modalityDelivery[0].ValorMinimoPedido

	return {
		id: data['Id'],
		name: data['Nome'],
		address: data['Localizacao'] && data['Localizacao']['Endereco'],
		opened: data['Aberto'],
		status: data['StatusEstabelecimento'],
		logo: data['Imagem'] && data['Imagem']['Logo'],
		adHoc: data['PagamentoAdHoc'],
		aisle: data['CardapioCorredor'],
		deliveryTime: data['EstimativaEntrega'],
		selectedCategory: 0,
		cart: null,
		allCategories: categories,
		categories,
		products,
		images,
		searaAvailableDates,
		pickup: MULTI_STORE === "true" ? selectedPickup : pickup,
		delivery: MULTI_STORE === "true" ? selectedDelivery : delivery,
		scheduling,
		contact,
		openingHours,
		onlineFlags,
		presentialFlags,
		modalities,
		deliveryMinimumValue,
		pickupMinimumValue,
		loyaltyPoints,
		minForFreeShipping: data["ValorMinimoPedidoFreteGratis"],
	}
}

export const mapAddressValidationRequest = (data: any): AddressValidationRequest => {
	const {
		latitude,
		longitude
	} = data

	return {
		'Latitude': latitude,
		'Longitude': longitude
	}
}

export const mapAddressValidationData = (data: any): AddressValidationData => {
	return {
		success: data['Sucesso'],
		message: data['Mensage'],
		valid: data['EnderecoValido']
	}
}
