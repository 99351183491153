import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  display : flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  svg {
    g {
      path{
        fill: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
      }
    }
  }
`

export const H2 = styled.h1`
  font-size: 1.5rem;
  line-height: 1.5rem;
  margin-bottom: .7rem;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
`

export const H3 = styled.h2`
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin-bottom: 0;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
`

export const Paragraph = styled.p`
  font-size: 1rem;
  text-align: center;
`

export const Button = styled.button`
  align-items: center;
  background: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
  justify-content: center;
  color: #fff;
  padding: 1.5rem 1rem;
  border-radius: 15px;
  height: 3.5rem;
  position: relative;
  cursor: pointer;
  display: flex;
  margin: 0 1.5rem 1rem 1.5rem;
  min-width: calc(100% - 3rem);
  box-sizing: border-box;

  &.disabled {
    opacity: .5;
    cursor: default;
  }
`

export const ButtonLabel = styled.span`
  font-weight: 700;
  font-size: .875rem;
  line-height: 1.25rem;
  margin: 0 2rem;

  @media (min-width: 60rem) {
    margin: 0;
  }
`

const message = css`
  padding: .5rem .85rem;
  margin: 0 0 1.5rem 0; 
  background-color: #f4f4f4;
  min-height: 3rem;
  max-height: 7rem;
  box-sizing: border-box;
  border-radius: 1.15rem;
  border: 1px solid #dedede;
  color: #b9babc;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1rem;

  span {
    max-width: 85%;
    position: relative;
    max-height: calc(1rem * 3);
    overflow: hidden;
    padding-right: 1rem;
    text-overflow: ellipsis;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
    min-width: 1.5rem;
    min-height: 1.5rem;
    border-radius: 50%;
    padding: .25rem;
    box-sizing: border-box;
    margin: 0 0 0 1rem;
  }
`

export const ErrorMessage = styled.div`
  ${message}; 

  svg {
    background-color: #fd5b5d;
  }
`
