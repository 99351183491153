import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import history from '../../history'

import {
	useStoreSettings,
	useDeviceLayout,
	useLayout,
	useSidebar
} from '../hooks'

import {
	logout,
	setModality
} from '../../redux/actions/main'

import { cart } from '../../state'

import {
	pipe,
	GetContent,
	caculateProductsQuantity
} from '../../domain/helpers'

import {
	DefaultImage as Image,
	Button
} from '../components'

import NoStoreIcon from '../assets/no-store.png'

import { MainHeader } from '../main-header'

import { CartContainer } from '../cart'

import {
	Cart,
	PageContainer,
	PageContainerHeader,
	PageContainerMain,
	PageContainerSide
} from '..'

import {
	Navigation,
	Skeleton,
} from '..'

import {
	Wrapper,
	LoadingWrapper,
	Contened,
	H2,
	Paragraph
} from './styles'

export const Context = React.createContext({})

export function NoStorePage(props) {
	const {
		accessToken,
		loading,
		theme,
		storeName,
		storeImg,
		modalityId,
		address,
		addressDelivery,
		userName,
		cartProducts,
		setModality,
		URLParameters
	} = props

	const storeImage = theme.headerImage
	const storeLogo = theme.logoImage

	const {
		layoutMode,
		layoutModeEnum,
		handleLayoutMode
	} = useLayout()

	const {
		sidebarContent,
		sidebarOpened,
		sidebarContentEnum,
		handleSidebarContent,
		handleSidebarOpened
	} = useSidebar()

	const [visibleSignIn, setVisibleSignIn] = useState(false)
	const [viewCards, setViewCards] = useState(false)
	const [cartSlideDown, setCartSlideDown] = useState(false)

	const {
		url,
		title,
		description
	} = useStoreSettings()

	const isMobile = useDeviceLayout({
		isMobile: true
	})

	useEffect(() => {
		setCartSlideDown(false)
		setModality({ id: 4 })

	}, [isMobile, setModality])

	async function handleModality(id) {
		setModality({ id })
	}

	function handleContactUs() {
		history.push(`/contact-us${URLParameters}`)
	}

	function handleNoToken() {
		setVisibleSignIn(true)

		setTimeout(() => {
			setVisibleSignIn(false)
		}, 2000)
	}

	return (
		<Wrapper id={'no-store'}>
			<Helmet>
				<title>{`${title} - Nenhuma loja`}</title>
				<meta name='description' content={description} />
				<link rel='canonical' href={`${url}/no-store`} />
			</Helmet>

			{loading.includes('no-store') && <LoadingWrapper id='no-store-loading'>
				<Skeleton module='store' />
			</LoadingWrapper>}

			{!loading.includes('no-store') && <PageContainer
				isSideOpen={sidebarOpened}
				mode={layoutMode}
			>
				<PageContainerHeader>
					<MainHeader
						mode='full'
            theme={theme}
            storeLogo={storeLogo}
            name={storeName}
            storeImg={storeImg}
            userName={userName}
            accessToken={accessToken}
            setVisibleSignIn={setVisibleSignIn}
            visibleSignIn={visibleSignIn}
            handleModality={handleModality}
            modality={modalityId}
            addressDelivery={address ? address : addressDelivery}
            cartItemsQuantity={caculateProductsQuantity(cartProducts)}
						homeOnClick={() => {
							handleLayoutMode(layoutModeEnum.default)
							handleSidebarContent(sidebarContentEnum.default)
							handleSidebarOpened(false)
							setViewCards(false)
						}}
						userClicked={() => {
							if (sidebarOpened && sidebarContent === sidebarContentEnum.nav) {
								handleLayoutMode(layoutModeEnum.default)
								handleSidebarContent(sidebarContentEnum.default)
								handleSidebarOpened(false)
							} else {
								handleLayoutMode(layoutModeEnum.headerFullContent)
								handleSidebarContent(sidebarContentEnum.nav)
								handleSidebarOpened(true)
							}
						}}
						cartClicked={() => {
							if (sidebarOpened && sidebarContent === sidebarContentEnum.cart) {
								handleLayoutMode(layoutModeEnum.default)
								handleSidebarContent(sidebarContentEnum.default)
								handleSidebarOpened(false)
							} else {
								handleLayoutMode(layoutModeEnum.sideFullContent)
								handleSidebarContent(sidebarContentEnum.cart)
								handleSidebarOpened(true)
							}
						}}
						isSidebarOpen={sidebarOpened}
					/>
				</PageContainerHeader>
				<PageContainerMain opened={sidebarOpened}>
					<>
						<Image src={storeImage} alt="" />
						<Contened>
							<Image src={NoStoreIcon} alt='Ícone para sem loja' width="120px" />

							<H2>Não estamos operando na sua região ainda.</H2>
							<Paragraph>Estamos trabalhando para ampliar nossa área de atendimento.
								Infelizmente não poderemos atende-lo no momento.</Paragraph>
							<Button onClick={() => {}}>Alterar Endereço</Button>
						</Contened>
					</>
				</PageContainerMain>
				<PageContainerSide opened={sidebarOpened}>
					{!isMobile && layoutMode === layoutModeEnum.sideFullContent &&
						<CartContainer
							logo={storeLogo}
							storeImg={storeImg}
							name={storeName}
						>
							<Cart
								mode='simple'
								screenMobile={isMobile}
								setViewCards={setViewCards}
								viewCards={viewCards}
								className={cartSlideDown ? 'slide-down' : ''}
								handleNoToken={handleNoToken}
							/>
						</CartContainer>
					}

					{layoutMode === layoutModeEnum.headerFullContent && <Navigation
							contactUs={() => handleContactUs()}
							userName={userName}
							currentRoute='no-store'
						/>}
				</PageContainerSide>
			</PageContainer>}
		</Wrapper>
	)
}

NoStorePage.propTypes = {
	accessToken: PropTypes.string,
	loading: PropTypes.arrayOf(PropTypes.shape({})),
	theme: PropTypes.shape({
		headerImage: PropTypes.string,
		logoImage: PropTypes.string
	}),
	storeName: PropTypes.string,
	storeImg: PropTypes.string,
	modalityId: PropTypes.number,
	address: PropTypes.shape({}),
	addressDelivery: PropTypes.shape({}),
	userName: PropTypes.string,
	cartProducts: PropTypes.shape({}),
	setModality: PropTypes.func,
	URLParameters: PropTypes.string
}

const mapStateToProps = (state) => {
	return {
		accessToken: state.user.accessToken,
		addressDelivery: state.address.addressByCEP || '',
		cartProducts: (state.main.cart && state.main.cart.products) || [],
		loading: state.loading.loading || [],
		registeredCoupons: state.main.coupons || { coupons: [] },
		address: state.address.address || '',
		userAddresses: state.userAddress.userAddresses || [],
		userHistory: state.main.userHistory || {},
		userName: state.user.name || '',
		storeImg: (state.store.store && state.store.store.logo) || '',
		modalityId: state.main.modality && state.main.modality.id,
		storeName: state.store.store && state.store.store.name,
		storeId: state.main.store && state.main.store.id,
		storeStatus: state.store.store && state.store.store.status,
		deliveryFee: state.store.store && state.store.store.deliveryFee,
		deliveryMinimumValue: state.store.store && state.store.store.deliveryMinimumValue,
		pickupMinimumValue: state.store.store && state.store.store.pickupMinimumValue,
		deliveryTime: state.store.store && state.store.store.deliveryTime,
		storeContact: state.store.store && state.store.store.contact,
		openingHours: state.store.store && state.store.store.openingHours,
		onlineFlags: state.store.store && state.store.store.onlineFlags,
		URLParameters: (state.main.URLParameters) || ''
	}
}

const GetConnection = connect(mapStateToProps, {
	fetchCart: cart.fetchCart,
	logout,
	setModality
})

export const NoStore = React.memo(pipe(
	GetConnection,
	GetContent({ context: Context, id: 'no-store' })
)(NoStorePage))
