import { actionTypes } from '.'

export const setAccessToken = (token) => async (dispatch) => {
  dispatch({
    type: actionTypes.SET_ACCESS_TOKEN,
    payload: token
  })
}

export const setUserCallback = (userCallback) => async (dispatch) => {
  dispatch({
    type: actionTypes.SET_USER_CALLBACK,
    payload: userCallback
  })
}

export const setUserCpf = (userCpf) => async (dispatch) => {
  dispatch({
    type: actionTypes.SET_USER_CPF,
    payload: userCpf
  })
}

export const setUserName = (userName) => async (dispatch) => {
  dispatch({
    type: actionTypes.SET_USER_NAME,
    payload: userName
  })
}
