export const CustomLayoutDevices = [
  'iPhone_X_Global',
  'iPhone_X_GSM',
  'iPhone_XS',
  'iPhone_XS_Max',
  'iPhone_XS_Max_Global',
  'iPhone_XR',
  'iPhone_11',
  'iPhone_11_Pro',
  'iPhone_11_Pro_Max',
  'iPhone_SE_2nd_Gen',
  'iPhone_12_Mini',
  'iPhone_12',
  'iPhone_12_Pro',
  'iPhone_12_Pro_Max'
]
