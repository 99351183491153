import React, {
	useState
} from 'react'
import PropTypes from 'prop-types';
import { Dialog } from '@material-ui/core';
import { CloseButton } from '../../coupons/CouponOverlay/styles';
import { CustomIcon } from '../CustomIcon';
import { CustomPrimaZoom, DefaultImage, ImageContentZoom, Paragraph } from './style';

const imageToZoomStyle = {
	minHeight: '10.5rem',
	maxHeight: '10.5rem',
	'object-fit': 'cover',
	transform: 'scale(1)'
}

export function CustomImage(props) {
	const [src, setSrc] = useState(props.src)
	const [error, setError] = useState(false)
	const [openModalImage, setOpenModalImage] = useState(false)

	const onError = () => {
		if (!error) {
			setSrc(props.fallbackSrc)
			setError(true)
		}
	}

	const image = (style = {}, removeBorderRadius = false, modalStatus = false) => {
		return (
			<DefaultImage
				alt={props.alt}
				name={props.name}
				id={props.id}
				src={src}
				style={style}
				onError={onError}
				onClick={() => modalStatus && setOpenModalImage(modalStatus)}
				removeBorderRadius={removeBorderRadius}
			/>
		)
	}


	return props.zoom ? (
		<>
			<ImageContentZoom>
				{image(imageToZoomStyle, false, true)}
				<Paragraph>Clique sobre a imagem para dar zoom</Paragraph>
			</ImageContentZoom>

			<Dialog
				open={openModalImage}
				onClose={() => setOpenModalImage(false)}
			>
				<div style={{ overflow: 'auto' }}>
					<CloseButton className='close-button' onClick={() => {
						setOpenModalImage(false)
					}}>
						<CustomIcon name='Times' />
					</CloseButton>
					<CustomPrimaZoom>
						{image({}, true)}
					</CustomPrimaZoom>
				</div>
			</Dialog>
		</>

	) : (
		<img
			alt={props.alt}
			name={props.name}
			id={props.id}
			src={src}
			style={{ borderRadius: '0.5rem' }}
			onError={onError}
		/>
	)
}



CustomImage.propTypes = {
	alt: PropTypes.string,
	fallbackSrc: PropTypes.string,
	id: PropTypes.string,
	name: PropTypes.string,
	src: PropTypes.string,
	zoom: PropTypes.string
}
