import React, {
	useState
} from 'react'
import PropTypes from 'prop-types'

import {
	CustomCheckbox,
	CustomIcon
} from '../../components'

import {
	AdditionalsWrapper,
	AdditionalWrapper,
	AdditionalHeader,
	AdditionalList,
	QuantityControlWrapper,
	Span,
	H3,
	Div,
	Paragraph,
	Button,
	AdditionalsListItemWrapper
} from './styles'

const QuantityControl = ({
	data,
	quantity,
	id,
	handleQuantity
}) => {
	return <QuantityControlWrapper isEmpty={!quantity}>
		{quantity ? <>
			<Button onClick={() => {
				handleQuantity({
					...data,
					id,
					quantity: -1
				})
			}}>
				<CustomIcon name={quantity === 1 ? 'Trash' : 'Minus'} width='10px' />
			</Button>
			<Span>{quantity}</Span>
			<Button onClick={() => {
				if (quantity === 999) {
					return
				}

				handleQuantity({
					...data,
					id,
					quantity: 1
				})
			}}>
				<CustomIcon name='Plus' width='10px' />
			</Button>
		</> : <>
			<Button onClick={() => {
				if (quantity === 999) {
					return
				}

				handleQuantity({
					...data,
					id,
					quantity: 1
				})
			}}>
				<CustomIcon name='Plus' width='12px' />
			</Button>
		</>}
	</QuantityControlWrapper>
}

QuantityControl.propTypes = {
	data: PropTypes.arrayOf(),
	quantity: PropTypes.number,
	id: PropTypes.number,
	handleQuantity: PropTypes.func
}

export function Additionals(props) {
	const {
		data,
		handleQuantity,
		handleChange,
		selectedProduct,
		// cart
	} = props

	const {
		selectedAdditionals
	} = selectedProduct

	// const {
	// 	products
	// } = cart

	// const cartSelectedProductArr = products && products.length && selectedProduct ? (products.filter(filteredItem => filteredItem.id === selectedProduct.id)) : []
	// const cartSelectedProduct = (cartSelectedProductArr.length && cartSelectedProductArr[0]) || {}

	const [update, setUpdate] = useState(0)

	// const {
	// 	selectedAdditionals: cartSelectedAdditionals
	// } = cartSelectedProduct

	return <AdditionalsWrapper id="additionals-wrapper">
		{data && data.map((dataMappedItem, index) => {
			const key = `additionals-${index}-${(new Date()).getTime()}`

			const {
				items: additionalsItems,
				checkbox,
				name,
				minimum,
				maximum,
				groupId,
				calcType
			} = dataMappedItem

			const selectedGroup = (selectedAdditionals && (selectedAdditionals.filter(filteredItem => filteredItem.groupId === groupId && filteredItem.calcType === calcType))[0]) || null

			const selectedGroupItems = selectedGroup && selectedGroup.items
			const selectedGroupItemsQuantity = !checkbox ? selectedGroupItems && selectedGroupItems.reduce((acc, reducedItem) => {
				return acc + reducedItem.quantity
			}, 0) : selectedGroupItems && selectedGroupItems.length

			const maximumQuantity = (selectedGroup && selectedGroup.maximum) || maximum

			return <AdditionalWrapper className={`${(selectedGroup && ((selectedGroup.items && !selectedGroup.items.length) || (!selectedGroup.items))) ? 'required' : ''} ${(selectedGroupItemsQuantity >= maximumQuantity) ? 'maximum' : ''}`} id={`additionals-group-${groupId}-${calcType}`} key={key}>
				<AdditionalHeader>
					<H3>{name}</H3>

					{minimum >= 1 ? <Span>Obrigatório</Span> : null}
				</AdditionalHeader>
				<AdditionalList>
					{additionalsItems && additionalsItems.map((additionalsItemsMappedItem, index) => {
						const key = `additionals-list-item-${index}-${(new Date()).getTime()}`

						const {
							value,
							description,
							name,
							code,
							calcType,
							groupId,
							double
						} = additionalsItemsMappedItem

						const selectedGroup = (selectedAdditionals && (selectedAdditionals.filter(filteredItem => filteredItem.groupId === groupId && filteredItem.calcType === calcType))[0]) || null
						const hasAdded = (selectedGroup && selectedGroup.items && selectedGroup.items.length && (selectedGroup.items.filter(filteredItem => filteredItem.code === code))) || false
						const hasChecked = hasAdded && hasAdded.length

						// const cartSelectedGroup = (cartSelectedAdditionals && (cartSelectedAdditionals.filter(filteredItem => filteredItem.groupId === groupId && filteredItem.calcType === calcType))[0]) || null
						// const hasCartAdded = (cartSelectedGroup && cartSelectedGroup.items && cartSelectedGroup.items.length && (cartSelectedGroup.items.filter(filteredItem => filteredItem.code === code))) || false
						// const hasCartChecked = hasCartAdded && hasCartAdded.length

						const checked = !update ? hasChecked : hasChecked
						const checkedData = !update ? hasAdded : hasAdded

						return <AdditionalsListItemWrapper fixWidh={!checkbox && !!checkedData?.length} key={key} className={`${checkbox ? 'checkbox' : ''} ${checked ? 'checked' : ''}`}>
							<Div>
								<Paragraph className='name'>{name}</Paragraph>
								<Paragraph className='description'>{description}</Paragraph>
								{value && value > 0 && !double ? <Paragraph className='value'>{`+ R$ ${value.toFixed(2).replace('.', ',')}`}</Paragraph> : null}
								{!!double ? <Paragraph className='free'>Grátis!</Paragraph> : null}
							</Div>

							<Div className='actions'>
								{!checkbox ? <QuantityControl
									data={additionalsItemsMappedItem}
									id={code}
									quantity={checkedData && checkedData[0] && checkedData[0].quantity}
									handleQuantity={handleQuantity}
								/> : <CustomCheckbox
									id={`product-additionals-item-${groupId}-${calcType}-${code}`}
									checked={checked}
									onChange={() => {
										setUpdate(true)
										handleChange(additionalsItemsMappedItem, checked, checkbox, selectedGroupItemsQuantity)
									}}
								/>}
							</Div>
						</AdditionalsListItemWrapper>
					})}
				</AdditionalList>
			</AdditionalWrapper>
		})}
	</AdditionalsWrapper>
}

Additionals.propTypes = {
	data: PropTypes.arrayOf(),
	handleQuantity: PropTypes.func,
	handleChange: PropTypes.func,
	selectedProduct: PropTypes.shape({
		id: PropTypes.number,
		selectedAdditionals: PropTypes.arrayOf(PropTypes.shape({}))
	}),
	cart: PropTypes.shape({
		products: PropTypes.arrayOf(PropTypes.shape({}))
	}),
}
