import React from 'react'
import {
    connect
} from 'react-redux'

import {
    pipe
} from '../../../domain/helpers'
import {
    Dialog,
    Wrapper
} from './styles'
import { QRcode } from '../../components/QRcode'
import { URL_DOWNLOAD_APP } from '../../../config'

function AppDownloadComponent(props) {
    return <Wrapper>
        <h3>Escaneie o QR code e baixe nosso app agora!</h3>
        <QRcode code={URL_DOWNLOAD_APP} /> 
        <Dialog>
            Mais rápido e prático
        </Dialog>
    </Wrapper>
}

const mapStateToProps = (state) => {
    return {
        data: (state.main.cart && state.main.cart.products) || []
    }
}

const GetConnection = connect(mapStateToProps, {})

export const AppDownload = React.memo(pipe(
    GetConnection
)(AppDownloadComponent))
