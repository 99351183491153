import React from 'react'
import styled from 'styled-components'

import LoadingGIF from '../../assets/ipiranga-loading.gif'

import {
  CustomIcon
} from '../../components'

import {
  getCardBrandImageById,
  numberToCurrency,
  formatCpf
} from '../../../infra/utils'

const Wrapper = styled.div`
  display : flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  margin-top: 0;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #fff;
    box-sizing: border-box;
    min-width: 100%;
    flex: 1;
    border-bottom: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textFourthColor) || '#CCC' } 0.063rem solid;
    padding: 1.25rem;

    > p {
      color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textSecondaryColor) || '#444' };
      font-size: 0.875rem;
    }
  }
`

const H2 = styled.h2`
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  text-align: left;
  margin: 0;
  padding: 0;
  min-width: 100%;
  flex: 1;

  > span.name {
    color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
  }

  > span.status_0 {
    color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
  }

  > span.status_1 {
    color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textEighthColor) || '#444' };
  }
`

const H3 = styled.h3`
  font-size: 1.125rem;
  color: #000;
  margin: 0;
  padding: 0;
  margin-bottom: 1.25rem;
  min-width: 100%;
`

const List = styled.ul`
  list-style: inside none;
  min-width: 100%;
  padding: 0;
  margin: 0;
  flex: 1;
`

const ListItem = styled.li`
  min-width: 100%;
  margin-bottom: 0.625rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: #000;
  font-size: 1rem;

  &:last-child {
    margin-bottom: 0;
  }

  > span {
    flex: 1;

    &:nth-child(1) {
      flex: 0;
      padding-right: 0.625rem;
    }

    &:nth-child(2) {
      > span {
        display: block;
        font-size: 0.75rem;
        color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textEleventhColor) || '#333' };
      }
    }

    &:nth-child(3) {
      text-align: right;
    }
  }
`

const ImageContainer = styled.img`
  width: 6.25rem;
  height: auto;
`

const Paragraph = styled.p`  
  min-width: 100%;
  padding: 0;
  margin: 0;
  color: #7D7D7D;
`

const Div = styled.div``

const Summary = styled.div`
  min-width: 100%;
  text-align: right;
  flex: 1;
 
  > p {
    margin: 0 auto;

    &.date {
      color: #757575;
    }

    &:nth-child(2) {
      margin-bottom: 0.938rem;
    }

    > strong {
      color: #000;
    }
  }
`

const Footer = styled.footer`  
  flex: 1;
  min-width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.938rem;
  padding-top: 1.25rem;

  > p {
    margin-bottom: 0.938rem;
  }
`

const Button = styled.button`
  background: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
  margin: 0 1.25rem;
  font-size: 1.25rem;
  padding: 0.5rem 0.938rem;
  box-sizing: border-box;
  min-width: calc(100% - 2.5rem);
  max-width: calc(100% - 2.5rem);
  color: #fff;
  text-transform: uppercase;
  border-radius: 7px;
  font-weight: bold;
`

const SelectedCardContainer = styled.p`
  margin: 0;
  width: 100%;
  max-width: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  > span {
    font-size: 0.75rem;
    font-weight: bold;
    color: #686868;

    &:nth-child(2) {
      font-size: 0.875rem;
      font-weight: normal;
    }

    > img {
      margin-right: 0.313rem;
    }

    > a {
      margin-left: 0.313rem;

      > img {
        width: 0.938rem;
        height: auto;
      }
    }
  }
`

const Span = styled.span``

const SelectedCard = ({ brand, number}) => {
  return <SelectedCardContainer>
    <span>
      { brand && number ? <>
        <img src={getCardBrandImageById(brand)} alt={`Bandeira do cartao: ${getCardBrandImageById(brand)}`} />

        { number }
      </>: '' }
    </span>
  </SelectedCardContainer>
}

export function Counter(props) {
  const {
    userName,
    cpf,
    cardBrand,
    cardNumber,
    useCpf,
    status,
    orderDate,
    requestNumber,
    products,
    handleFinishOrder,
    cashChange,
    deliveryAddress,
    scheduling
  } = props

  const {
    date,
    schedule
  } = scheduling

  const DiscountText = () => (
    products.summary.discount ?
      <Paragraph>
        Desconto: <Span>{`R$ ${products.summary.discount.toFixed(2).replace('.', ',')}` }</Span>
      </Paragraph> : null
  )

  return <Wrapper id='counter-receipt'>
    <Div className={ !status ? '' : (status === 2 ? 'status_1' : 'status_0')}>
      {status === null && <ImageContainer src={LoadingGIF} alt={`Carregando…`} />}

      {status === 1 && <>
        <H2>
          <Span className='name'>{userName}</Span>, <br />
          seu pedido foi confirmado!
        </H2>

        <Paragraph className='date'>{ orderDate }</Paragraph>
      </>}

      {status === 2 && requestNumber && <>
        <H2>
          <Span className='name'>{userName}</Span>, <br />
          seu pedido está pronto!
        </H2>
      </>}
    </Div>

    {status && <>
      <Div>
        <H3>Dia e horário agendado</H3>

        <Paragraph><CustomIcon name='Calendar' /> { date }</Paragraph>

        <Paragraph><CustomIcon name='Clock' /> { schedule } </Paragraph>
      </Div>

      <Div>
        <H3>Local de entrega</H3>

        <Paragraph>{ deliveryAddress }</Paragraph>
      </Div>

      <Div>
        <H3>Resumo do Pedido</H3>

        <List>
          {
            products && products.list && products.list.map((item, index) => {
              const {
                Quantidade: qty,
                Produto: name,
                Valor: value,
                Descricao: description,
                TextoAcompanhamento: extra
              } = item

              const key = `checkout-listitem-${index}`

              return <ListItem key={key}>
                <span>{ `${qty}x` }</span>

                <span name='info'>
                  { name }

                  <span>  
                    { extra || description }
                  </span>
                </span>

                <span name='info'>{`${numberToCurrency(value)}`}</span>
              </ListItem>
            })
          }
        </List>
      </Div>

      {products.summary && <Summary>
        <Paragraph>Subtotal <span>{`${numberToCurrency(products.summary.subtotal)}`}</span></Paragraph>
        <DiscountText />
        <Paragraph><strong>Total: {`${numberToCurrency(products.summary.total)}`}</strong></Paragraph>
      </Summary>}

      <Footer>
        {useCpf && <Paragraph>CPF na nota: {formatCpf(cpf)}</Paragraph>}

        {cashChange && <Paragraph>Troco para: {`${numberToCurrency(cashChange)}`}</Paragraph>}

        <SelectedCard brand={cardBrand || 1} number={cardNumber} />

        <Button disabled={ (status !== 2 ? true : false) } onClick={() => {
          handleFinishOrder()
        }}>Voltar para início</Button>
      </Footer>
    </>}
  </Wrapper>
}
