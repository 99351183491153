import styled from 'styled-components'

export const CashbackContent = styled.div`
  display: flex;
  align-items: center;
  background-color: #00a958;
  color: #fff;
  font-size: .8rem;
  font-weight: bold;
  padding: .2rem 4.5rem;
  border-bottom-right-radius: 16px;
  width: max-content;
  & svg {
    margin-right: 8px;
    width: 16px;
  }
  @media (min-width: 35rem) and (max-width: 60rem) {
    padding: .2rem 5rem .2rem 1rem !important;
    font-size: 1rem;
  }
  @media (max-width: 35rem) {
    position: absolute;
    padding: .2rem 2rem .2rem 1rem;
    font-size: 1rem;
  }

  ${props => (props.smallSizeOnly && props.top) && `
      position: absolute !important;
      padding: .2rem 1rem .2rem 1rem !important;
      font-size: 1rem !important;
      font-size: .75rem !important;
  ` }
`
