import React, {
  useEffect, useState
} from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import {
  CustomIcon
} from '../../components'

import history from '../../../history'

import {
	setQuantity,
	setSearchProductCategory,
	setProductCategory
} from '../../../redux/actions/main'

import { CartBar } from '../CartBar'

import {
	Coupon,
	List
} from '..'

import { Search } from './Search'

import {
  Wrapper,
  StoreWrapper,
  H1,
  MobileHeaderWrapper,
  CloseButton,
  Space
} from './styles'

function CategoriesProductsComponent(props) {
  const {
    storeName,
    selectedCategory,
    cart,
    selectedProducts,
    storeLogo,
    storeStatus,
    searchProductCategory,
    setQuantity,
    handleViewProduct,
    handleAddProduct,
    setSearchProductCategory,
    setProductCategory,
    searchProducts,
    coupon,
    close,
    URLParameters
  } = props

  const categoryId = selectedCategory.id
  const categoryName = selectedCategory.name
  const displayFull = true

  const searchText = searchProductCategory && searchProductCategory.text

  const [renderCartBar, setRenderCartBar] = useState(false)

  useEffect(() => {
    setSearchProductCategory({ text: '' })

    return () => { }
  }, [
    setSearchProductCategory
  ])

	function handleQuantity(args) {
    setQuantity(args)
  }

  function handleSearchText(args) {
    postSearchText(args)
  }

  function postSearchText(args) {
    setSearchProductCategory({
      text: args
    })
  }

  function handleSearch() {
    const productsResults = selectedProducts.filter(filteredItem => (filteredItem.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
      filteredItem.description.toLowerCase().indexOf(searchText.toLowerCase()) > -1))

    if (searchText.length) {
      setProductCategory(productsResults)

      setTimeout(() => {

      }, 100)

      return
    }

    setProductCategory({})

  }

  return <Wrapper id='store-categories-products'>
    <MobileHeaderWrapper>
      <CloseButton className='close' onClick={() => {
        close()
      }}>
        <CustomIcon name='Times' />
      </CloseButton>
    </MobileHeaderWrapper>

    <StoreWrapper>{storeName}</StoreWrapper>

    <Coupon show={!!coupon} data={coupon} />

    <Search searchText={searchText} handleSearchText={handleSearchText} handleSearch={handleSearch} />

    <H1>{categoryName}</H1>

    <List
      direction={'horizontal'}
      categoryId={categoryId}
      categoryName={categoryName}
      handleQuantity={handleQuantity}
      handleViewProduct={handleViewProduct}
      handleAddProduct={handleAddProduct}
      cart={cart}
      products={searchProducts.length > 0 ? searchProducts : selectedProducts}
      storeImg={storeLogo}
      status={storeStatus}
      displayFull={displayFull}
    />

    <Space></Space>
    <CartBar
      renderCartBar={renderCartBar}
      setRenderCartBar={setRenderCartBar}
      handleClick={() => {
        history.push(`/cart${URLParameters}`)
      }} />

  </Wrapper>
}

CategoriesProductsComponent.propTypes = {
	storeName: PropTypes.string,
	selectedCategory: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string
	}),
	cart: PropTypes.string,
	selectedProducts: PropTypes.arrayOf(PropTypes.shape({})),
	storeLogo: PropTypes.string,
	storeStatus: PropTypes.number,
	searchProductCategory: PropTypes.shape({
		text: PropTypes.string
	}),
	setQuantity: PropTypes.func,
	handleViewProduct: PropTypes.func,
	handleAddProduct: PropTypes.func,
	setSearchProductCategory: PropTypes.func,
	setProductCategory: PropTypes.func,
	searchProducts: PropTypes.arrayOf(PropTypes.shape({})),
	coupon: PropTypes.shape({}),
	close: PropTypes.func,
	URLParameters: PropTypes.string
}

const mapStateToProps = (state) => {
  return {
    storeStatus: state.store.store && state.store.store.status,
    selectedProduct: (state.menu.selectedProduct) || null,

    storeName: state.store.store && state.store.store.name,
    storeLogo: state.store.store && state.store.store.logo,
    selectedCategory: state.store.store && state.store.store.selectedCategory,
    cart: state.main.cart,
    modalityId: state.main.modality.id,
    selectedProducts: state.store.store && state.store.store.products,
    searchProductCategory: state.main.searchProductCategory,
    searchProducts: (state.store.store && state.store.store.searchProducts) || {},
    coupon: state.store.store && state.store.store.coupon,
    URLParameters: (state.main.URLParameters) || ''
  }
}

const Connected = connect(mapStateToProps, {
  setSearchProductCategory,
  setProductCategory,
	setQuantity
})(withRouter(CategoriesProductsComponent))

export function CategoriesProducts(props) {
  return <Connected {...props} />
}
