import { combineReducers } from 'redux'

import main from './reducers/main'

import {
	addressReducer,
	loadingReducer,
	signUpReducer,
	userAddressReducer,
	userReducer,
	cartReducer,
	menuReducer,
	sidebarReducer,
	layoutReducer,
	storeReducer
} from '.'

export default combineReducers({
	address: addressReducer,
	loading: loadingReducer,
	user: userReducer,
	signUp: signUpReducer,
	userAddress: userAddressReducer,
	cart: cartReducer,
	menu: menuReducer,
	sidebar: sidebarReducer,
	layout: layoutReducer,
	store: storeReducer,

  main
})
