import React, {
  useEffect, useState
} from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import {
  CustomIcon
} from '../../components'

import { Product } from '../'

import { Search } from './Search'

import {
	setSearchProductCategory,
	setProductCategory
} from '../../../redux/actions/main'

import { Coupon } from '../Coupon'

import {
  Wrapper,
  H1,
  WrapperHeader,
  BackButton,
  PageTitle,
  Space
} from './styles'
import { CartBar } from '../CartBar'
import history from '../../../history'

function CategoriesProductsCardComponent(props) {
  const {
    storeName,
    selectedCategory,
    cart,
    selectedProducts,
    storeImg,
    storeStatus,
    searchProductCategory,
    handleViewProduct,
    handleAddProduct,
    setSearchProductCategory,
    setProductCategory,
    coupon,
    close,
    storeLogo
  } = props

  const categoryId = selectedCategory.id
  const categoryName = selectedCategory.name

  const searchText = searchProductCategory && searchProductCategory.text

  const [renderCartBar, setRenderCartBar] = useState(false)

  useEffect(() => {
    setSearchProductCategory({ text: '' })

    return () => { }
  }, [
    setSearchProductCategory
  ])

  function handleSearchText(args) {
    postSearchText(args)
  }

  function postSearchText(args) {
    setSearchProductCategory({
      text: args
    })
  }

  function handleSearch() {
    const productsResults = selectedProducts.filter(filteredItem => (filteredItem.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
      filteredItem.description.toLowerCase().indexOf(searchText.toLowerCase()) > -1))

    if (searchText.length) {
      setProductCategory(productsResults)

      setTimeout(() => {

      }, 100)

      return
    }

    setProductCategory({})

  }

  return <Wrapper id='store-categories-products-card'>
    <WrapperHeader>
      <BackButton onClick={() => {
        close()
      }}
      >
        <CustomIcon name='AngleLeft' />
      </BackButton>
      <PageTitle>{storeName}</PageTitle>
    </WrapperHeader>

    <Coupon show={!!coupon} data={coupon} />

    <Search searchText={searchText} handleSearchText={handleSearchText} handleSearch={handleSearch} />

    <H1>{categoryName}</H1>

    {selectedProducts && selectedProducts.map((mappedItem, index) => {
      const {
        id,
        name,
        description,
        image,
        value,
        additionals,
        promotionalValue
      } = mappedItem

      const key = `stores-products-category-group-${index}`
      const formattedPrice = `R$ ${Number(value).toFixed(2).replace('.', ',')}`
      const formattedPricePromotional = promotionalValue ? `R$ ${Number(promotionalValue).toFixed(2).replace('.', ',')}` : null
      const cartProductPrevious = cart && (cart.products && cart.products.length) ? (cart.products.filter(filteredItem => filteredItem.id === id))[0] : null
      const cartProductPreviousQty = cartProductPrevious && cartProductPrevious.quantity
      const selectedAdditionals = (cartProductPrevious && cartProductPrevious.selectedAdditionals) || []
			const requiredAdditionals = additionals && additionals.length && additionals.filter(item => item.minimum >= 1)
			const hasRequiredAdditionals = requiredAdditionals && requiredAdditionals.length ? true : false
      
			return <Product
				hasRequiredAdditionals={hasRequiredAdditionals}
        direction={'card'}
        key={key}
        quantity={cartProductPreviousQty}
        selectedCategory={{
          name: categoryName,
          id: categoryId
        }}
        handleView={handleViewProduct}
        handleAdd={handleAddProduct}
        id={id}
        name={name}
        description={description}
        image={image}
        storeLogo={storeLogo}
        value={formattedPrice}
        rawPrice={value}
        additionals={additionals}
        selectedAdditionals={selectedAdditionals}
        promotionalValue={formattedPricePromotional}
        storeImg={storeImg}
        categories={true}
        status={storeStatus}
        className={`view-all-products`}
      />
    })}

    <Space></Space>
    <CartBar
      renderCartBar={renderCartBar}
      setRenderCartBar={setRenderCartBar}
      handleClick={() => {
        history.push('/cart')
      }} />

  </Wrapper>
}

CategoriesProductsCardComponent.propTypes = {
	storeName: PropTypes.string,
	selectedCategory: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string
	}),
	cart: PropTypes.shape({
		products: PropTypes.arrayOf(PropTypes.shape({}))
	}),
	selectedProducts: PropTypes.arrayOf(PropTypes.shape({})),
	storeImg: PropTypes.string,
	storeStatus: PropTypes.number,
	searchProductCategory: PropTypes.string,
	handleViewProduct: PropTypes.func,
	handleAddProduct: PropTypes.func,
	setSearchProductCategory: PropTypes.func,
	setProductCategory: PropTypes.func,
	coupon: PropTypes.shape({}),
	close: PropTypes.func,
	storeLogo: PropTypes.string
}

const mapStateToProps = (state) => {
  return {
    storeStatus: state.store.store && state.store.store.status,
    storeName: state.store.store && state.store.store.name,
    storeImg: state.store.store && state.store.store.logo,
    selectedProduct: (state.menu.selectedProduct) || null,
    selectedCategory: state.store.store && state.store.store.selectedCategory,
    cart: state.main.cart,
    modalityId: state.main.modality.id,
    selectedProducts: state.store.store && state.store.store.products,
    searchProductCategory: state.main.searchProductCategory,
    searchProducts: (state.store.store && state.store.store.searchProducts) || {},
    coupon: state.store.store && state.store.store.coupon
  }
}

const Connected = connect(mapStateToProps, {
  setSearchProductCategory,
  setProductCategory
})(withRouter(CategoriesProductsCardComponent))

export function CategoriesProductsCard(props) {
  return <Connected {...props} />
}
