import React from 'react'
import PropTypes from 'prop-types'

import history from '../../../history'

import { ReactComponent as IconReceipt } from '../../assets/icon_comprovante.svg'

import {
  Div,
  DefaultParagraph as Paragraph,
  H2,
  Button
} from '../../components'

import {
  Wrapper,
  PageTitle
} from './styles'

export function EmptyContent({
  URLParameters,
  data
}) {
  const {
    content
  } = data

  return <Wrapper>
    <PageTitle>HISTÓRICO DE PEDIDOS</PageTitle>
    <Div className='header'>
      <IconReceipt />
    </Div>
    <Div className='content'>
      <H2>{content.title}</H2>

      <Paragraph>{content.text}</Paragraph>

      <Button onClick={() => history.push(`/${URLParameters}`)}>Ir para o cardápio</Button>
    </Div>
  </Wrapper>
}

EmptyContent.propTypes = {
	URLParameters: PropTypes.string,
  data: PropTypes.shape({
		content: PropTypes.shape({
			title: PropTypes.string,
			text: PropTypes.string
		})
	})
}
