import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Wrapper = styled.div`
  padding: 1.25rem;
  border-radius: 0.938rem;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: calc(100% - 2.5rem);
  min-width: calc(100% - 2.5rem);
  box-sizing: border-box;
  position: relative;

  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    min-width: 100%;

    > button {
      margin-right: 0.938rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }
`

const H4 = styled.h4`
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.thirdColor) || '#000' };
  font-size: 1rem;
  line-height: 1rem;
  margin: 0;
  padding: 0;
  flex: 1;
  text-align: left;
  margin-bottom: 1.25rem;
`

const Paragraph = styled.p`
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#000' };
  font-size: 0.875rem;
  line-height: 0.875rem;
  margin: 0;
  padding: 0;
  flex: 1;
  text-align: left;
  min-width: 100%;
  margin-bottom: 1.25rem;
`

const Button = styled.button`
  background: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
  border-radius: 0.938rem;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.invertedPrimaryColor) || '#FFF' };
  min-height: 2.5rem;
  max-height: 2.5rem;
  line-height: 1.25rem;
  padding: 0.625rem 0.938rem;
  box-sizing: border-box;
  flex: 1;
  font-size: 0.875rem;
  font-weight: bold;
  white-space: nowrap;
`

const Div = styled.div``

export function Warning(props) {
  const {
    title,
    message,
    actions
  } = props

  return <Wrapper>
    { title ? <H4>{ title }</H4> : null }

    { message ? <Paragraph>{ message }</Paragraph> : null }

    { actions ? <Div>
      { actions.map((actionsMappedItem, index) => {
        const {
          label,
          handleClick
        } = actionsMappedItem

        const key = `overlay-warning-button-item-${ index }-${ new Date().getTime() }`
      
        return <Button key={ key } onClick={ () => {
          handleClick()
        } }>
            { label }
          </Button>
      }) }
    </Div> : null }
  </Wrapper>
}

Warning.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.node)
}
