interface iMenuCategoryProductTagData {
	type: number
	format: number
	text: string
	fontColor: string
	backgroundColor: string
}

interface iMenuCategoryProductAdditionalData {
}

interface iMenuCategoryProductData {
	id: number
	name: string
	description: string
	value: number
	promotionalValue: number | null
	showFromValueText: boolean
	fromValue: number | null
	packageValue: number
	delivery: boolean
	image: string
	rewardImage: string
	promotionInactivated: boolean
	promotional: boolean
	additionals: iMenuCategoryProductAdditionalData[]
	couponId: number
	couponName: string
	points: number
	pointsLoyaltyModelId: number
	level: number
	rewardLoyaltyProductId: number
	statusId: number | boolean
	couponProductsPurchaselimit: number | null
	deliveryFree: boolean
	tags: iMenuCategoryProductTagData
	couponTypeValueId: number | null
	couponDiscount: number | null
	success: boolean
	message: string
}

interface iMenuCategoryData {
	id: number
	name: string
	promotional: boolean
	products: iMenuCategoryProductData[]
	coupon: boolean
	productLimit: number
	reward: boolean
}

interface iMenuData {
	categories: iMenuCategoryData[]
	success: boolean
	message: string
}

interface iMenuCategoryProductTagResponse {
	TipoTag: number
	FormatoTag: number
	TextoTag: string
	CorFonteTag: string
	CorFundoTag: string
}

interface iMenuCategoryProductAdditionalResponse {
}

interface iMenuCategoryProductResponse {
	Id: number
	Nome: string
	Descricao: string
	Valor: number
	ValorPromocional: number | null
	ExibirApartirDe: boolean
	ValorApartirDe: number | null
	ValorEmbalagem: number
	Delivery: boolean
	Imagem: string
	ImagemPremio: string
	PromocaoInativada: boolean
	Promocional: boolean
	Acompanhamentos: iMenuCategoryProductAdditionalResponse[]
	CupomId: number | null
	NomeCupom: string | null
	Pontos: number
	FidelidadeModeloPontosId: number
	Nivel: number
	FidelidadeProdutoPremioId: number
	StatusId: boolean | number
	LimiteCompraProdutosCupom: number | null
	FreteGratis: boolean
	Tags: iMenuCategoryProductTagResponse[]
	TipoValorCupomId: number | null
	DescontoCupom: number | null
	Sucesso: boolean
	Mensagem: string
}

interface iMenuCategoryResponse {
	Id: number
	Nome: string
	Promocional: boolean
	Produtos: iMenuCategoryProductResponse[]
	Cupom: boolean
	LimiteProduto: number
	Premio: boolean
}

export const mapMenuData = (data: any, index: number): iMenuData => {
	const categories = data.Categorias && data.Categorias.map((mappedItem: iMenuCategoryResponse) => {
		const products = mappedItem.Produtos.map((mappedItem: iMenuCategoryProductResponse) => {
			const tags = mappedItem.Tags.map((mappedItem: iMenuCategoryProductTagResponse) => {
				const tagDTO = {
					type: mappedItem.TipoTag,
					format: mappedItem.FormatoTag,
					text: mappedItem.TextoTag,
					fontColor: mappedItem.CorFonteTag,
					backgroundColor: mappedItem.CorFundoTag
				}

				return tagDTO
			})

			const mappedAdditionals = mappedItem['Acompanhamentos'] && mappedItem['Acompanhamentos'].length && mappedItem['Acompanhamentos'].map((additionalsMappedItem: any) => {
				const additionalsItems = additionalsMappedItem['Itens'] && additionalsMappedItem['Itens'].length && additionalsMappedItem['Itens'].map((additionalsItemsMappedItem: any) => {
					return {
						counter: additionalsItemsMappedItem['Balcao'],
						delivery: additionalsItemsMappedItem['Delivery'],
						description: additionalsItemsMappedItem['Descricao'],
						name: additionalsItemsMappedItem['Nome'],
						value: additionalsItemsMappedItem['Valor'],
						packPrice: additionalsItemsMappedItem['ValorEmbalagem'],
						code: additionalsItemsMappedItem['Codigo'],
						calcType: additionalsMappedItem['TipoCalculoCustomizacao'],
						groupId: additionalsItemsMappedItem['GrupoId'],
						minimum: additionalsMappedItem['Minimo'],
						maximum: additionalsMappedItem['Maximo'],
					}
				})

				return {
					name: additionalsMappedItem['Nome'],
					minimum: additionalsMappedItem['Minimo'],
					maximum: additionalsMappedItem['Maximo'],
					checkbox: additionalsMappedItem['Checkbox'],
					calcType: additionalsMappedItem['TipoCalculoCustomizacao'],
					promotional: additionalsMappedItem['Promocional'],
					groupId: additionalsItems[0].groupId,
					items: additionalsItems
				}
			})

			const id = mappedItem.Id
			const name = mappedItem.Nome
			const description = mappedItem.Descricao
			const value = mappedItem.Valor
			const promotionalValue = mappedItem.ValorPromocional
			const showFromValueText = mappedItem.ExibirApartirDe
			const fromValue = mappedItem.ValorApartirDe
			const packageValue = mappedItem.ValorEmbalagem
			const delivery = mappedItem.Delivery
			const image = mappedItem.Imagem
			const rewardImage = mappedItem.ImagemPremio
			const promotionInactivated = mappedItem.PromocaoInativada
			const promotional = mappedItem.Promocional
			const additionals = mappedAdditionals || []
			const couponId = mappedItem.CupomId
			const couponName = mappedItem.NomeCupom
			const points = mappedItem.Pontos
			const pointsLoyaltyModelId = mappedItem.FidelidadeModeloPontosId
			const level = mappedItem.Nivel
			const rewardLoyaltyProductId = mappedItem.FidelidadeProdutoPremioId
			const statusId = mappedItem.StatusId
			const couponProductsPurchaselimit = mappedItem.LimiteCompraProdutosCupom
			const deliveryFree = mappedItem.FreteGratis
			const couponTypeValueId = mappedItem.TipoValorCupomId
			const couponDiscount = mappedItem.DescontoCupom
			const success = mappedItem.Sucesso
			const message = mappedItem.Mensagem
			const loyaltyModelPointsId = mappedItem.FidelidadeModeloPontosId

			return {
				id,
				name,
				description,
				value,
				promotionalValue,
				showFromValueText,
				fromValue,
				packageValue,
				delivery,
				image,
				rewardImage,
				promotionInactivated,
				promotional,
				additionals,
				couponId,
				couponName,
				points,
				pointsLoyaltyModelId,
				level,
				rewardLoyaltyProductId,
				statusId,
				couponProductsPurchaselimit,
				deliveryFree,
				tags,
				couponTypeValueId,
				couponDiscount,
				success,
				message,
				loyaltyModelPointsId
			}
		})

		const id = mappedItem.Id
		const name = mappedItem.Nome
		const promotional = mappedItem.Promocional
		const coupon = mappedItem.Cupom
		const productLimit = mappedItem.LimiteProduto
		const reward = mappedItem.Premio

		return {
			id,
			name,
			promotional,
			products,
			coupon,
			productLimit,
			reward
		}
	})
	const success = data['Sucesso'] || false
	const message = data['Mensagem'] || ''

	return {
		categories,
		success,
		message
	}
}
