import { PAYMENT_TYPES } from '../../domain/enum/payment'
import alelo from '../../view/assets/card_brand_alelo.svg'
import ben from '../../view/assets/card_brand_ben.svg'
import sodexo from '../../view/assets/card_brand_sodexo.svg'
import ticket from '../../view/assets/card_brand_ticket.svg'
import vr from '../../view/assets/card_brand_vr.svg'
import cash from '../../view/assets/cash_payment.svg'
import getCardBrandImageById from './getCardBrandImageById'


const getCardBrandImageByPaymentTypeAndId = (paymentType, brandId) => {
  switch(paymentType) {
    case PAYMENT_TYPES.ALELO:
      return alelo
    case PAYMENT_TYPES.BEN:
      return ben
    case PAYMENT_TYPES.TICKET_VR:
      return ticket
    case PAYMENT_TYPES.SODEXO:
      return sodexo
    case PAYMENT_TYPES.VR:
      return vr
    case PAYMENT_TYPES.CASH:
      return cash
    default:
      return getCardBrandImageById(brandId)
  }
}

export default getCardBrandImageByPaymentTypeAndId
