import React from 'react'
import PropTypes from 'prop-types'
import ContentLoader from 'react-content-loader'

function SkeletonContent(props) {
  const {
    module
  } = props

  if (module === 'home') {
    return <>
      {/* Highlights */}
      <rect x='0' y='0' rx='4' ry='4' width='100%' height='235' />

      {/* Modality */}
      <rect x='32%' y='300' rx='4' ry='4' width='150' height='30' />

      {/* Agglomerations */}
      <rect x='0' y='380' rx='4' ry='4' width='250' height='20' />
      <rect x='0' y='420' rx='4' ry='4' width='90' height='90' />
      <rect x='35%' y='420' rx='4' ry='4' width='90' height='90' />
      <rect x='70%' y='420' rx='4' ry='4' width='90' height='90' />
    </>
  }

  if (module === 'stores-list') {
    return <>
      {/* Title */}
      <rect x='0' y='0' rx='4' ry='4' width='100%' height='25' />
      <rect x='0' y='30' rx='4' ry='4' width='50%' height='25' />

      {/* First Store */}
      <circle cx='45' cy='125' r='35' width='35%' height='auto' />
      <rect x='25%' y='80' rx='4' ry='4' width='75%' height='25' />
			<rect x='25%' y='110' rx='4' ry='4' width='75%' height='25' />
			<rect x='25%' y='140' rx='4' ry='4' width='75%' height='25' />
    </>
  }

  if (module === 'store-mobile') {
    return <>
      <rect x="0" y="0" rx="4" ry="4" width="100%" height="210" />
      <circle cx="83%" cy="205" r="35" />
      <rect x="10" y="220" rx="4" ry="4" width="150" height="20" />
      <rect x="10" y="250" rx="4" ry="4" width="120" height="15" />

      <rect x="3%" y="280" rx="4" ry="4" width="110" height="15" />
      <rect x="34%" y="280" rx="4" ry="4" width="2" height="15" />
      <rect x="36%" y="280" rx="4" ry="4" width="110" height="15" />
      <rect x="67%" y="280" rx="4" ry="4" width="2" height="15" />
      <rect x="69%" y="280" rx="4" ry="4" width="110" height="15" />
      <rect x="3%" y="305" rx="4" ry="4" width="110" height="15" />
      <rect x="36%" y="305" rx="4" ry="4" width="110" height="15" />
      <rect x="69%" y="305" rx="4" ry="4" width="110" height="15" />

      <rect x="10" y="360" rx="4" ry="4" width="150" height="20" />
      <rect x="0" y="400" rx="4" ry="4" width="100%" height="1" />
      <rect x="10" y="420" rx="4" ry="4" width="170" height="20" />
      <rect x="10" y="450" rx="4" ry="4" width="250" height="15" />
      <rect x="10" y="470" rx="4" ry="4" width="220" height="15" />
      <rect x="10" y="490" rx="4" ry="4" width="170" height="20" />
      <rect x="70%" y="420" rx="4" ry="4" width="90" height="90" />

      <rect x="0" y="520" rx="4" ry="4" width="100%" height="1" />
      <rect x="10" y="540" rx="4" ry="4" width="170" height="20" />
      <rect x="10" y="570" rx="4" ry="4" width="250" height="15" />
      <rect x="10" y="590" rx="4" ry="4" width="220" height="15" />
      <rect x="10" y="610" rx="4" ry="4" width="170" height="20" />
      <rect x="70%" y="540" rx="4" ry="4" width="90" height="90" />

      <rect x="0" y="640" rx="4" ry="4" width="100%" height="1" />
      <rect x="10" y="660" rx="4" ry="4" width="170" height="20" />
      <rect x="10" y="690" rx="4" ry="4" width="250" height="15" />
      <rect x="10" y="710" rx="4" ry="4" width="220" height="15" />
      <rect x="10" y="730" rx="4" ry="4" width="170" height="20" />
      <rect x="70%" y="660" rx="4" ry="4" width="90" height="90" />
    </>
  }

  if (module === 'store') {
    return <>
      <rect x="0" y="0" rx="0" ry="0" width="100%" height="60" />
      <circle cx="10%" cy="86" r="17" />
      <rect x="65" y="75" rx="2" ry="2" width="60" height="5" />
      <rect x="65" y="85" rx="2" ry="2" width="80" height="5" />
      <rect x="65" y="95" rx="2" ry="2" width="100" height="5" />
      <rect x="0" y="115" rx="0" ry="0" width="100%" height="1" />

      <rect x="20" y="130" rx="3" ry="3" width="200" height="5" />
      <rect x="20" y="140" rx="3" ry="3" width="220" height="5" />
      <rect x="20" y="150" rx="3" ry="3" width="250" height="5" />
      <rect x="20" y="160" rx="3" ry="3" width="150" height="5" />
      <rect x="20" y="170" rx="3" ry="3" width="110" height="5" />
      <rect x="20" y="180" rx="3" ry="3" width="80" height="5" />
    </>
  }

  if (module === 'header') {
    return <>
      <circle cx="5%" cy="13" r="10" />
      <rect x="35" y="10" rx="2" ry="2" width="60" height="4" />
      <rect x="35" y="16" rx="2" ry="2" width="80" height="4" />
      <rect x="80%" y="10" rx="2" ry="2" width="30" height="4" />
      <rect x="89%" y="10" rx="2" ry="2" width="30" height="4" />
    </>
  }

  if (module === 'coupons-list') {
    return <>
      {/* Title */}
      <rect x='0' y='0' rx='4' ry='4' width='100%' height='30' />

      <rect x='0' y='40' rx='4' ry='4' width='100%' height='60' />

      <rect x='0' y='110' rx='4' ry='4' width='100%' height='30' />

      <rect x='0' y='150' rx='16' ry='16' width='100%' height='70' />

      <rect x='0' y='230' rx='16' ry='16' width='100%' height='70' />

      <rect x='0' y='310' rx='16' ry='16' width='100%' height='70' />
    </>
  }
}

SkeletonContent.propTypes = {
  module: PropTypes.string.isRequired
}

export function Skeleton(props) {
  const {
    module
  } = props

  return <ContentLoader viewBox={
    module === 'store' ? '0 0 380 200' :
      module === 'header' ? '0 0 380 27' : 
        module === 'coupons-list' ? '0 0 380 380' : '0 0 380 900'
    } backgroundColor={'#dedede'} foregroundColor={'#e6e6e6'}>
    <SkeletonContent module={module} />
  </ContentLoader>
}

Skeleton.propTypes = {
  module: PropTypes.string.isRequired
}
