import React, {
  useState
} from 'react'
import styled from 'styled-components'

import {
	CustomCheckbox,
	CustomIcon
} from '../../components'

const AdditionalsWrapper = styled.div`
  min-width: 100%;
`

const AdditionalWrapper = styled.div`
	&.maximum {
    > ul {
      > li {
        &:not(.checked) {
          opacity: .5;
        }
      }
    }
  }

  &.required {
    > div {

      > h3 {
        color: #F00;
        animation: required .5s;
        position: relative;
      }

      > span {
        color: #F00;
        border-color: #F00;
        animation: required .5s;
        position: relative;
      }
    }
  }

  @keyframes required {
    from { left: 0;}
    25% { left: 0.938rem }
    50% { left: -15px }
    75% { left: 0.938rem }
    to { left: 0; }
  }
`

const AdditionalHeader = styled.div`
  background: #F6F6F6;
  flex: 1;
  padding: 0.938rem 1.25rem;
  margin: 0 -1.25rem;
  margin-bottom: 1.25rem;
  min-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  > h3 {
    flex: 1;
    color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000'};
    text-transform: uppercase;
  }

  > span {
    border: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'} 0.063rem solid;
    color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333' };
    font-size: 0.875rem;
    height: 1.25rem;
    border-radius: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0.625rem;
    flex: 1;
    max-width: 6.25rem;
    min-width: 6.25rem;
  }
`

const AdditionalList = styled.ul`
  list-style: inside none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 100%;
  padding: 0;
  padding-bottom: 0;
  margin-bottom: 0.625rem;
`

const QuantityControlWrapper = styled.div`
	max-height: 3.125rem;
	min-height: 3.125rem;
	height: 3.125rem;
	align-self: flex-end;
	padding: 0;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-direction: row;

	> button {
		border: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'} 0.125rem solid;
		border-radius: 50%;
		flex: 1;
		color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333' };
		font-size: 1rem;
		min-width: 1.875rem;
		min-height: 1.875rem;
		max-width: 1.875rem;
		max-height: 1.875rem;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    > span {
      display: flex;
      justify-content: center;
      align-items: center;
    }
	}

	> span {
		flex: 1;
		margin: 0 0.625rem;
		color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
		font-size: 1.125rem;
		font-weight: bold;
    min-height: 1.875rem;
    max-height: 1.875rem;
    margin: 0 0.625rem;
    display: flex;
    align-items: center;
    justify-content: center;
	}
`

const Span = styled.span``

const H3 = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 1rem;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#999'};
`

const Div = styled.div``

const Paragraph = styled.p`
	color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#000'} 0.063rem solid;
	padding: 0;
	margin: 0;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	flex: 1;
	min-width: 100%;
`

const Button = styled.button`
	background: none;
	padding: 0;
	margin: 0;
`

const AdditionalsListItemWrapper = styled.li`
	flex: 1;
	padding: 0;
	min-width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	margin-bottom: 1.25rem;

  &.checkbox {
    > div {
      &.actions {
        max-width: 1.875rem;
      }
    }
  }

  > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    &.actions {
      align-items: flex-start;
			flex: 1;
      max-width: 5.625rem;

      > span {
        display: flex;
      }
    }

    > p {
      &.name {
        font-size: 1rem;
        color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000'};
        font-weight: bold;
      }

      &.description {
        color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000'};
        margin-bottom: 0.375rem;
        font-size: 0.75rem;
      }

      &.value {
        color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.greyMedium) || '#000'};
        font-size: 1rem;
      }
    }
  }
`

const QuantityControl = ({
	data,
	quantity,
	id,
	handleQuantity
}) => {
	return <QuantityControlWrapper>
		{ quantity ? <>
			<Button onClick={() => {
				handleQuantity({
					...data,
					id,
					quantity: -1
				})
			}}>
				<CustomIcon name={ quantity === 1 ? 'Trash': 'Minus' } width='12px' />
			</Button>
			<Span>{ quantity }</Span>
			<Button onClick={ () => {
				if (quantity === 999) {
					return
				}

				handleQuantity({
					...data,
					id,
					quantity: 1
				})
			} }>
				<CustomIcon name='Plus' width='12px' />
			</Button>
		</> : <>
			<Button onClick={ () => {
				if (quantity === 999) {
					return
				}

				handleQuantity({
					...data,
					id,
					quantity: 1
				})
			} }>
				<CustomIcon name='Plus' width='12px' />
			</Button>
		</> }
	</QuantityControlWrapper>
}

export function Additionals(props) {
	const {
		data,
		handleQuantity,
		handleChange,
		selectedProduct,
		cart
	} = props

	const {
		selectedAdditionals
	} = selectedProduct

	const {
		products
	} = cart
	
	const cartSelectedProductArr = products && products.length && selectedProduct ? (products.filter(filteredItem => filteredItem.id === selectedProduct.id)) : []
	const cartSelectedProduct = (cartSelectedProductArr.length && cartSelectedProductArr[0]) || {}

	const [update, setUpdate] = useState(0)
	
	const {
		selectedAdditionals: cartSelectedAdditionals
	} = cartSelectedProduct

	return <AdditionalsWrapper>
		{ data && data.map((dataMappedItem, index) => {
			const key = `additionals-${ index }-${ (new Date()).getTime() }`

			const {
				items: additionalsItems,
				checkbox,
				name,
        minimum,
        maximum,
				groupId,
				calcType
			} = dataMappedItem

      const selectedGroup = (selectedAdditionals && (selectedAdditionals.filter(filteredItem => filteredItem.groupId === groupId && filteredItem.calcType === calcType))[0]) || null
      
      const selectedGroupItems = selectedGroup && selectedGroup.items
      const selectedGroupItemsQuantity = selectedGroupItems && selectedGroupItems.reduce((acc, reducedItem) => {
        return acc + reducedItem.quantity
      }, 0)

      const maximumQuantity = (selectedGroup && selectedGroup.maximum) || maximum

			return <AdditionalWrapper className={`${ (selectedGroup && ((selectedGroup.items && !selectedGroup.items.length) || (!selectedGroup.items))) ? 'required' : '' } ${ (selectedGroupItemsQuantity >= maximumQuantity) ? 'maximum' : '' }`} id={`additionals-group-${ groupId }-${ calcType }`} key={ key }>
				<AdditionalHeader>
					<H3>{ name }</H3>
					{ minimum >= 1 ? <Span>Obrigatório</Span> : null }
				</AdditionalHeader>
				<AdditionalList>
					{ additionalsItems && additionalsItems.map((additionalsItemsMappedItem, index) => {
						const key = `additionals-list-item-${ index }-${ (new Date()).getTime() }`

						const {
							value,
							description,
							name,
							code,
							calcType,
							groupId
						} = additionalsItemsMappedItem

						const selectedGroup = (selectedAdditionals && (selectedAdditionals.filter(filteredItem => filteredItem.groupId === groupId && filteredItem.calcType === calcType))[0]) || null
						const hasAdded = (selectedGroup && selectedGroup.items && selectedGroup.items.length && (selectedGroup.items.filter(filteredItem => filteredItem.code === code))) || false
						const hasChecked = hasAdded && hasAdded.length

						const cartSelectedGroup = (cartSelectedAdditionals && (cartSelectedAdditionals.filter(filteredItem => filteredItem.groupId === groupId && filteredItem.calcType === calcType))[0]) || null
						const hasCartAdded = (cartSelectedGroup && cartSelectedGroup.items && cartSelectedGroup.items.length && (cartSelectedGroup.items.filter(filteredItem => filteredItem.code === code))) || false
						const hasCartChecked = hasCartAdded && hasCartAdded.length

						const checked = !update ? hasChecked || hasCartChecked : hasChecked
						const checkedData = !update ? hasAdded || hasCartAdded : hasAdded

						return <AdditionalsListItemWrapper key={ key } className={`${ checkbox ? 'checkbox' : '' } ${ checked ? 'checked' : '' }`}>
							<Div>
								<Paragraph className='name'>{ name }</Paragraph>
								<Paragraph className='description'>{ description }</Paragraph>
								{ value && value > 0 ? <Paragraph className='value'>{ `+ R$ ${ value.toFixed(2).replace('.', ',') }` }</Paragraph> : null }
							</Div>

							<Div className='actions'>
								{ !checkbox ? <QuantityControl
										data={ additionalsItemsMappedItem }
										id={ code }
										quantity={ checkedData && checkedData[0] && checkedData[0].quantity }
										handleQuantity={ handleQuantity }
									/> : <CustomCheckbox
                  							id={ `product-additionals-item-${ groupId }-${ calcType }-${ code }` }
											checked={ checked }
											onChange={ () => {
												setUpdate(true)
												handleChange(additionalsItemsMappedItem, checked)
											} }
										/> }
							</Div>
						</AdditionalsListItemWrapper>
					})}
				</AdditionalList>
			</AdditionalWrapper>
		}) }
	</AdditionalsWrapper>
}
