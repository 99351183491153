export default ({
  menuRepository,
  maps,
  auth
}) => {
  return async (payload) => {
    const {
      onSuccess,
      onError,
			onEmpty,
      data
    } = payload

    try {
      const search = await menuRepository.fetchSearch({
        data,
        maps,
        auth: auth()
      })

      if (search.categories && search.categories.length) {
				onSuccess({search})
			} else {
				onEmpty()
			}
    } catch(e) {
      console.log(e)

      onError()
    }
  }
}
