import styled from 'styled-components'

export const Wrapper = styled.nav`
  display: flex;
  flex-direction: column;
  background-color: #fff;

  @media (min-width: 60rem) {
    padding: 0 0 5rem 0;
    border-bottom: 1px solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'};
    max-width: 22.25rem;
    width: 100%;
    position: fixed;
    
    overflow-y: scroll;
    max-height: 98%;
  
    ::-webkit-scrollbar {
      width: 6px;
    }
    
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'}; 
        border-radius: 10px;
    }
    
    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#000'}; 
        -webkit-box-shadow: inset 0 0 6px ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'}; 
    }
  }
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'};
  padding: 2.5rem 1.5rem;
  max-height: 8.5rem;
  overflow: hidden;    

  img, svg {
    margin: 0 1.5rem 0 0;
    max-width: 3.5rem;
    width: 100%;
  }

  span {
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
    margin: 0;
    overflow: hidden;
    max-height: 6rem;
  }

  @media (min-width: 60rem) {
    max-height: 7rem;

    span {
      max-height: 4rem;
    }
  }
`

export const NavListItem = styled.li`
  display: flex;
  align-items: center;
  height: 3rem;
  cursor: pointer;
  padding: 1rem;

  &:hover {
    background-color: #f7f7f7;
  }

  @media (min-width: 60rem) {
    height: 5rem;
  }
`

export const NavListItemLink = styled.a`
  display: flex;
  flex: 1;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  min-height: 100%;
  min-width: 100%;
  width: 100%;
`

export const NavListItemImageContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 1.5rem 0 0;
  width: 2.15rem;

  svg {
    path {
      fill: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
    }
  }

`

export const NavListItemDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  span {
    margin: 0;
    font-family: Nunito;
    font-weight: 700;
    font-size: 1rem;
    color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
  }

  p {
    margin: 0;
    font-size: .875rem;
    color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#000'};
  }
`

export const NavList = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
  list-style: inside none;
  margin: 0;

  ${NavListItem}:not(:first-of-type) {
    margin: 0;    
  }
`

export const MobileHeaderWrapper = styled.header`
  width: 100%;

  > button {
    &.close {
      background: none;
      padding: 0;
      margin: 0;
      position: absolute;
      right: 1.25rem;
      top: 1.25rem;
      font-size: 1.25rem;
      font-weight: 100;
    }
  }

  @media (min-width: 50rem) {
    display: none;
  }
`

export const CloseButton = styled.button`
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  background: none;
  border: 0 none;
  padding: 0;
  margin: 0;
`
