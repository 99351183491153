import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 7rem;
  min-width: 100%;
  max-width: 100%;
  border: .1rem solid ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#000' };
  border-radius: .8rem;
  padding: .8rem;
  background-color: #fff;
  box-sizing: border-box;
  cursor: pointer;

  ${props => props.selected ? `
      border: .1rem solid ${ (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
  ` : ''}

  ${props => props.inProgress ? `
    border: .1rem solid ${ (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
  ` : ''}

  &:hover {
    background-color: #f7f7f7;
  }

  @media (min-width: 60rem) {
    border-radius: 1rem;
    height: 8rem;
    padding: .75rem 1rem;
    ${props => props.selected ? `
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, .25));
      border: .2rem solid ${ (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
    ` : `
      border: .1rem solid ${ (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#000' };
    `
    }
  }

  @media (max-width: 60rem) {
    ${props => props.selected ? `
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, .25));
      border: .2rem solid ${ (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
    ` : `
      border: .1rem solid ${ (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#000' };
    `
    }
  }

  * {
    box-sizing: border-box;
    pointer-events: none;
  }

  label {
    cursor: pointer;
  }
`

export const CardSide = styled.div`
  flex: 0 1 3.5rem;
  padding: 0 .8rem 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  svg {
    width: inherit;
    height: inherit;
  }

  @media (min-width: 60rem) {
    padding: 0 1rem 0 0;
    flex: 0 1 6rem;
  }
`

export const StoreLogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: .063px solid ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#000' };
  border-radius: 50%;
  width: 4.5rem;
  height: 4.5rem;
  overflow: hidden;

  > img {
    height: auto;
    max-height: unset;
    max-width: 100%;
    min-height: auto;
    min-width: 100%;
    width: 100%;
  }

  @media (min-width: 60rem) {
    width: 5rem;
    height: 5rem;
  }
`

export const CardContent = styled.div`
  flex: 1 1 70%;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;

  @media (min-width: 60rem) {
    height: 100%;
  }
`

export const OrderDate = styled.h3`
  margin: 0 0 .5rem 0;
  width: 100%;
  padding: 0;
  font-weight: 700;
  font-size: .7rem;
  line-height: 1rem;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  border-bottom: .063px solid ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#000' };
  display: flex;
  align-items: flex-end;

  @media (min-width: 60rem) {
    font-size: 1rem;
    line-height: 1.375rem;
    margin: 0 0 .875rem 0;
  }
`

export const OrderItems = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0 0 .3rem 0;

  @media (min-width: 60rem) {
    margin: 0 0 .875rem 0;
  }
`

export const OrderItemsAmount = styled.div`
  border: .1rem solid ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#000' };
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  font-weight: bold;
  font-size: .9rem;
  height: 1.7rem;
  width: 1.7rem;
  border-radius: 25%;
  margin: 0 .2rem 0 0;

  @media (min-width: 60rem) {
    border: .2rem solid ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#000' };
    font-size: .875rem;
    height: 1.625rem;
    width: 1.625rem;
    margin: 0 .5rem 0 0;
  }
`

export const OrderItemsLabel = styled.label`
  font-weight: 600;
  font-size: .6rem;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-weight: bold;
    font-size: .9rem;
    color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
    padding-left: .5rem;
  }

  @media (min-width: 60rem) {
    font-weight: 600;
    font-size: .875rem;

    span {
      font-size: 1rem;
      padding-left: 1rem;
    }
  }
`

export const OrderItemsAddress = styled.label`
  width: 100%;
  font-weight: bold;
  font-size: .7rem;
  line-height: 1rem;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-self: flex-end;
  margin-top: .5rem;

  @media (min-width: 60rem) {
    font-size: .875rem;
    line-height: 1.25rem;
  }
`
