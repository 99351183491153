import { actionTypes } from '.'
import { loading, user, userAddress } from '..'

export const getSignUp = () => (dispatch, getState) => {
  const signUp = getState().signUp.signUp

  return signUp
}

export const setSignUp = (signUp) => async (dispatch) => {
  dispatch({
    type: actionTypes.SET_SIGN_UP,
    payload: signUp
  })
}

export const setMethod = (method) => async (dispatch) => {
  dispatch({
    type: actionTypes.SET_METHOD,
    payload: method
  })
}

export const postSignUp = (callback) => (dispatch, getState, container) => {
  const callbackError = callback && callback[0] && typeof callback[0] === 'function' ? callback[0] : () => {}
  const callbackSuccess = callback && callback[0] && typeof callback[1] === 'function' ? callback[1] : () => {}

  try {
    const stateSignUp = getState().signUp.signUp || {}

    const data = {...stateSignUp}
    const actions = {
      setLoading: loading.setLoading
    }

    return container.signUp({
      onSuccess: ({ signUp }) => {
        if (!signUp.success) {
          callbackError && callbackError()

					dispatch(setSignUp({
						...stateSignUp,
						...signUp,
						success: false
					}))

          return
        }

        if (signUp.success) {
          callbackSuccess && callbackSuccess()

          const {
            cpf,
						name
          } = signUp

          dispatch(setSignUp({
						...stateSignUp,
						...signUp,
						success: true,
						confirmationType: 'sms',
						resend: false
					}))
    
          dispatch(user.setUserCpf(cpf))
					dispatch(user.setUserName(name))

          return
        }
      },
      onError: () => {
        callbackError && callbackError()
      },
      onEmpty: () => {
        callbackError && callbackError()
      },
      dispatch,
      data,
      actions
   })
  } catch (e) {
    console.log(e)

    callbackError && callbackError()
  }
}

export const postSignUpPhone = (callback) => (dispatch, getState, container) => {
  const callbackError = callback && callback[0] && typeof callback[0] === 'function' ? callback[0] : () => {}
  const callbackSuccess = callback && callback[0] && typeof callback[1] === 'function' ? callback[1] : () => {}

	const stateSignUp = getState().signUp.signUp || {}
	const login = getState().main.login || {}

	const originData = stateSignUp.cpf ? stateSignUp : login
	const phone = originData.phone || stateSignUp.phone;
	const ddi = originData.ddi || stateSignUp.ddi;

	Object.assign(originData, {
		fullPhone: `+${ddi}${phone && phone.replace(/\(|\)|-| /g, '')}`,
	})

	const data = {...originData}

  try {
    return container.signUpPhone({
      onSuccess: ({ signUp }) => {
        if (!signUp.success) {
          callbackError && callbackError(signUp.message)
          return
        }

        if (signUp.success) {
          callbackSuccess && callbackSuccess()

          dispatch(setSignUp({
						...originData,
						phoneValidation: true
					}))
        }
      },
      onError: () => {
        callbackError && callbackError()
      },
      onEmpty: () => {
        callbackError && callbackError()
      },
      dispatch,
			data
   })
  } catch (e) {
    console.log(e)

    callbackError && callbackError()
  }
}

export const postSignUpPhoneCode = (callback) => (dispatch, getState, container) => {
	const callbackErrorFilter = callback && callback.filter(filteredItem => filteredItem.name === 'code' && filteredItem.type === 'error')
	const callbackError =  callbackErrorFilter && callbackErrorFilter[0] && callbackErrorFilter[0].callback
	const callbackSuccessFilter = callback && callback.filter(filteredItem => filteredItem.name === 'code' && filteredItem.type === 'success')
	const callbackSuccess =  callbackSuccessFilter && callbackSuccessFilter[0] && callbackSuccessFilter[0].callback

  const modalityId = getState().main.modality && getState().main.modality.id
	const userAddressId = getState().address.address && getState().address.address.id
	const accessToken = getState().user.accessToken
	
	const stateSignUp = getState().signUp.signUp || {}
	const stateAddress = getState().address.address || {}
	const login = getState().main.login || {}

	const originData = stateSignUp.cpf ? stateSignUp : login

	const data = {...originData, code: login.code}

  try {
    return container.signUpPhoneCode({
      onSuccess: async ({ signUp }) => {
        if (!signUp.success) {
          callbackError && callbackError(signUp.message)
          return
        }

        if (signUp.success) {
          callbackSuccess && callbackSuccess()

          if (modalityId === 4 && !userAddressId && stateAddress && stateAddress.main) {
						dispatch(user.setAccessToken(accessToken))
			
						await dispatch(userAddress.add(callback))
					}

					dispatch(setSignUp({
						...stateSignUp,
						...signUp,
						codeValidation: true
					}))
        }
      },
      onError: () => {
        callbackError && callbackError()
      },
      onEmpty: () => {
        callbackError && callbackError()
      },
      dispatch,
			data
   })
  } catch (e) {
    console.log(e)

    callbackError && callbackError()
  }
}

export const postSignUpFacebook = (callback) => (dispatch, getState, container) => {
  const callbackError = callback && callback[0] && typeof callback[0] === 'function' ? callback[0] : () => {}
  const callbackSuccess = callback && callback[0] && typeof callback[1] === 'function' ? callback[1] : () => {}

  try {
    const stateSignUp = getState().signUp && getState().signUp.signUp

    const data = {signUp: stateSignUp}
    const actions = {
      setLoading: loading.setLoading
    }

    return container.signUpFacebook({
      onSuccess: ({ signUp }) => {
        if (!signUp.success) {
          callbackError && callbackError()

          return
        }

        if (signUp.success) {
          callbackSuccess && callbackSuccess()

          const {
            CPF: cpf,
						name
          } = signUp

          dispatch(setSignUp({
						...stateSignUp,
						...signUp,
						ddi: 55,
						success: true,
						confirmationType: 'sms',
						resend: false
					}))
    
          dispatch(user.setUserCpf(cpf))
					dispatch(user.setUserName(name))

          return
        }
      },
      onError: () => {
        callbackError && callbackError()
      },
      onEmpty: () => {
        callbackError && callbackError()
      },
      dispatch,
      data,
      actions
   })
  } catch (e) {
    console.log(e)

    callbackError && callbackError()
  }
}

export const postSignUpGoogle = (callback) => (dispatch, getState, container) => {
  const callbackError = callback && callback[0] && typeof callback[0] === 'function' ? callback[0] : () => {}
  const callbackSuccess = callback && callback[0] && typeof callback[1] === 'function' ? callback[1] : () => {}

  try {
    const stateSignUp = getState().signUp && getState().signUp.signUp

    const data = {signUp: stateSignUp}
    const actions = {
      setLoading: loading.setLoading
    }

    return container.signUpGoogle({
      onSuccess: ({ signUp }) => {
				if (!signUp.success) {
          callbackError && callbackError()

          return
        }

        if (signUp.success) {
          callbackSuccess && callbackSuccess()

          const {
            CPF: cpf,
						name
          } = signUp

          dispatch(setSignUp({
						...stateSignUp,
						...signUp,
						ddi: 55,
						success: true,
						confirmationType: 'sms',
						resend: false
					}))
    
          dispatch(user.setUserCpf(cpf))
					dispatch(user.setUserName(name))

          return
        }
      },
      onError: () => {
        callbackError && callbackError()
      },
      onEmpty: () => {
        callbackError && callbackError()
      },
      dispatch,
      data,
      actions
   })
  } catch (e) {
    console.log(e)

    callbackError && callbackError()
  }
}
