import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
	padding-top: 6.5rem;
	box-sizing: border-box;
	max-height: 100%;
	min-height: 100%;
`

export const CartContainerHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .5rem 1rem;
  min-height: 5.625rem;
  max-height: 5.625rem;
  border-bottom: 1px solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'};
  background-color: #F7F7F7;
  position: fixed;
	left: calc(100% - 20rem);
	top: 0;
	z-index: 2;
	min-width: 20rem;
	max-width: 20rem;

  @media (min-width: 60rem) {
    border-left: 1px solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'};
    padding: .4rem;
  }

  &:before {
    content: '';
    border-bottom: 16px solid transparent;
    border-right: 16px solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'};
    border-top: 16px solid transparent;
    position: absolute;
    left: -16px;
    top: 50%;
    transform: translateY(-50%);
  }

  &:after {
    content: '';
    border-bottom: 15px solid transparent;
    border-right: 15px solid #f7f7f7;
    border-top: 15px solid transparent;
    position: absolute;
    left: -15px;
    top: 50%;
    transform: translateY(-50%);
  }
`

export const CartStoreName = styled.span``

export const CartStoreDescription = styled.span`
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000'};
  font-weight: 600;
  font-size: .875rem;
  line-height: 1.15rem;
  overflow: hidden;
  max-height: 5rem;
  text-overflow: ellipsis;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media (min-width: 50rem) {
    margin: 0;
  }
  

  ${CartStoreName} {
    font-size: 1.25rem;
    line-height: 1.5rem;
    color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000'};
  }
`

export const CartStoreIconContainer = styled.div`
  width: 4rem;
  height: 4rem;
  background-color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  img, svg {
    width: 100%;
    height: auto;
    border-radius: 50%;
  }
`

export const CartContainerContent = styled.div`
  background-color: #fff;
  padding: .8rem 1rem;
	max-height: calc(100% - 6.5rem);
	min-height: calc(100% - 6.5rem);
`

export const CartContainerIconArrow = styled.div`
  padding: 0 15px;

	> svg {
		color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};

		* {
			fill: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
		}
	}
`
