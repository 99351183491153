import { Button } from './Button'
import { Input } from './Input'
import { Paragraph } from './Paragraph'
import { Span } from './Span'

export * from './Button'
export * from './CustomIcon'
export * from './CustomImage'
export * from './CustomInput'
export * from './CustomCheckbox'
export * from './Div'
export * from './H2'
export * from './H4'
export * from './CustomLoading'
export * from './Loading'
export * from './Image'
export * from './Input'
export * from './Span'
export * from './Strong'
export * from './Stepper'
export * from './Header'
export * from './Notification'
export * from './CustomModalNotification'

export * from './styles'

const DefaultParagraph = Paragraph
const DefaultButton = Button
const DefaultInput = Input
const DefaultSpan = Span

export {
  DefaultParagraph,
  DefaultButton,
  DefaultInput,
  DefaultSpan
}
