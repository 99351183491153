import { useDispatch, useStore } from "react-redux";

import { setShowSignUp } from "../../redux/actions/main";
import history from "../../history";

export function usePhoneValidation() {
	const dispatch = useDispatch();
	const mainState = useStore().getState().main;
	const isValidPhone  = mainState?.login?.subscriptionStatus === 1;
	
	const showPhoneValidation = (isMobile = false) => {	
			isMobile ? history.push(`/sign-up`) : dispatch(setShowSignUp(true));
	};

	return {
		isValidPhone,
		showPhoneValidation,
	};
}
