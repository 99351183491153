import React, {
  useContext,
  useEffect,
  useState
} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'

import {
	useStoreSettings,
	useLayout,
	useSidebar
} from '../hooks'

import { ReactComponent as IconContactUs } from '../assets/icons/icon_contact_us.svg'
import { ReactComponent as IconClose } from '../assets/icons/icon_close.svg'
import { ReactComponent as IconCheck } from '../assets/icons/icon_check.svg'

import {
  setModality,
  handleGTM
} from '../../redux/actions/main'

import {
  useDeviceLayout
} from '../hooks'

import {
  contactUs
} from '../../state'

import {
  pipe,
  GetContent,
  caculateProductsQuantity
} from '../../domain/helpers'

import {
  DefaultImage as Image,
  CustomIcon,
  InputRounded,
  CustomLoading
} from '../components'

import {
  Cart,
  CartContainer
} from '../cart'

import {
  MainHeader
} from '../main-header'

import {
  PageContainer,
  PageContainerHeader,
  PageContainerMain,
  PageContainerSide
} from '../page-container'

import {
  Navigation,
} from '..'

import {
  H1,
  Wrapper,
  BackButton,
  Form,
  SendButton,
  PageSubtitle,
  PageParagraph,
  Row,
  Column,
  Message
} from './styles'

const requiredField = {
  name: 'message',
  message: 'A mensagem é obrigatória'
}

export const Context = React.createContext({})

export function ContactUsPage(props) {
	const {
    accessToken,
    addressDelivery,
    cartProducts,
    sendContactUs,
    contactUs,
    loading,
    setContactUs,
    theme,
    address,
    userName,
    storeName,
    storeImg,
    modalityId
  } = props

  const storeImage = theme.headerImage
  const storeLogo = theme.logoImage

	const {
		url,
		title,
		description
	} = useStoreSettings()

  const context = useContext(Context)

  const isMobile = useDeviceLayout({
    isMobile: true
  })

	const {
		layoutMode,
		layoutModeEnum,
		handleLayoutMode
	} = useLayout()

	const {
		sidebarContent,
		sidebarOpened,
		sidebarContentEnum,
		handleSidebarContent,
		handleSidebarOpened
	} = useSidebar()

  const { sections } = useContext(Context)
  const [visibleSignIn, setVisibleSignIn] = useState(false)
  const [viewCards, setViewCards] = useState(false)

  const contactUsSection = sections && sections.filter(item => item.id === 'send')[0]

  const registeredContactUssSectionTitle = contactUsSection.title
  const registeredContactUssSectionText = contactUsSection.text

  const [error, setError] = useState(undefined)
  const [success, setSuccess] = useState(false)
  const [pending, setPending] = useState(false)

  useEffect(() => {
    if (contactUs && contactUs.notifications && contactUs.notifications.length) {
      setContactUs({
        ...contactUs.notifications[0]
      })
    }
  }, [
    contactUs,
    setContactUs,
  ])

  async function handleModality(id) {
    setModality({ id })
  }

  function handleContactUs() {

  }

  async function handleSubmit() {
    setError(undefined)
    setSuccess(false)

    if (!contactUs.message) {
      setError(requiredField.message)
      return
    }

    setPending(true)
    await sendContactUs([() => {

    }, () => {
      setContactUs({ message: '' })
      setSuccess(true)
    }])

    setPending(false)
  }

  return (
    <Wrapper id={'contact-us'}>
			<Helmet>
				<title>{`${title} - Fale conosco`}</title>
				<meta name='description' content={description} />
				<link rel='canonical' href={`${url}/contact-us`} />
			</Helmet>

      <PageContainer
				isSideOpen={sidebarOpened}
				mode={layoutMode}
			>
        <PageContainerHeader isMobile={isMobile}>
          <MainHeader
            mode='full'
            theme={theme}
            storeLogo={storeLogo}
            name={storeName}
            storeImg={storeImg}
            userName={userName}
            accessToken={accessToken}
            setVisibleSignIn={setVisibleSignIn}
            visibleSignIn={visibleSignIn}
            handleModality={handleModality}
            modality={modalityId}
            addressDelivery={address ? address : addressDelivery}
            cartItemsQuantity={caculateProductsQuantity(cartProducts)}
            homeOnClick={() => {
							handleLayoutMode(layoutModeEnum.default)
							handleSidebarContent(sidebarContentEnum.default)
							handleSidebarOpened(false)
							setViewCards(false)
						}}
						userClicked={() => {
							if (sidebarOpened && sidebarContent === sidebarContentEnum.nav) {
								handleLayoutMode(layoutModeEnum.default)
								handleSidebarContent(sidebarContentEnum.default)
								handleSidebarOpened(false)
							} else {
								handleLayoutMode(layoutModeEnum.headerFullContent)
								handleSidebarContent(sidebarContentEnum.nav)
								handleSidebarOpened(true)
							}
						}}
						cartClicked={() => {
							if (sidebarOpened && sidebarContent === sidebarContentEnum.cart) {
								handleLayoutMode(layoutModeEnum.default)
								handleSidebarContent(sidebarContentEnum.default)
								handleSidebarOpened(false)
							} else {
								handleLayoutMode(layoutModeEnum.sideFullContent)
								handleSidebarContent(sidebarContentEnum.cart)
								handleSidebarOpened(true)
							}
						}}
						isSidebarOpen={sidebarOpened}
          />
        </PageContainerHeader>
        <PageContainerMain opened={sidebarOpened}>
          <Image src={storeImage} alt="" />

          <H1>{context.title}</H1>

          <BackButton className='back-button' onClick={() => {
            window.history.back()
          }}>
            <CustomIcon name='AngleLeft' />
          </BackButton>

          {!error && success &&
            <Message className="success">
              <span>Mensagem enviada com sucesso!</span>
              <IconCheck />
            </Message>
          }

          {error && <Message className="error">
            <span>{error}</span>
            <IconClose />
          </Message>
          }

          <Row>
            <Column>
              <PageSubtitle>{registeredContactUssSectionTitle}</PageSubtitle>
              <PageParagraph>{registeredContactUssSectionText}</PageParagraph>
              <Form>
                <InputRounded
                  as='textarea'
                  placeholder='Escreva aqui sua mensagem'
                  name='message'
                  value={contactUs && contactUs.message}
                  onChange={(event) => {
                    setContactUs({ message: event.target.value })
                  }}
                />

                <SendButton
                  onClick={() => handleSubmit()}
                  type='button'
                  disabled={pending}
                >{loading.includes('send-contact-us') ?
                  <CustomLoading
                    color={'#fff'}
                    type={'spin'}
                    id='default-loading'
                    height={30}
                    width={30} /> :
                  'Enviar'}</SendButton>
              </Form>
            </Column>
            <Column className='center'>
              <IconContactUs id='contactUsIcon' />
            </Column>
          </Row>
        </PageContainerMain>
        <PageContainerSide opened={sidebarOpened}>
					{!isMobile && layoutMode === layoutModeEnum.sideFullContent &&
            <CartContainer logo={storeLogo}>
              <Cart
                mode='simple'
                screenMobile={isMobile}
                setViewCards={setViewCards}
                viewCards={viewCards}
                handleFinish={() => { }}
                handleClose={() => { }}
              />
            </CartContainer>}

						{layoutMode === layoutModeEnum.headerFullContent && <Navigation
              contactUs={() => handleContactUs()}
              userName={userName}
              currentRoute='contact-us'
            />}
        </PageContainerSide>
      </PageContainer>
    </Wrapper>
  )
}

ContactUsPage.propTypes = {
	accessToken: PropTypes.string,
	addressDelivery: PropTypes.shape({}),
	cartProducts: PropTypes.arrayOf(PropTypes.shape({})),
	sendContactUs: PropTypes.func,
	contactUs: PropTypes.shape({
		notifications: PropTypes.arrayOf(PropTypes.shape({})),
		message: PropTypes.string
	}),
	loading: PropTypes.arrayOf(PropTypes.string),
	setContactUs: PropTypes.func,
	theme: PropTypes.shape({
		headerImage: PropTypes.string,
		logoImage: PropTypes.string
	}),
	address: PropTypes.shape({}),
	userName: PropTypes.string,
	storeName: PropTypes.string,
	storeImg: PropTypes.string,
	modalityId: PropTypes.number
}

const mapStateToProps = (state) => {
  return {
    accessToken: state.user.accessToken,
    addressDelivery: state.address.addressByCEP || '',
    cartProducts: (state.main.cart && state.main.cart.products) || [],
    loading: state.loading.loading || [],
    coupon: state.main.coupon || {},
    contactUs: state.main.contactUs || { message: '', notifications: [] },
    address: state.address.address || {},
    userAddresses: state.userAddress.userAddresses || [],
    userHistory: state.main.userHistory || {},
    userName: state.user.name || '',
    storeImg: (state.store.store && state.store.store.logo) || '',
    modalityId: state.main.modality && state.main.modality.id,
    storeName: state.store.store && state.store.store.name
  }
}

const GetConnection = connect(mapStateToProps, {
  sendContactUs: contactUs.sendContactUs,
  setContactUs: contactUs.setContactUs,

  setModality,
  handleGTM
})

export const ContactUs = React.memo(pipe(
  GetConnection,
  GetContent({ context: Context, id: 'contact-us' })
)(ContactUsPage))
