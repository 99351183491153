import React from 'react'
import { connect } from 'react-redux'

import history from '../../history'

import {
  pipe,
  GetContent
} from '../../domain/helpers'

import {
  logout,
  setUserHistory
} from '../../redux/actions/main'

import { ReactComponent as IconCreditCard } from '../assets/icons/icon_single_credit_card.svg'
import { ReactComponent as IconOrder } from '../assets/icons/icon_order.svg'
import { ReactComponent as IconUser } from '../assets/icons/icon_rounded_user.svg'
import { ReactComponent as IconCoupon } from '../assets/icons/icon_coupon.svg'
import { ReactComponent as IconContactUs } from '../assets/icons/icon_contact_us.svg'
import { ReactComponent as IconLogout } from '../assets/icons/icon_logout.svg'

import {
  Wrapper,
  Header,
  NavListItem,
  NavListItemLink,
  NavListItemDescription,
  NavListItemImageContainer,
  NavList,
  MobileHeaderWrapper,
  CloseButton
} from './styles'
import { CustomIcon } from '../components'
import { useDeviceLayout } from '../hooks'

const Context = React.createContext({})

export function NavigationComponent(props) {
  const {
    userClicked,
    userName,
    currentRoute,
    logout,
    setUserHistory,
    setVisibleNavigation,
    URLParameters
  } = props

  const isMobile = useDeviceLayout({
    isMobile: true
  })

  return (
    <Wrapper>
      <MobileHeaderWrapper>
        <CloseButton className='close' onClick={() => {
          setVisibleNavigation(false)
        }}>
          <CustomIcon name='Times' />
        </CloseButton>
      </MobileHeaderWrapper>
      <Header>
        <IconUser />
        <span>Olá, {userName ? userName : ''}</span>
      </Header>
      <NavList>
        <NavListItem onClick={() => {
          setUserHistory({
            back: `${currentRoute}`
          })

          history.push(`/coupons${URLParameters}`)
        }}>
          <NavListItemLink>
            <NavListItemImageContainer>
              <IconCoupon />
            </NavListItemImageContainer>
            <NavListItemDescription>
              <span>Cupons</span>
              <p>Cadastre e consulte seus cupons</p>
            </NavListItemDescription>
          </NavListItemLink>
        </NavListItem>
        <NavListItem onClick={() => {
          setUserHistory({
            back: currentRoute
          })

          history.push(`/placed-orders${URLParameters}`)
        }}>
          <NavListItemLink>
            <NavListItemImageContainer>
              <IconOrder />
            </NavListItemImageContainer>
            <NavListItemDescription>
              <span>Pedidos</span>
              <p>Acompanhe ou veja seu histórico</p>
            </NavListItemDescription>
          </NavListItemLink>
        </NavListItem>
        <NavListItem onClick={() => {
          setUserHistory({
            back: currentRoute
          })

          history.push(`/my-cards${URLParameters}`)
        }}>
          <NavListItemLink>
            <NavListItemImageContainer>
              <IconCreditCard />
            </NavListItemImageContainer>
            <NavListItemDescription>
              <span>Cartões</span>
              <p>Acesse sua carteira digital</p>
            </NavListItemDescription>
          </NavListItemLink>
        </NavListItem>
        <NavListItem>
          <NavListItemLink onClick={() => {
            setUserHistory({
              back: `${currentRoute}`
            })

            history.push(`/contact-us${URLParameters}`)
          }}>
            <NavListItemImageContainer>
              <IconContactUs />
            </NavListItemImageContainer>
            <NavListItemDescription>
              <span>Fale conosco</span>
              <p>Problemas ou dúvidas? Pode falar!</p>
            </NavListItemDescription>
          </NavListItemLink>
        </NavListItem>
        <NavListItem>
          <NavListItemLink onClick={() => {
            if (isMobile) {
              setVisibleNavigation(false)
              logout()

              return
            }

            if (logout && typeof logout === 'function') {
              logout()
            }

            if (userClicked && typeof userClicked === 'function') {
              userClicked()
            }
          }}>
            <NavListItemImageContainer>
              <IconLogout />
            </NavListItemImageContainer>
            <NavListItemDescription>
              <span>Sair</span>
            </NavListItemDescription>
          </NavListItemLink>
        </NavListItem>
      </NavList>
    </Wrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    accessToken: state.user.accessToken,
    addressDelivery: state.address.addressByCEP || '',
    cartProducts: (state.main.cart && state.main.cart.products) || [],
    loading: state.loading.loading || [],
    order: state.main.order || {},
    orderCpf: state.main.order && state.main.order.cpf,
    orderHistory: state.main.orderHistory || { orders: [] },
    storeLogo: state.store.store && state.store.store.logo,
    storeImage: state.store.store && state.store.store.images && state.store.store.images.headerBackgroundImage,
    userAddresses: state.userAddress.userAddresses || [],
    userHistory: state.main.userHistory || {},
    userName: state.user.name || '',
    addressNumber: (state.main.address && state.main.address.number) || null,
    URLParameters: (state.main.URLParameters) || ''
  }
}

const GetConnection = connect(mapStateToProps, {
  setUserHistory,
  logout
})

export const Navigation = React.memo(pipe(
  GetConnection,
  GetContent({ context: Context, id: 'navigation' })
)(NavigationComponent))
