import React from 'react'

import {
  ProgressBar,
  ProgressBarItem,
  OrderStatusTitle,
  Stepper,
  StepperItem
} from './styles'

export function OrderStatus({
  ...propStatus
}) {
  const {
    statusProgress,
    status0,
    status1,
    status2,
    status3,
    statusDate0,
    statusDate1,
    statusDate2,
    statusDate3
  } = propStatus

  return (
    <>
      <OrderStatusTitle>Status da Compra</OrderStatusTitle>
      <ProgressBar>
        <ProgressBarItem className={status0 ? 'active' : ''} />
        <ProgressBarItem className={status1 ? 'active' : ''} />
        <ProgressBarItem className={status2 ? 'active' : ''} />
        <ProgressBarItem className={status3 ? 'active' : ''} />
      </ProgressBar>

      <Stepper>
        <StepperItem className={status0 ? 'active' : ''} status={statusProgress === 1 ? true : false}>
          Recebido com sucesso
          {status0 && <span>{statusDate0}</span>}
        </StepperItem>
        <StepperItem className={status1 ? 'active' : ''} status={statusProgress === 2 ? true : false}>
          Em andamento
          {status1 && <span>{statusDate1}</span>}
        </StepperItem>
        <StepperItem className={status2 ? 'active' : ''} status={statusProgress === 3 ? true : false}>
          Saiu para entrega
          {status2 && <span>{statusDate2}</span>}
        </StepperItem>
        <StepperItem className={status3 ? 'active' : ''} status={statusProgress === 4 ? true : false}>
          Chegou! Bom apetite!
          {status3 && <span>{statusDate3}</span>}
        </StepperItem>
      </Stepper>
    </>
  )
}

OrderStatus.propTypes = {
	
}
