import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogContent } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ReactComponent as IconPhone } from '../../assets/icons/icon_phone.svg'
import { ReactComponent as IconTyping } from '../../assets/icons/icon_typing.svg'

import {
  CustomIcon,
  DefaultImage as Image
} from '../../components'

import {
  Wrapper,
  Header,
  StoreOrder,
  StoreOrderLogo,
  StoreOrderDetails,
  StoreOrderName,
  StoreOrderDate,
  StoreOrderModality,
  StoreOrderStatus,
  Help,
  HelpButton,
  HelpLabel,
  CustomOrderSummary,
  MobileHeader,
  H1,
  BackButton,
  DialogWrapper,
  CloseButton,
  H2,
  H3,
  Title,
  Button
} from './styles'
import { maskPhone } from '../../../domain/helpers'

const useStyles = makeStyles({
  paper: {
    margin: 0,
    position: 'absolute',
    bottom: '-10px'
  },
  paperWidthMd: {
    width: '100%',
  },
  paperWidthMdMobile: {
    width: '400px'
  }
});

export function Summary(props) {
  const {
    storeName,
    storeLogo,
    deliveryAddress,
    pickupAddress,
    date,
    modalityId,
    inProgress,
    phone,
    whatsapp,
    products,
    cpf,
    hasCpf,
    cardBrand,
    cardNumber,
    handleTrackOrder,
    screenMobile,
		payOnDelivery
  } = props
  const classes = useStyles();
  const [overlay, setOverlay] = useState(false)
  const maxWidth = 'md'

	const openWhattsApp = (whatsapp) => {
		window.open(`https://api.whatsapp.com/send?phone=${whatsapp}`);
	}

  return (
    <Wrapper>
      <MobileHeader>
        <H1>Meus Pedidos</H1>
        <BackButton className='back-button' onClick={() => {
          window.history.back()
        }}>
          <CustomIcon name='AngleLeft' />
        </BackButton>
      </MobileHeader>
      <Header>
        <StoreOrder>
          <StoreOrderLogo>
            <Image src={storeLogo} />
          </StoreOrderLogo>
          <StoreOrderDetails>
            <StoreOrderName>{storeName}</StoreOrderName>
            <StoreOrderDate>{date}</StoreOrderDate>
          </StoreOrderDetails>
        </StoreOrder>
        <StoreOrderModality>{modalityId === 4 ? 'Delivery' : 'Retirada'}</StoreOrderModality>
        {inProgress ? <StoreOrderStatus className="inprogress">Em andamento</StoreOrderStatus> : <StoreOrderStatus className="finish">Finalizado</StoreOrderStatus>}
      </Header>
      <Help>
        {(whatsapp || phone) && !screenMobile && <HelpLabel>Precisa de ajuda?</HelpLabel>}
        {whatsapp && !screenMobile ? <HelpButton onClick={() => openWhattsApp(whatsapp)}>
          Fale conosco
          <IconTyping />
        </HelpButton> : <></>}
        {phone && !screenMobile ? <HelpButton onClick={() => setOverlay(true)}>
          Ligar para loja
          <IconPhone />
        </HelpButton> : <></>}
      </Help>
      <CustomOrderSummary
        products={products}
        modalityId={modalityId}
        deliveryAddress={modalityId === 4 ? deliveryAddress : ''}
        storeAddress={modalityId === 2 ? pickupAddress : ''}
        cardBrand={cardBrand}
        cardNumber={cardNumber}
        cpf={cpf}
        hasCpf={hasCpf}
        buttonName='Acompanhar Pedido'
        handleTrackOrder={handleTrackOrder}
        whatsapp={whatsapp}
        phone={phone}
        setOverlay={setOverlay}
				payOnDelivery={payOnDelivery}
      />
      <Dialog
        open={overlay}
        onClose={() => setOverlay(false)}
        aria-labelledby={`phone-dialog-title`}
        aria-describedby={`phone-dialog-description`}
        maxWidth={maxWidth}
        classes={screenMobile ? { paper: classes.paper, paperWidthMd: classes.paperWidthMd } : { paperWidthMd: classes.paperWidthMdMobile }}
        className='phone-detail'
        PaperProps={{ style: { borderRadius: !screenMobile ? '16px' : '10px' } }}
      >
        <DialogContent>
          <DialogWrapper id='phone-information'>
            <CloseButton className='close-button' onClick={() => {
              setOverlay(false)
            }}>
              <CustomIcon name='Times' />
            </CloseButton>

            <Title>
              <IconPhone />
              <H2>Ligar para loja</H2>
            </Title>

            <H3>{maskPhone(phone)}</H3>

            <Button onClick={() => {
              setOverlay(false)
            }}>Entendi!</Button>
          </DialogWrapper>
        </DialogContent>
      </Dialog>
    </Wrapper>
  )
}

Summary.propTypes = {
  storeName: PropTypes.string,
  storeLogo: PropTypes.string,
  deliveryAddress: PropTypes.shape({}),
  pickupAddress: PropTypes.shape({}),
  date: PropTypes.string,
  modalityId: PropTypes.number,
  inProgress: PropTypes.bool,
  phone: PropTypes.string,
  whatsapp: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.shape({})),
  cpf: PropTypes.string,
  hasCpf: PropTypes.bool,
  cardBrand: PropTypes.string,
  cardNumber: PropTypes.string,
  handleTrackOrder: PropTypes.func
}
