import React from 'react'
import PropTypes from 'prop-types'

import { ReactComponent as IconLocation } from '../../assets/icons/icon_location.svg'
import { ReactComponent as IconExpandDown } from '../../assets/icons/icon_expand_down.svg'
import { ReactComponent as IconUser } from '../../assets/icons/icon_singin.svg'
import { ReactComponent as BasketIcon } from '../../assets/icons/icon_cesta.svg'
import { ReactComponent as HomeIcon } from '../../assets/icons/icon_home.svg'

import {
  DeliveryAddressContainer,
  DeliveryAddressLocationContainer,
  DeliveryAddressTitle,
  LocationAddress,
  StoreHeaderActionButton,
  StoreHeaderActionsContainer,
  Wrapper,
  CartItemsButtonContainer,
  StoreSelectContainer,
  StoreName,
  IconButtonCartsItemsAmount,
  LocationAddressFull,
  LocationAddressSmall
} from './styles'

import {
  useDeviceLayout,
  useSidebar
} from '../../hooks'

import history from '../../../history'
import { MULTI_STORE } from '../../../config'
import { caculateProductsQuantity } from '../../../domain/helpers'
import { sidebarContentEnum } from '../../../domain/sidebar'
import { Modality } from '../Modality'

export function Full(props) {
  const {
    address,
    cartClicked,
    userClicked,
    visibleSignIn,
    storeLogo,
    handleModality,
    homeOnClick,
    context,
    modalityId,
    storeAddress,
    storeName,
    storeImg,
    accessToken,
    homeDisabled,
    userCpf,
    opened,
    products,
    storeDelivery,
    storePickup,
    subscriptionStatus,
    URLParameters,
    userName,
    setOpenCart,
    setVisibleSignIn,
    setVisibleStoreList,
    setAddressPostalCodeRequest,
    setAddressRequest,
    setNoAddressesList,
    className,
    handleGTM
  } = props

  const {
    sidebarContent,
    sidebarOpened
  } = useSidebar()

  const isMobile = useDeviceLayout({
    isMobile: true
  })

  const { content } = context
  const deliverySection = content.filter(item => item.id === 'delivery-place')[0]
  const deliverySectionTitle = deliverySection.title
  const pickupSection = content.filter(item => item.id === 'pickup-place')[0]
  const pickupSectionTitle = pickupSection.title

  const selectedAddress = (address && address.main) || 'Insira seu endereço aqui'
  const selectedAddressNumber = address && address.number

  const displayAddress = modalityId === 4 ?
    (selectedAddress && selectedAddress.length > 30 && selectedAddress.length < 40 ?
      (selectedAddress.slice(0, -17) + '...') : selectedAddress && selectedAddress.length > 40 && selectedAddress.length < 50 ?
        (selectedAddress.slice(0, -30) + '...') : selectedAddress && selectedAddress.length > 50 ?
          (selectedAddress.slice(0, -35) + '...') : selectedAddress) + (selectedAddressNumber ? `, ` + selectedAddressNumber : '')
    : storeAddress && storeAddress.slice(0, -9) + '...'

  const fullDisplayAddress = modalityId === 4 ?
    selectedAddress + (selectedAddressNumber ? `, ` + selectedAddressNumber : '') :
    storeAddress

  const wrapperClasses = `main-header-content ${className}`

  return (
    <Wrapper className={wrapperClasses} isSidebarOpen={sidebarOpened && sidebarContent === sidebarContentEnum.cart}>
      {MULTI_STORE === 'true' && address && address.main && storeName ?
        <StoreSelectContainer onClick={() => setVisibleStoreList(true)}>
          <img src={storeImg} alt="logo" />
          <StoreName>{storeName}</StoreName>
          <IconExpandDown />
        </StoreSelectContainer> :
        <img src={storeLogo} alt="logo" className={'logo-image'} />}
      <DeliveryAddressContainer>
        <DeliveryAddressTitle>{modalityId === 4 ? deliverySectionTitle : pickupSectionTitle}</DeliveryAddressTitle>
        <DeliveryAddressLocationContainer onClick={() => {
          if (modalityId !== 4) {
            return
          }

          if (!accessToken) {
            setAddressPostalCodeRequest(true)
            setAddressRequest(false)
          } else {
            setAddressPostalCodeRequest(false)
            setAddressRequest(true)
            setNoAddressesList(false)
          }
        }}>
          <IconLocation />
          {fullDisplayAddress && fullDisplayAddress.length > 16 ? <>
            <LocationAddressSmall>{displayAddress}</LocationAddressSmall>
          </> : <>
            <LocationAddressFull>{fullDisplayAddress}</LocationAddressFull>
          </>}
          <LocationAddress>{displayAddress}</LocationAddress>
          <IconExpandDown />
        </DeliveryAddressLocationContainer>
      </DeliveryAddressContainer>
      {modalityId && <Modality
        modalityId={modalityId}
        storeDelivery={storeDelivery}
        opened={opened}
        storePickup={storePickup}
        handleModality={handleModality}
        handleGTM={handleGTM}
        content={content}
        storeName={storeName}
      />}
      <StoreHeaderActionsContainer>
        <StoreHeaderActionButton onClick={() => {
          if (!homeDisabled) {
            setOpenCart(false)
            if (sidebarOpened && sidebarContent !== sidebarContentEnum.cart) {
              cartClicked()
            }
            if (homeOnClick && typeof homeOnClick === 'function') {
              homeOnClick()
            }

            history.push(`/${URLParameters}`)
          }
        }}>
          <HomeIcon />
        </StoreHeaderActionButton>
        {userName && userCpf && accessToken && subscriptionStatus === 1 ? <StoreHeaderActionButton onClick={() => {
          setOpenCart(false)
          userClicked()
        }}>
          <IconUser />
        </StoreHeaderActionButton> :
          <StoreHeaderActionButton onClick={() => {
            if (sidebarOpened && sidebarContent !== sidebarContentEnum.cart) {
              cartClicked()
            }
            if (homeOnClick && typeof homeOnClick === 'function') {
              homeOnClick()
            }

            if (isMobile) {
              history.push(`/login${URLParameters}`)

              return
            }

            setOpenCart(false)

            if (accessToken) {
              userClicked()
            } else {
              visibleSignIn ? setVisibleSignIn(false) : setVisibleSignIn(true)
            }
          }
          }>
            <IconUser />
          </StoreHeaderActionButton>}
        <StoreHeaderActionButton
          onClick={() => {
            setOpenCart(opened ? false : true)
            cartClicked()
          }}
          opened={sidebarOpened}
        >
          <CartItemsButtonContainer>
            <IconButtonCartsItemsAmount>{caculateProductsQuantity(products)}</IconButtonCartsItemsAmount>
            <BasketIcon />
          </CartItemsButtonContainer>
        </StoreHeaderActionButton>
      </StoreHeaderActionsContainer>
    </Wrapper>
  )
}

Full.propTypes = {
  address: PropTypes.shape({
    main: PropTypes.string,
    number: PropTypes.string
  }),
  cartClicked: PropTypes.func,
  userClicked: PropTypes.func,
  visibleSignIn: PropTypes.bool,
  isSidebarOpen: PropTypes.bool,
  storeLogo: PropTypes.string,
  handleModality: PropTypes.func,
  homeOnClick: PropTypes.func,
  context: PropTypes.shape({
    content: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  modalityId: PropTypes.number,
  storeAddress: PropTypes.string,
  storeName: PropTypes.string,
  storeImg: PropTypes.string,
  accessToken: PropTypes.string,
  homeDisabled: PropTypes.bool,
  userCpf: PropTypes.string,
  opened: PropTypes.bool,
  products: PropTypes.arrayOf(PropTypes.shape({})),
  storeDelivery: PropTypes.bool,
  storePickup: PropTypes.bool,
  subscriptionStatus: PropTypes.number,
  URLParameters: PropTypes.string,
  userName: PropTypes.string,
  setOpenCart: PropTypes.func,
  setVisibleSignIn: PropTypes.func,
  setVisibleStoreList: PropTypes.func,
  setAddressPostalCodeRequest: PropTypes.func,
  setAddressRequest: PropTypes.func,
  setNoAddressesList: PropTypes.func,
  className: PropTypes.string,
  handleGTM: PropTypes.func
}
