import styled from 'styled-components'

import { VerticalProductContainer } from '../VerticalProduct/styles'

export const CategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 1.5rem 1rem;
  min-width: 100%;
  max-width: 100%;
  margin-bottom: 1.5rem;

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: 60rem) {
		padding: 1.5rem 2rem;
    flex-direction: row;
    flex-wrap: wrap;
  }

  ${VerticalProductContainer} {
    &:first-of-type {
      padding: .5rem 0 1rem 0;
    }

    @media (min-width: 60rem) {
      &:first-of-type {
        padding: 1rem;
      }

      &:nth-child(2n) {
        margin: 0 1rem 1rem 0;
      }
    }
  }
`

export const H1 = styled.h1`
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  min-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textFourthColor) || '#e2e2e2'} .063rem solid;
  padding-bottom: 1.25rem;
  margin-bottom: .5rem;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#333'};
  font-size: 1.125rem;

  @media (min-width: 60rem) {
    margin-bottom: 1rem;
  }
`
