export default ({
  apiService
}) => {
  const strategy = {
    request: async ({
      data,
      auth,
      dispatch,
      actions,
      maps
   }) => {
      const {
        setLoading
      } = actions

      const {
        loading,
        orderId
      } = data

      const {
        mapCartList
      } = maps

      if (loading && loading.length && loading.indexOf('highlights') > -1) {
        return false
      }

      dispatch(setLoading('cart'))
  
      const url = `Pedidos/ObterPedidosPorComanda?comandaId=${ orderId }`
      const result = await apiService.get(url, auth)
      const mappedResult = mapCartList(result.data)
  
      return mappedResult
    }
  }

  return {
    fetchCart: async (payload) => {
      return await strategy['request'](payload)
    }
  }
}
