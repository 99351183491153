import React from 'react'
import styled from 'styled-components'

const Checkbox = styled.input`
  width: 1.25rem;
  height: 1.25rem;
  font-size: 1.25rem;
  width: auto;
  opacity: 0;
  position: absolute;
  left: 0;
  margin-left: -1.25rem;

  &:checked ~ label:after {
    transform: rotate(-45deg) scale(1);
  }

  &:checked ~ label:before {
    background-color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
  }
`

const Span = styled.span`
  position: relative;
  flex: 1;
`

const Label = styled.label`
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    margin: 4px;
    width: 1.375rem;
    height: 1.375rem;
    transition: transform 0.28s ease;
    border-radius: 0.5rem;
    border: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'} 0.125rem solid;
  }

  &:after {
    content: '';
    display: block;
    width: 0.625rem;
    height: 0.313rem;
    border-bottom: 0.125rem solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.invertedPrimaryColor) || '#000'};
    border-left: 0.125rem solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.invertedPrimaryColor) || '#000'};
    transform: rotate(-45deg) scale(0);
    transition: transform ease 0.25s;
    position: absolute;
    top: 0.75rem;
    left: 0.625rem;
  }
`

export function CustomCheckbox(props) {
  const {
    id,
    type,
    name,
    disabled
  } = props

  return <Span>
    <Checkbox { ...props } type={type ? type : 'checkbox'} key={id} name={name} disabled={disabled}/>
    <Label htmlFor={ id } />
  </Span>
}
