import styled from 'styled-components'

import { device } from '../../../../domain/enum/responsive'


export const ReceiptSumaryContainer = styled.div`
  grid-area: summary;
  width: 100%;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  @media (min-width: 60rem) {
    padding: 0 0 0 1.5rem;
  }

  #cart-content {
    margin: 2rem 0 0 0;
    
    #notification {
      display: none;
    }
  }
`

export const ReceiptSummaryStore = styled.div`
  display: none;
  align-items: center;
  border-bottom: 1px solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'};
  padding: 0 0 1rem 0;
  margin: 0 0 1rem 0;

  @media (min-width: 60rem) {
    display: flex;
  }
`

export const ReceiptSummaryContent = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 60rem) {
    overflow: auto;

    &::-webkit-scrollbar {
      width: 1.5rem;
    }

    &::-webkit-scrollbar-thumb {
      border-left: 1rem solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      background-color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'};
    }

    &::-webkit-scrollbar-button {
      width: 0;
      height: 0;
      display: none;
    }

    &::-webkit-scrollbar-corner {
      background-color: transparent;
    }
  }
`

export const StoreContainer = styled.label`
  display: flex;
  flex-direction: column;
  flex: 1 1 70%;
`

export const StoreLabel = styled.label`
  font-weight: 600;
  font-size: .875rem;
  line-height: 1.188rem;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
`

export const StoreName = styled.h3`
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.688rem;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' }; 
  margin: 0;
`

export const StoreLogo = styled.img`
  flex: 0 1 30%;
  max-width: 4rem;
`

export const StatusBox = styled.div`
  flex: 1;
  margin-bottom: 1.875rem;
  border: #000 .125rem solid;
  padding: .938rem;
  box-sizing: border-box;
  border-radius: .625rem;
  text-align: center;

  @media ${device.desktop} {
  min-width: unset;
  max-width: unset;
  text-align: left;
  padding: 0;
  margin-bottom: 0;

  > p:nth-child(3) {
    text-align: center;
    > strong {
    font-size: 1.5rem;
    }
  }
  }

  &.status_0 {
  border: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textBlueColor) || '#333'} .125rem solid;
  background: #fff;
  margin: 0 1.5rem 0;


  @media ${device.desktop} {
    border: 0 none;
    background: none;
  }
  }

  &.status_1 {
  border: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textEighthColor) || '#444'} .125rem solid;
  background: #fff;
  margin: 0 1.5rem 0;

  @media ${device.desktop} {
    border: 0 none;
    background: none;

    > p {
    color: #000;

    &.requestNumber {
      background: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textSeventhColor) || '#333'};

      > span {
      display: inline-block;
      color: #fff;
      font-size: 2.5rem;
      }

      > strong {
      color: #fff;
      font-size: 2.5rem;
      }
    }

    > strong {
      color: #000;
    }
    }
  }

  @media ${device.mobile} {
    > p {
    color:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000'};

    > div {
      color:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
      font-size: 1.25rem;
    }

    &.requestNumber {
      > span {
      display: none;
      }

      > strong {
      color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
      }
    }

    > strong {
      color:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000'};
    }
    }
  }
  }

  > p {
  min-width: unset;
  margin: 0;
  padding: 0;
  margin-bottom: 1.875rem;

  &.only-mobile {
    @media ${device.mobile} {
    display: block;
    color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
    };

    @media ${device.desktop} {
    display: none;
    color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
    }
  }

  &.only-desktop {
    @media ${device.mobile} {
    display: none;
    color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
    }

    @media ${device.desktop} {
    display: block;
    color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
    }
  }

  &.date {
    margin-bottom: .938rem;
    color:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.grey) || '#000'};

    @media ${device.desktop} {
    margin-bottom: 1.875rem;
    }
  }

  &.requestNumber {
    margin-bottom: 0;

    @media ${device.desktop} {
    background: #F7F7F7;
    flex: 1;
    border: #DDD .063rem solid;
    padding: .938rem 0;
    box-sizing: border-box;
    border-radius: .313rem;
    text-align: center;
    color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};

    > span {
      display: inline-block;
      color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
      text-transform: uppercase;
      font-size: 2.5rem;
      font-weight: bold;
    }

    > strong {
      color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
      font-size: 2.5rem;
    }
    }

    @media ${device.mobile} {
    > strong {
      color: #000;
      font-size: 2.5rem;
    }

    > span {
      display: none;
    }
    }
  }

  > strong {
    color: #000;
    font-size: 1.25rem;
  }
  }
`

export const HeaderBox = styled.div`
  > h3 {
  font-size: 1.2rem;
  line-height: 1.5rem;
  color:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000'};
  text-align: center;
  margin: 0 0 1.875rem 0;
  padding: 0;
  min-width: 280px;
  flex: 1;

  &.date {
    font-weight: 400;
    font-size: .938rem;
  }
  
  > span{
    &.status_0 {
    color:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#FB9D19'};
    }

    &.status_1 {
    color:${props => (props.theme && props.theme.colors && props.theme.colors.positive) || '#FB9D19'};
    }
  }

  }

  @media ${device.desktop} {
  min-width: unset;
  max-width: unset;
  text-align: left;
  margin-bottom: 0;
  }

  > div {
  color:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
  font-size: 1.25rem;
  }

`
