export default ({
  apiService
}) => {
  const strategy = {
    coupons: async ({
      data,
      auth,
      dispatch,
      actions,
      maps
    }) => {
      const {
        setLoading
      } = actions

      const {
        loading
      } = data

      const {
        mapCouponsData
      } = maps

      if (loading && loading.length && loading.indexOf('coupons') > -1) {
        return false
      }

      dispatch(setLoading('coupons'))
  
      const url = `Cupons/Listar`
      const result = await apiService.get(url, auth)
      const mappedResult = mapCouponsData(result.data)
  
      return mappedResult
    },
    add: async ({
      data,
      auth,
      dispatch,
      actions,
      maps
    }) => {
      const {
        setLoading
      } = actions

      const {
        loading
      } = data

      const {
        mapCouponData,
        mapCouponRequest
      } = maps

      if (loading && loading.includes('add-coupon')) {
        return false
      }

      dispatch(setLoading('add-coupon'))
  
      const url = `Cupons/Cadastrar`
      const result = await apiService.post(url, mapCouponRequest(data), auth)
      const mappedResult = mapCouponData(result.data)
  
      return mappedResult
    }
  }

  return {
    fetchCoupons: async (payload) => {
      return await strategy['coupons'](payload)
    },

    addCoupon: async (payload) => {
      return await strategy['add'](payload)
    }
  }
}
