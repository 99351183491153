import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex; 
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem;
  margin: 1rem 0 0;
  border: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#e2e2e2'} .063rem solid;
  border-radius: .5rem;
  cursor: pointer;

  &.active{
    border: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#e2e2e2'} .160rem solid;
  }

  &:first-child {
    margin-top: 0;
  }

  * {
    pointer-events: none;
  }

  > .full-text {
    min-width: 100%;
    max-width: 100%;
  }

  > .image {
    flex: 0;
    max-height: 5rem;
    min-height: 5rem;
    max-width: 5rem;
    min-width: 5rem;
    border-radius: .625rem;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1.1rem;

    > img {
      min-height: auto;
      max-height: unset;
      min-width: 100%;
      max-width: 100%;
      flex: 1;
    }
  }

  > div {
    > div {
      &.service-info {
        margin-bottom: .313rem;
      }
    }

    > p {
      margin-bottom: .125rem;

      &.coupon {
        background: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textSixthColor) || '#e2e2e2'};
        color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
        border-radius: .625rem;
        font-size: .688rem;
        width: auto;
        padding: .313rem .625rem;
        line-height: .688rem;
        height: 1rem;
        box-sizing: border-box;
        display: inline-block;
        min-width: unset;

        & custom-icon{
          font-size: .875rem;
        }
      }

      &.coupon-free {
        background: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textSixthColor) || '#e2e2e2'};
        color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
        border-radius: .625rem;
        font-size: .75rem;
        width: auto;
        padding: .313rem .938rem;
        line-height: .75rem;
        height: 1rem;
        box-sizing: border-box;
        display: inline-block;
        min-width: unset;

        & custom-icon{
          font-size: .875rem;
        }
      }
    }
  }
`
export const Overlay = styled.div`
  min-height: 6.25rem;
  width: 90%;
  background: #000;
  position: absolute;
  opacity: .8;
  border-radius: 1.25rem;
  transform: translate(0, -10%);

  > div {
    margin-top: .938rem;

    &.over{
      display: flex;
      height: 100%;
      min-height: 100%;
      -webkit-align-items: center;
      align-items: center;
      -webkit-justify-content: center;
      justify-content: center;
      color: #fff;
    } 

    &.text{
      display: flex;
      height: 100%;
      min-height: 100%;
      -webkit-align-items: center;
      align-items: center;
      -webkit-justify-content: center;
      justify-content: center;
      color: #fff;
      font-size: .688rem;
      margin-top: .313rem;
      text-align: center;
    }
  }
`
export const Div = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  flex: 1;

  &.text {
    min-height: 5rem;
  }
`

export const Image = styled.img`
  border-radius: .625rem;
`

export const Paragraph = styled.p`
  min-width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  margin: 0;
  padding: 0;
  font-size: .75rem;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#333'};
  
  &.service-info{
    border: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'} .063rem solid;
    border-radius: 1rem;
  }

  &.description {
    font-size: .825rem
    color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.grey02) || '#333'};
    
    >span {
      &.dot{
        font-weight: bold;
        font-size: 1.6rem
      }
    }
   
  }

  &.address {
    color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
    margin-bottom: .625rem;
  }

  > span {
    
    &.time-from {
      font-size: .8rem;
      color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
      margin-bottom: 0;
      padding: .1rem;
      border-radius: 1rem;
      max-height: 1.875rem;
      white-space: nowrap;

      svg {
        margin: 0 0 0 .3rem;
        width: .60rem;

        path {
          fill:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
        }
      }
    }

    &.value-from {
      font-size: .8rem;
        color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
        margin-bottom: 0;
        padding: .1rem .2rem .1rem .1rem;
        border-radius: 1rem;
        max-height: 1.875rem;
        white-space: nowrap;

        svg {
          margin: 0 0 0 .3rem;
          width: .60rem;

          path {
            fill:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
          }
        }
      }
  }
`

export const Title = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 700;
  color: ${props => props.theme.main.general.textHighlightColor || '#002063'};
`

export const Span = styled.span`
  &.dot {
    margin-left: .313rem;
    margin-right: .313rem;
  }
`
