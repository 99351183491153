import { useEffect } from 'react'
import { useDispatch, useStore } from 'react-redux'

import { storeSettings } from '../../state/store-settings'

export function useStoreSettings() {
	const dispatch = useDispatch()
	const store = useStore()

	const selectedStoreId = store.getState().store && store.getState().store.selectedStoreId

	useEffect(() => {
		dispatch(storeSettings.fetchStoreSettings())
  }, [
		dispatch,
		selectedStoreId
	])

	const {
		title,
		url,
		description
	} = store.getState().main.storeSettings

	return {
		title,
		url,
		description
	}
}
