import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-width: 100%;
  border-radius: .938rem;
  padding: 1.25rem;
  position: relative;
	margin-bottom: 1rem;

	&.default {
		background: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.red04) || '#000' };
		border: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.red04) || '#000' } .063rem solid;
	}

	&.warning {
		background: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.red04) || '#000' };
		border: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.red04) || '#000' } .063rem solid;

		> .close-button {
			color: #fff;
		}
	}

	&.error {
		background: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.red04) || '#000' };
		border: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.red04) || '#000' } .063rem solid;

		> .close-button {
			color: #fff;
		}
	}

	&.info {
		background: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
		border: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' } .063rem solid;

		> .close-button {
			color: #fff;
		}
	}

  > .close-button {
    position: absolute;
    right: .5rem;
    top: 50%;
		transform: translate(0, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;

    > span {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      min-height: 100%;
    }
  }
`

export const Paragraph = styled.p`
  margin: 0;
  padding: 0;
	line-height: 1.25rem;
	min-height: 1.25rem;
	padding-right: 1.25rem;
	color: #fff;
`

export const CloseButton = styled.button`
  background: none;
  border: 0 none;
  padding: .5rem;
  margin: 0;
	box-sizing: border-border;
	color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
`
