export default {
	SET_ADDRESS_BY_CEP: 'SET_ADDRESS_BY_CEP',
  SET_ADDRESS: 'SET_ADDRESS',
	SET_ADDRESSES: 'SET_ADDRESSES',
  SET_ADDRESS_POSTAL_CODE_REQUEST: 'SET_ADDRESS_POSTAL_CODE_REQUEST',
  SET_ADDRESS_REQUEST: 'SET_ADDRESS_REQUEST',
  SET_NO_ADDRESSES_LIST: 'SET_NO_ADDRESSES_LIST',
  SET_CEP: 'SET_CEP',
  SET_GEOLOCATION: 'SET_GEOLOCATION',
  SET_USER_ADDRESS: 'SET_USER_ADDRESS',
  SET_USER_ADDRESSES: 'SET_USER_ADDRESSES',
  REMOVE_USER_ADDRESSES_ITEM: 'REMOVE_USER_ADDRESSES_ITEM',
}
