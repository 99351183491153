export default ({
  storeRepository,
  maps,
  auth
}) => {
  return async (payload) => {
    const {
      onError,
      data,
      actions,
      dispatch
    } = payload

    try {
      const validation = await storeRepository.fetchStoreAddressValidation({
        data,
        actions,
        maps,
        dispatch,
        auth: auth()
      })

      return validation
    } catch(e) {
      console.log(e)

      onError()
    }
  }
}
