import React, { useState } from 'react'

import { CustomSelect } from '../../components/CustomSelect'

import {
	CategoriesSelectWrapper
} from './styles'

export function CategoriesSelect(props) {
	const {
		data,
		handleSearchCategories
	} = props
	const [selectedOption, setSelectOption] = useState('')

	const handleCategories = (search) => {
		handleSearchCategories(search)
	}

	return <CategoriesSelectWrapper>
		{data && <CustomSelect
			id='categories'
			variant='outlined'
			options={data}
			selectedOption={selectedOption}
			setSelectOption={setSelectOption}
			handleCategories={handleCategories}
			all='Todas as categorias'
		/>}
	</CategoriesSelectWrapper >
}
