import styled from 'styled-components'

export const Wrapper = styled.div`
  min-height: 100%;
  min-width: 100%;
  max-width: 100%;
  flex: 1;
  overflow-x: hidden;
`

export const Steps = styled.div`
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  position: absolute;
  top: 0;
  left: auto;
  display: flex;
  flex-wrap: nowrap;
  transition: all .25s ease;
  flex-direction: row;
  transition: all .25s ease;
`
