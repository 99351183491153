import styled from 'styled-components'

export const Wrapper = styled.main`
  margin: 0;
  background-color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.backgroundMain) || '#CCC'};
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;

  ${ props => props.itemId ? 'display: none;' : ''}

  @media (min-width: 60rem) {
    ${props => {
      if (props.opened) {
        return `max-width: 40rem;
        min-width: calc(100% - 20rem);`
      } else {
        return `max-width: 59.8rem;
        min-width: 59.8rem;`
      }
    }}
    ${ props => props.itemId ? 'display: unset;' : ''}
    border-left: 1px solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'};
    border-right: 1px solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'};
  }

  @media (min-width: 80rem) {
    ${props => {
      if (props.opened) {
        return `max-width: 58.8rem;
        min-width: 58.8rem;`
      } else {
        return `max-width: 70rem;
        min-width: 58.8rem;`
      }
    }}

    ${ props => props.itemId ? 'display: unset;' : ''}
    border-left: 1px solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'};
    border-right: 1px solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'};
  }

	@media (min-width: 95rem) {
		max-width: 70rem;
  }
`
