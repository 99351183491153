import styled from 'styled-components'

import { fadeIn } from '../components'

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  margin: 0 auto;
  position: relative;
  max-width: 100%;
  min-width: 100%;
  ${ props => props.maxHeight ?
    `max-height: ${ props.maxHeight }px;
    min-height: ${ props.maxHeight }px;
    overflow: hidden;` :
    `` }

  * {
    font-family: 'Nunito', 'sans-serif';
  }

  > div {
    min-width: 100%;
    max-width: 100%;

    &#cart {
      position: absolute;
      left: 0;
      top: 100%;
      z-index: 2;
      min-width: 100%;
      max-width: 100%;
      min-height: 100%;

      > div {
        &#cart-content {
          flex: none;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding-bottom: 5.625rem;
          
          > div {
            &.cart-list {
              flex: none;
            }
          }
        }
      }
    }

    &#store-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      flex: 1;
      max-width: 100%;
      min-width: 100%;
      position: relative;

      &.no-cart {
        > div {
          &#store-cartbar {
            opacity: 0;
            pointer-events: none;
          }

          &#store-products {
            padding-bottom: 0;
          }
        }
      }

      > div {
        &#store-header {
          padding-bottom: .625rem;
          min-width: 100%;
          max-width: 100%;
        }

        &#store-search {
          padding: 0 1.25rem;
          margin-bottom: 1.25rem;
          max-width: 100%;
          min-width: 100%;
        }
        
        &#store-search-category {
          padding: 0 1.25rem;
          margin-bottom: 1.875rem;
          max-width: 100%;
          min-width: 100%;
        }

        &#store-products {
          padding: 0 1.25rem;
          padding-bottom: 0;
          max-width: 100%;
          min-width: 100%;
        }

        &#addressbar {
          position: fixed;
          left: 0;
          bottom: 0;
          opacity: 0;
          max-width: 100%;
          min-width: 100%;

          &.motion {
            opacity: 1;
          }
        }

        &#store-product {
          position: absolute;
          left: 0;
          top: 0;
          z-index: 5;
        }

        &#store-categories-products {
          max-width: 100%;
          min-width: 100%;
          padding-bottom: 6.875rem;
        }
      }
    }
  }
  
  > div {
    display: flex;
    flex: 1;

    > main {
      background: #fff;
      min-height: 100%;
      max-height: unset;
      box-sizing: border-box;
      position: relative;

      > div {
        &#cards {
					@media (min-width: 60rem) {
						min-height: calc(100% - 17rem);
						max-height: calc(100% - 17rem);
					}
        }
      }

      > img {
        height: auto;
        max-height: unset;
        max-width: 100%;
        min-height: auto;
        min-width: 100%;
        width: 100%;
        display: none;

        @media (min-width: 60rem) {
          display: unset;
        }
      }
    }
  }

  @media (min-width: 60rem) {
    > div {
      > main {
        margin: 0px auto;
        min-height: calc(100% - 5.625rem);
        max-height: calc(100% - 5.625rem);
        box-sizing: unset !important;
      }
    }
  }
`

export const ContentWrapper = styled.div`
  flex: 1;
  min-width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  ${fadeIn}
`

export const Paragraph = styled.p`
  margin: 0;
  padding: 0;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#333'};
  font-size: 1rem;
`

export const BackButton = styled.button`
  margin: 0;
  padding: 0;
  background: none;
  border: 0 none;
  color: #FFF;
  font-size: 1rem;
  position: absolute;
  cursor: pointer;
  left: 1.25rem;
  top: 1.25rem;
  z-index: 1;

  @media (min-width: 40rem) {
    display: none;
  }
`

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'}
  flex: 1;
`

export const SubHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 1.5rem 1rem 0;
  max-width: 100%;
  min-width: 100%;
  box-sizing: border-box;

  #store-search {
    min-width: auto;
    flex: 1;
  }

  @media (min-width: 60rem) {
    padding: 2rem 2rem 0;

    #store-search {
      flex: 0 0 calc(60% - .5rem);
    }
  }
`

export const H1 = styled.h1`
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  margin: 0;
  padding: 1rem 0;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: bold;
  min-width: 100%;
  text-transform: none;
  text-align: center;
  border-bottom: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#000' } .063rem solid;
  padding-bottom: 1.25rem;

  @media (min-width: 60rem) {
    text-align: left;
    font-size: 1.25rem;
    text-transform: uppercase;
    padding: 2rem 2rem 1rem 2rem;
    min-width: calc(100% - 4rem);
    margin-bottom: 1rem;
  }
`
export const WhattsAppImg = styled.div`
  width: 4rem;
  height: 4rem;
  position: fixed;
  bottom: 1.25rem;
  right: 1.25rem;
  cursor: pointer;
  z-index: 1;

  &.ativo{
    bottom: 7rem;
  }
  
  img, svg {
    width: 4rem;
    height: 4rem;
    display: block;
  }
`
