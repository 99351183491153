import React from "react";

import { ReactComponent as IconCashback } from '../../../assets/icons/cashback.svg'
import { CashbackContent } from "./styles";

export const Cashback = (props) => {

    const { cashback, isMobile, smallSizeOnly, top } = props;

    return (
        <CashbackContent smallSizeOnly={smallSizeOnly} top={top}>
            <IconCashback />
            <span>AQUI TEM {cashback}% DE CASHBACK {!isMobile && !smallSizeOnly ? 'PARA UTILIZAR NO APP' : ''} </span>
        </CashbackContent >
    )
}
