import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  min-width: 100%;
  max-width: 100%;
  height: 3.125rem;
  z-index: 10;
  transition: all .5s ease-out;

  @media (max-width: 60rem) {
    ${props => {
      if (props.fixed) {
        return `
          position: fixed; 
          top: 0; 
          left: 0; 
          opacity: 1; 
          overflow: hidden;
          
          -webkit-animation: fadein 1s;
          -moz-animation: fadein 1s;
          -ms-animation: fadein 1s;
          -o-animation: fadein 1s;
          animation: fadein 1s;        
          `
      }

      return `display: none; opacity: 0;`
    }}
  }

  @media (min-width: 60rem) {
    display: none;
  }
`

export const HeaderBox = styled.div`
  background: #fff;
  color: ${props => props.theme.main.general.primaryColor || '#000'};
  box-sizing: border-box;
  cursor: pointer; 
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

export const BackButton = styled.button`
  margin: 0;
  padding: 0;
  background: none;
  border: 0 none;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000'};
  font-size: 1rem;
  position: absolute;
  cursor: pointer;
  left: 1.25rem;
  top: 1.25rem;
  z-index: 1;
`

export const H1 = styled.h1`
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
  margin-top: 1.25rem;
  padding: 0;
  font-size: 1.125rem;
  line-height: 1.25rem;
  font-weight: bold;
  min-width: 100%;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 1.25rem;
`
