export default ({
  storeSettingsRepository
}) => {
  return async (payload) => {
		const {
      onSuccess,
      onError
    } = payload

    try {
      const storeSettings = await storeSettingsRepository.get()

      onSuccess({storeSettings})
    } catch(e) {
      console.log(e)

      onError()
    }
  }
}
