import { actionTypes } from '.'

import {
	loading,
	modality,
	store,
	addressActions
} from '..'

export const create = (cart) => (dispatch) => {
  dispatch({
    type: actionTypes.NEW_CART,
    payload: cart
  })
}

export const update = (cart) => (dispatch) => {
  dispatch({
    type: actionTypes.UPDATE_CART,
    payload: cart
  })
}

export const cleanCart = () => (dispatch) => {
  dispatch({
    type: actionTypes.CLEAN_CART
  })
}

export const setSideMode = (sideMode) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_SIDE_MODE,
    payload: sideMode
  })
}

export const setCoupon = (coupon) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_COUPON,
    payload: coupon
  })
}

export const setCartCoupon = (coupon) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_CART_COUPON,
    payload: coupon
  })
}

export const fetchCart = (callback) => (dispatch, getState, container) => {
  try {
    const orderId = getState().main.order && getState().main.order.id

    const data = {
      orderId
    }

    const actions = {
      setLoading: loading.setLoading
    }

    return container.fetchCart({
      onSuccess: ({ cart}) => {
        dispatch(update({
          products: cart.items
        }))
        dispatch(modality.setModality({
          id: cart.modality.id
        }))
        dispatch(store.setStore({
          id: cart.store.id,
          name: cart.store.name,
          address: cart.store.address
        }))
        dispatch(addressActions.setAddress({
          main: cart.deliveryAddress
        }))
      },
      onError: () => {
        dispatch(cleanCart())
      },
      onEmpty: () => {
        dispatch(cleanCart())
      },
      dispatch,
      data,
      actions
   })
  } catch (e) {
    console.log(e)

    dispatch(cleanCart())
  } finally {
    callback && callback()
  }
}
