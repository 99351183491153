import styled from 'styled-components'

export const Wrapper = styled.div`
	font-family: ${props => props.theme.newFontFamily};
	padding: 1rem 1.5rem;
	border-bottom: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'} .063rem solid;
	display: flex;
	flex-direction: row;
	min-width: calc(100% - 1.5rem); 
	margin-bottom: 1rem;

	> div {
        &.content{		
			>div{
				display: flex;
			}
         	width: 80%;
			> p{
				margin: 0 0 0 .2rem;
				color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000'};
			}

			> img {
				margin-right: .625rem;
				height: 1.563rem;
				width: auto;
			}
        }
        
        &.edit{
          display: flex;
          flex-direction: column;
          justify-content: end;

          svg {
            width: 1rem;
          }
        }
      }

	> p {
		color: #C2C2C2;
		font-size: 1.2rem
		margin-right: .625rem;

		

		> span {
			
		}
	}
`

export const Paragraph = styled.p`
	margin: 0;
	padding: 0 0 0 .6rem;
`

export const Image = styled.img``

export const Span = styled.span``

export const Button = styled.button`
	background: none;
	border: 0 none;
	padding: 0;
	margin: 0;
	color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
`
export const H3 = styled.h3`
  font-size: 1.125rem;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000'};
  margin: 0;
  padding: 0;
  margin-bottom: .1rem;
  min-width: 100%;
  display: flex;
  align-items: center;

  > button {
    display: flex;
    align-items: center;
  }
`
