import { CARD_BRANDS_IDS } from '../../domain/enum/payment'
import alelo from '../../view/assets/card_brand_alelo.svg'
import amex from '../../view/assets/card_brand_amex.svg'
import ben from '../../view/assets/card_brand_ben.svg'
import diners from '../../view/assets/card_brand_diners.svg'
import elo from '../../view/assets/card_brand_elo.svg'
import hipercard from '../../view/assets/card_brand_hipercard.svg'
import mastercard from '../../view/assets/card_brand_mastercard.svg'
import sodexo from '../../view/assets/card_brand_sodexo.svg'
import ticket from '../../view/assets/card_brand_ticket.svg'
import visa from '../../view/assets/card_brand_visa.svg'
import vr from '../../view/assets/card_brand_vr.svg'
import cash from '../../view/assets/cash_payment.svg'


const images = {
	0: cash,
	[CARD_BRANDS_IDS.MASTERCARD]: mastercard,
	[CARD_BRANDS_IDS.VISA]: visa,
	[CARD_BRANDS_IDS.AMEX]: amex,
	[CARD_BRANDS_IDS.ELO]: elo,
	[CARD_BRANDS_IDS.HIPERCARD]: hipercard,
	[CARD_BRANDS_IDS.ALELO]: alelo,
	[CARD_BRANDS_IDS.BEN]: ben,
	[CARD_BRANDS_IDS.DINERS]: diners,
	[CARD_BRANDS_IDS.SODEXO]: sodexo,
	[CARD_BRANDS_IDS.TICKET_VR]: ticket,
	[CARD_BRANDS_IDS.TICKET_VA]: ticket,
	[CARD_BRANDS_IDS.VR]: vr,
	[CARD_BRANDS_IDS.CASH]: cash
}

const getCardBrandImageById = (brandId) => {
	return images[brandId]
}

export default getCardBrandImageById
