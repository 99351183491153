import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  min-width: 100%;
  max-width: 100%;
  background: #fff;
  font-size: .875rem;
  min-height: 100%;
  padding: 1.25rem;
`
export const ListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  overflow-x: scroll;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  max-height: 3.75rem;
  min-height: 3.75rem;
  height: 2.5rem;
  min-width: 100%;
  max-width: 100%;
  transition: all .25s ease-in;

  &::-webkit-scrollbar {
    width: 0 !important
  }
  
  > a{
    text-transform: uppercase;
    cursor: pointer;
    font-weight: bold;
    color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textSecondaryColor) || '#999' };
    font-size: .875rem;
    padding-bottom: .625rem;
    min-height: 2.5rem;
    max-height: 2.5rem;
    line-height: 2.5rem;
    padding: 0;
    transition: all .25s;
    text-decoration: none;
    margin-right: 1.25rem;
    padding: 0 .125rem;

    &:hover, &:active, &.active {
      color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
      border-bottom: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' } .125rem solid;
      text-decoration: none;
      font-size: 1rem;
    }

    &:last-child {
      margin-right: 1.25rem;
      padding-right: 1.25rem;
    }
  }
`

export const CategoriesSelectWrapper = styled.div`
    position: relative;
    display: none;

    @media (min-width: 60rem) {
      display: block;
      flex: 0 0 calc(40% - .5rem);
      margin: 0 1rem 0 0;
    }

    svg {
      width: 1rem;
      position: absolute;
      right: .938rem;
      padding: 0;
      top: 50%;
      transform: translate(0, -50%);

      path {
        stroke: #C3C3C3;
      };
    }
`

export const CategoriesSelect = styled.select`
  border-color: #CDCDCD;
  cursor: pointer;
  height: 2.5rem;
  background-color: #F8F8F8;
  border-radius: 1rem;
  padding: 0 .938rem;
  color: #AEAEAE;
  font-weight: normal;
  font-size: .75rem;
  line-height: 1rem;
  color: #AEAEAE;
  appearance: none;
  width: 100%;
`

export const CategoriesSelectOption = styled.option`

`
