import styled from 'styled-components'

import { DefaultButton, DefaultInput, DefaultParagraph, DefaultSpan, fadeIn } from '../../components'

export const Wrapper = styled.div`
  background: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  box-sizing: border-box;
  margin: 0 auto;
  box-sizing: border-box;
  max-width: 100%;
  min-width: 100%;
  position: relative;

  ${fadeIn}

  @media(min-width: 60rem) {
    
  }
`

export const ContentWrapper = styled.div`
  background: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
  //transition: all .25s ease-in;
  flex: 1;
  max-width: calc(100% - 2.5rem);
  min-width: calc(100% - 2.5rem);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.25rem;
  position: relative;
  flex-direction: column;
  box-sizing: border-box;

  ${ fadeIn }
`

export const Paragraph = styled(DefaultParagraph)`
  margin: 1rem;
`


export const GeolocationPage = styled.div`
  width: 100%;
  height: 100%;  
  background-image: url(${props => props.backgroundImage});
  background-size: auto;
  background-size: cover;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  min-width: 100%;

  * {
    box-sizing: border-box;
  }
`

export const GeolocationContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  min-width: 100%;
  padding: 3rem .8rem;
  box-sizing: border-box;

  @media (min-width: 25rem) {
    max-width: 25rem;
    min-width: 25rem;
  }

  @media (max-width: 60rem){
    padding: 3rem 2rem;
  }
`

export const StoreLogoImage = styled.img`
  width: 19rem;
  align-self: center;
`

export const GeolocationGreetingsText = styled.p`
  font-size: 1.25rem;
  font-weight: 700;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.secondaryColor) || '#666' };
  text-align: center;
  margin: 3rem 0 0 0;

  @media (min-width: 60rem) {
    font-size: 1.5rem;
    text-align: center;
    margin: 4rem 0 0 0;
  }
`

export const GeolocationDescriptionText = styled.p`
  font-size: 1.25rem;
  font-weight: 700;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.secondaryColor) || '#666' };
  text-align: center;
  margin: 2rem 0 2rem 0;

  @media (min-width: 60rem) {
    font-size: 1.5rem;
    margin: 4rem 0 3rem 0;
  }
`

export const GeolocationActions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  max-width: 100%;

  @media (min-width: 60rem) {
    max-width: 23rem;
  }
`

export const GeolocationButton = styled.button`
  background-color: ${ props => 
    (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.buttonColorPrimary) || '#000' 
  };
  color: ${ props => 
    (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.buttonColorSecondary) || '#000' 
  };
  font-size: 1rem;
  display: flex;
  align-items: center;
  height: 3.5rem;
  min-width: 100%;
  max-width: 100%;
  border-radius: 1rem;
  border: 1px solid ${ props => 
    (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.buttonColorSecondary) || '#000' 
  };
  padding: .5rem 1rem;
  font-weight: 700;
  cursor: pointer;
  box-sizing: border-box;

  :hover {
    background-color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
  }

  @media (min-width: 30rem) {
    min-width: 21rem;
    max-width: 21rem;
  }
`
export const GeolocationButtonSpan = styled.span`
  flex: 1;
  display: flex;
  justify-content: center;
`

export const GeolocationButtonIconContainer = styled.span`
  padding: 0 1rem 0 0; 
  border-right: .1rem solid rgba(255, 255, 255, .7);
  height: 100%;
  display: flex;
  align-items: center;

  span {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      height: 1.5rem;
      width: 1.5rem;
      fill: #fff;
    }
  }
`

export const CEPForm = styled.div`
  display: flex;
  align-items: center;
  margin: 2rem 0 0 0;
  max-width: 100%;

  button {
    background-color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.buttonColorPrimary) || '#000' };
    border: 1px solid ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.buttonColorSecondary) || '#000' };
    border-radius: 1rem;
    color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.buttonColorSecondary) || '#000' };;
    font-weight: 700;
    padding: 1rem;
    font-size: 1.25rem;
    text-align: center;
    cursor: pointer;
    height: 4rem;
    width: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;

    :hover {
      opacity: .9;
    }
  }
`

export const PostalCodeInputContainer = styled.div`
  position: relative;
  margin: 0 1rem 0 0;
  flex: 1;
  display: flex;
  max-width: 11rem;

  span {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
    height: 2rem;
    display: flex;
    align-items: center;
    font-size: 1rem;
    padding: 0 1rem 0 0;
    border-right: .1rem solid ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
    font-weight: 600;
  }

  input {
    height: 4rem;
    border-radius: 1rem;
    border: 0;
    min-width: 11rem;
    max-width: 16rem;
    font-size: 1rem;
    outline: none;
    color: rgba(0, 0, 0, .6);
    padding: 0 0 0 5rem;
    -moz-appearance: textfield;
    box-sizing: border-box;

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    ::placeholder {
      opacity: .3;
    }
  }

  @media (min-width: 30rem) {
    min-width: 16rem;
  }
`

export const Span = styled(DefaultSpan)``

export const Button = styled(DefaultButton)``

export const Input = styled(DefaultInput)``
