import styled from 'styled-components'

export const Label = styled.label``

export const Wrapper = styled.div`
	position: relative;

	> label {
		display: none;
	}

	@media (min-width: 40rem) {
		> label {
			opacity: 0;
			position: absolute;
			font-size: .8rem;
			left: .5rem;
			top: -.5rem;
			background: #fff;
			color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'};
			min-height: 1rem;
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			min-width: auto;
			max-width: 100%;
			z-index: 1;
			padding: 0 .5rem;
			transition: all .2s ease-out;
		}

		&.focused {
			> label {
				opacity: 1;
			}
		}
	}
`

export const Input = styled.input`
	background: #fff;
  border: .0625rem solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'};
  width: 100%;
  border-radius: .7rem;
  max-height: 3rem;
  min-height: 3rem;
  padding: 1rem;
  box-shadow: none;
  box-sizing: border-box;
  transition: all .2s ease-in;
	position: relative;

	@media (min-width: 40rem) {
		::placeholder {
			font-size: 1rem;
			line-height: 3rem;
			min-height: 3rem;
			display: flex:
			align-items: flex-start;
			justify-content: center;
			color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'};
			transition: all .2s ease-in;
			position: absolute;
			left: 1rem;
			top: 50%;
			transform: translate(0, -50%);
		}

		&:focus {
			outline: 0;
			box-shadow: 0 0 0 1px ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };

			::placeholder {
				font-size: .8rem;
				line-height: .8rem;
				min-height: .8rem;
				top: -.8rem;
				transform: translate(0, 0);
			}
		}
	}
`

export const Paragraph = styled.p`
  margin: 0;
  padding: 0;
  font-size: .875rem;

  &.notification {
    color: #f00;
  }
`
