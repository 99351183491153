import styled from 'styled-components'

export const Image = styled.img`
  border-radius: .625rem;
`

export const Wrapper = styled.div`
  display: flex; 
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 1.25rem;
  margin-bottom: 1.25rem;
  border-bottom: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textFourthColor) || '#e2e2e2'} .063rem solid;
  min-width: 100%;
  max-width: 100%;

  &:last-child {
    border-bottom: 0 none;
  }

  * {
    pointer-events: none;
  }

  > .text {
    min-width: 55%;
    max-width: 55%;
  }

  > .full-text {
    min-width: 100%;
    max-width: 100%;
  }

  > .image {
    flex: 0;
    max-height: 6.25rem;
    min-height: 6.25rem;
    max-width: 6.25rem;
    min-width: 6.25rem;
    border-radius: .625rem;
    overflow: hidden;
    position: relative;
    -webkit-overflow-scrolling: touch;
    display: flex;
    align-items: center;
    justify-content: center;

    > img {
      min-height: auto;
      max-height: unset;
      min-width: 100%;
      max-width: 100%;
      flex: 1;
    }
  }
`

export const H2 = styled.h2`
  margin: 0;
  margin-bottom: .625rem;
  padding: 0;
  font-size: .875rem;
  line-height: .875rem;
  font-weight: 600;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#333'};
`

export const Div = styled.div``

export const Paragraph = styled.p`
  margin: 0;
  padding: 0;
  margin-bottom: 1.25rem;
  font-size: .75rem;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#333'};

  &.description {
    color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#333'};
  }

  &.value {
    color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#333'};
    margin-bottom: 0;
  }

  &.promo {
    color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#333'};
    margin-bottom: 0;
    font-size: 1rem;
  }

  >span{
    color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#333'};
    margin-bottom: 0;
    font-size: .75rem;
    text-decoration: line-through;
    margin-left: .313rem;
  }
`

export const Span = styled.span``

export const VerticalProductContainer = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
  border-bottom: 1px solid #e7e6e7;
  width: 100%;
  max-height: 9.5rem;
  cursor: pointer;

  @media (min-width: 60rem) {
    padding: 1rem;
    border: 1px solid #d9d8d9;
    border-radius: 10px;
    flex: 1 1 auto;
    max-width: calc(50% - .5rem);
    margin: 0 0 1rem 0;
    height: 9.5rem;
  }

  &:hover {
    background-color: #f7f7f7;
  }

  * {
    box-sizing: border-box;
  }
`

export const VerticalProductContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 60%;
  margin: 0 .5rem 0 0;

  @media (min-width: 60rem) {
    height: 100%;
  }
`

export const VerticalProductName = styled.h3`
  margin: 0;
  font-weight: 600;
  font-size: 1rem;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
`

export const VerticalProductDescription = styled.p`
  margin: 0 0 .25rem 0;
  font-family: Nunito;
  font-weight: 400;
  font-size: .875rem;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#000' };
  max-height: 4.5rem;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
`

export const VerticalProductPriceContainer = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: 60rem) {
    margin: auto 0 0 0;
  }
`

export const VerticalProductPrice = styled.span`
  font-weight: 700;
  font-size: 1.25rem;

  &.promo {
    color: #19BF2B;
  }

  &.value{
    color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
  }
`

export const VerticalProductPromotionalPrice = styled.span`
  font-weight: 600;
  font-size: 1rem;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#CCC' };
  margin: 0 0 0 .5rem;
  text-decoration: line-through;
`

export const VerticalProductImageContainer = styled.div`
  flex: 0 0 6rem;
  height: 5.5rem;
  background-color: none;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  justify-content: center;

  img {
    height: inherit;
    max-height: unset;
    min-height: auto;
    transform: scale(.9);
  }

  @media (min-width: 60rem) {
    height: 100%;
    flex: 0 0 7rem;
  }
`
