interface CouponDataCoupons {
  id: number
  code: string
  quickDescription: string
  description: string
  expiration: string
  value: string
  maxValue: string
  minValue: string
  type: string
  addedAt: string
  expirationAt: string
  pickupValue: string
  pickupMaxValue: string
  pickupMinValue: string
  pickupType: string
}

interface CouponsData {
  message: string
  coupons: CouponDataCoupons[]
  success: boolean
}

interface CouponData {
  id: number
  value: number
  type: number
  message: string
  success: boolean
}

interface CouponRequest {
  Nome: string
}

export const mapCouponsData = (data: any) : CouponsData => {
  const coupons = (data['Cupons'] && data['Cupons'].length && data['Cupons'].map((mappedItem: any) => {
    return {
      id: mappedItem['CupomId'],
      code: mappedItem['Nome'],
      quickDescription: mappedItem['BreveDescricao'],
      description: mappedItem['Descricao'],
      expiration: mappedItem['Expirado'],
      value: mappedItem['Valor'],
      maxValue: mappedItem['ValorMaximo'],
      minValue: mappedItem['ValorMinimo'],
      type: mappedItem['TipoValorCupom'],
      addedAt: mappedItem['DataAdicionado'],
      expirationAt: mappedItem['DataExpiracao'],
      pickupValue: mappedItem['ValorCupomModalidadeRetirada'],
      pickupMaxValue: mappedItem['ValorMaximoCupomModalidadeRetirada'],
      pickupMinValue: mappedItem['ValorMinimoCupomModalidadeRetirada'],
      pickupType: mappedItem['TipoValorCupomModalidadeRetirada']
    }
  })) || []

	return {
    message: data['Mensagem'],
    success: data['Sucesso'],
    coupons
  }
}

export const mapCouponData = (data: any) : CouponData => {
  return {
    id: data['CupomId'],
    type: data['TipoValorCupomId'],
    value: data['Valor'],
    message: data['Mensagem'],
    success: data['Sucesso']
  }
}

export const mapCouponRequest = (data: any) : CouponRequest => {
  const {
    coupon
  } = data

  return {
    Nome: coupon
  }
}
