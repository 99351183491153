import styled from 'styled-components'

export const Wrapper = styled.div`
  background-color: #fff;
  box-sizing: border-box;
  margin: 0;
  max-width: 100%;
  min-width: 100%;
  position: relative;
  flex: 1;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;

  * {
    font-family: 'Nunito', 'sans-serif';
    box-sizing: border-box;
  }
`
export const NoCardIcon = styled.div`
  opacity: 0.2;
  svg {
    fill: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
  }

  svg path {
    fill: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
  }
`

export const ContentWrapper = styled.div`
  min-width: 100%;
  max-width: 100%;
  padding: 1rem 1rem 5.5rem;
  //min-height: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
	flex: 1;

	@media (min-width: 60rem) {
		padding: 0 2rem 5.5rem;
	}

  > div {
		&.notification {
			@media (min-width: 40rem) {
				position: absolute;
				left: 1rem;
				top: 1rem;
				min-width: calc(100% - 2rem);
				right: 1rem;
				z-index: 3;
			}
		}
  }
`

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  min-width: 100%;
`

export const LoadingImage = styled.img`
  width: 6.25rem;
  height: auto;
`
export const HeaderWrapper = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	border-bottom: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#000'} .063rem solid;
	justify-content: space-between;
	padding: 0 2rem;
	margin-bottom: 1rem;

	button {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: .875rem;
		height: 3.5rem;
		padding: 0 1rem;
		margin-top: 0.8rem;
		min-width: 12rem;
    max-width: 12rem;
	}

	@media (max-width: 60rem) {
		display: none;
	}
`
export const H1 = styled.h1`
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000'};
  margin: 0;
  padding: 1rem 0;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: bold;
  text-transform: none;
  text-align: center;
  padding-bottom: 1.25rem;

  @media (min-width: 60rem) {
    text-align: left;
    font-size: 1.25rem;
    text-transform: uppercase;
    padding: 2rem 2rem 1rem 2rem;
    margin-bottom: 1rem;
  }
`
export const H4 = styled.h4`
  margin: 20px 0 0 0;
  padding: 0;
  font-size: 1.125rem;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
  text-align: center;
`

export const PageTitle = styled.h4`
  margin: 0;
  padding: 0;
  font-size: 1.125rem;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
  text-align: center;
`

export const BackButton = styled.button`
  margin: 0;
  padding: 0;
  border: 0 none;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000'};
  background: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.secondaryColor) || '#000'};
  font-size: 1rem;
  position: absolute;
  cursor: pointer;
  left: 1.25rem;
  top: 1.25rem;
  z-index: 1;
  top: 50%;
  transform: translate(0, -50%);
`

export const WrapperHeader = styled.header`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 5rem;
  border-bottom: 1px solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'};
  margin: 0;
  background: #fff;

  @media (min-width: 60rem) {
    display: none;
  }
`

export const PaymentMethodContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
	position: relative;

  &.mobile{
    display: flex;

    .close,
    h1 {
      display: none;
    }

    form {
      padding: 0;
    }
  }

  &.web{
    display: none;
  }

  @media (min-width: 60rem) {
    &.mobile{
      display: none;
    }

    &.web{
      display: flex;
    }
  }
`

export const PaymentMethodHeader = styled.header`
  flex: 0 0 3.5rem;
  height: 3.5rem;
  background: #F6F6F6;
  border: 1px solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'};
  padding: 0 1rem;
  border-radius: 13px;
  display: flex;
  align-items: center;
  margin: 0 0 1rem 0;

  h3 {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.375rem;
    color: #6B6B6B;
    margin: 0;
  }
`

export const PaymentMethodHeaderMobile = styled.header`
  flex: 0 0 3.5rem;
  height: 3.5rem;
  background: #F6F6F6;
  border: 1px solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'};
  padding: 0 1rem;
  border-radius: 13px;
  display: flex;
  align-items: center;
  margin: 0 0 1rem 0;

  h3 {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.375rem;
    color: #6B6B6B;
    margin: 0;
  }

	@media (min-width: 60rem) {
    display: none;
  }
`

export const PaymentMethodItem = styled.li`
  flex: 1 0 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'};
  min-height: 3rem;
  padding: 1rem .5rem;

  @media (min-width: 60rem) {
    flex: 0 0 calc(50% - 1rem);

    &:nth-child(2n) {
      margin: 0 0 0 2rem;
    }
  }
`

export const PaymentMethodItems = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
	margin-bottom: 3rem;

  ${PaymentMethodItem} {

  }
`

export const PaymentMethodItemContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`

export const PaymentMethodItemActions = styled.div`
  flex: 0 0 3rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0;

    input {
      height: 1.5rem;
      width: 1.5rem;
      font-size: 1.5rem;
      margin: 0;
      opacity: 0;
      z-index: 3;
      cursor: pointer;
    }

    label {
      height: 1.5rem;
      width: 1.5rem;

      &:before {
        margin: 0;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
      }

      &:after {
        top: .45rem;
        left: .35rem;
      }
    }
  }

  svg {
    margin: 0 0 0 .5rem;
  }
`

export const BrandImage = styled.img`
  margin-right: 1rem;
  width: 2rem;
`

export const BrandName = styled.span`
  font-size: 1rem;
  line-height: 1.375rem;
  color: #7D7D7D;
  margin: .25rem 0 0 1rem;
`
export const BrandBlocked = styled.span`
  font-size: .75rem;
  line-height: 1;
  color: red;
  margin: .25rem 0 0 1rem;
  padding: 4px 8px;
  border: 1px solid red;
  border-radius: 8px;
  background-color: rgba(255, 26, 0, .08);
`

export const ActionContainer = styled.div`
  display: flex;
	align-items: flex-end;
	height: 3.5rem;
	flex-direction: column;
	margin-bottom: 2rem;

  &.top {
    position: unset;
    bottom: unset;
    left: unset;
    max-width: 100%;
    min-width: 100%;
    margin-bottom: 1rem;
    display: none;

    @media (min-width: 60rem) {
      display: block;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .875rem;
    flex: 1;
    height: 100%;
		max-width: 9rem;
  	min-width: 9rem;


    > svg {
      margin-left: 1rem;
    }
  }
  
  @media (min-width: 60rem) {
		display:none;
    position: absolute;
    bottom: 0;
    left: auto;
    right: 2rem;
    top: auto;
    max-width: 12rem;
    min-width: 12rem;
    margin-bottom: 1rem;

    &.top {
      button {
        max-width: 12rem;
        min-width: 12rem;
        right: 2rem;
        position: absolute;
        height: unset;
        max-height: 3.5rem;
        min-height: 3.5rem;
      }
    }
  }
`

export const Paragraph = styled.p`
  color: #c2c2c2;
  padding: 0 0 40px 0;
  font-size: .875rem;
`

export const ContentMensagem = styled.div`
  align-items: center;
  justify-content: center;
`

export const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  box-sizing: border-box;
  margin: 0 auto;
  box-sizing: border-box;
  max-width: 100%;
  min-width: 100%;
  position: relative;
`

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // min-height: 100%;
  // max-height: 100%
`
