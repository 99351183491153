import React, {
  useLayoutEffect,
  useState,
  useContext,
} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {Helmet} from 'react-helmet'

import history from '../../../history'

import {
  pipe,
  GetContent
} from '../../../domain/helpers'

import {
	setUserHistory
} from '../../../redux/actions/main'

import {
  loading,
  addressActions
} from '../../../state'

import {
	useStoreSettings
} from '../../hooks'

import {
  CustomLoading
} from '../../components'

import {
  Button,
  Wrapper,
  Paragraph,
  Span,
  Input,
  GeolocationPage,
  GeolocationContainer,
  StoreLogoImage,
  GeolocationGreetingsText,
  GeolocationDescriptionText,
  GeolocationActions,
  CEPForm,
  PostalCodeInputContainer
} from './styles'

export const Context = React.createContext({})

function HomeComponent(props) {
  const {
    accessToken,
    currentStep,
    fetchAddressByCEP,
    goNextStep,
    loading,
    postalCode,
    setCEP,
    setLoading,
    theme,
		setUserHistory
  } = props

  const context = useContext(Context)
  const { sections } = context
  const section = sections.filter(item => item.id === 'greetings')[0]
  const sectionTitle = section.content.title
  const sectionText = section.content.text
  const sectionForm = section.content.forms
  const sectionPostalCodeForm = sectionForm.filter(item => item.id === 'postal-code-form')[0]
  const sectionPostalCodeInput = sectionPostalCodeForm.fields.filter(item => item.id === 'postal-code-input')[0]
  const postalCodeInputLabel = sectionPostalCodeInput.label
  const postalCodeInputWarningText = sectionPostalCodeInput.warning
  const sectionPostalCodeButton = sectionPostalCodeForm.actions.filter(item => item.id === 'submit-button')[0]
  const postalCodeButtonLabel = sectionPostalCodeButton.label

  const storeBackgroundImage = theme.backgroundImage
  const storeLogo = theme.logoImage

	const {
		url,
		title,
		description
	} = useStoreSettings()

  const [warning, setWarning] = useState(false)

  useLayoutEffect(() => {
    setLoading({ item: 'welcome', delete: true })

    if (accessToken) {
      goNextStep()
    }

    return () => ({})
  }, [
    setLoading,
    accessToken,
    goNextStep
  ])

  function handlePostalCodeChange(event) {
    const {
      value
    } = event.target

    setCEP(value)
  }

  function handlePostalCodeClick() {
    if (postalCode && postalCode.length < 8) {
      setWarning(true)

      return
    }

    fetchAddressByCEP([{
			name: 'address-by-cep',
			type: 'error',
			callback: () => {
				history.push('/no-store')
			}
		}, {
			name: 'address-by-cep',
			type: 'success',
			callback: () => {
				goNextStep()

				setTimeout(() => {
					setUserHistory({
						back: '/',
						next: '/'
					})

					history.push('/')
				}, 1500)
			}
		}])
  }

  return <Wrapper>
		<Helmet>
			<title>{title}</title>
			<meta name='description' content={description} />
			<link rel='canonical' href={url} />
		</Helmet>

    {loading.includes('welcome') &&
      <CustomLoading
        color={'#fff'}
        type={'spin'}
        id='default-loading'
        height={40}
        width={40}
      />}

    {!loading.includes('welcome') && currentStep === 1 &&
      <GeolocationPage backgroundImage={storeBackgroundImage}>
        <GeolocationContainer>
          <StoreLogoImage src={storeLogo} />
          <GeolocationGreetingsText>{sectionTitle}</GeolocationGreetingsText>
          <GeolocationDescriptionText>
            {sectionText}
          </GeolocationDescriptionText>
          <GeolocationActions>
            <CEPForm>
              <PostalCodeInputContainer>
                <Span>{postalCodeInputLabel}</Span>
                <Input
                  type='text'
                  maxLength={9}
                  max={99999999}
                  placeholder='00000-000'
                  value={postalCode || ''}
                  onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                  onChange={handlePostalCodeChange}
                />
              </PostalCodeInputContainer>
              <Button onClick={handlePostalCodeClick}>{postalCodeButtonLabel}</Button>
            </CEPForm>
            {warning ? <Paragraph className='warning'>{postalCodeInputWarningText}</Paragraph> : null}
          </GeolocationActions>
        </GeolocationContainer>
      </GeolocationPage>
    }
  </Wrapper>
}

HomeComponent.propTypes = {
  loading: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string])),
	accessToken: PropTypes.string,
	currentStep: PropTypes.number,
	fetchAddressByCEP: PropTypes.func,
	goNextStep: PropTypes.func,
	postalCode: PropTypes.string,
	setCEP: PropTypes.func,
	setLoading: PropTypes.func,
	theme: PropTypes.shape({
		backgroundImage: PropTypes.string,
		logoImage: PropTypes.string,
	}),
	setUserHistory: PropTypes.func
}

const mapStateToProps = (state) => {
  return {
    postalCode: state.address.cep || '',

    accessToken: state.user.accessToken,
    loading: state.loading.loading || []
  }
}

const GetConnection = connect(mapStateToProps, {
	setCEP: addressActions.setCEP,
  setGeolocation: addressActions.setGeolocation,
  setLoading: loading.setLoading,
  fetchAddressByGeolocation: addressActions.fetchAddressByGeolocation,
  fetchAddressByCEP: addressActions.fetchAddressByCEP,
	setUserHistory
})

export const Home = React.memo(pipe(
  GetConnection,
  GetContent({ context: Context, id: 'welcome' })
)(HomeComponent))
