import axios from 'axios'
import uuidv4 from 'uuid/v4'

const getDeviceToken = ({
  localStorage,
  token
}) => {
  const savedToken = localStorage.getItem('deviceToken')

  if (savedToken) return savedToken
  
  const newToken = token()
  localStorage.setItem('deviceToken', newToken)

  return newToken
}

export const withAuthHeader = (module) => {
  const localStorageItem = { value: 'user' }
  const getUser = (item) => JSON.parse(localStorage.getItem(item))
  const user = getUser(localStorageItem.value)
  const accessToken = ((user && user.accessToken) || (user && user.token && user.token.accessToken))

  if (module === 'main' && accessToken) {
    const options = {
      headers: {
        Authorization: `bearer ${ accessToken }`
      }
    }

    return options
  }

  return null
}

export const apiService = ({
  localStorage,
  platformId,
  appId,
  apiUrl
}) => axios.create({
  responseType: 'json',
  baseURL: apiUrl,
  headers: {
    'Content-Type':'application/json',
    'Idioma':'pt-br',
    'Plataforma': platformId,
    'Versao':'3.12',
    'DeviceToken': getDeviceToken({ localStorage, token: uuidv4}),
    'AplicativoId': appId,
    'OneSignalPlayerId': '',
		"Agent": "Ecommerce"
  }
})

export const externalApiService = ({
  responseType,
  headers
} = {}) => axios.create({
  responseType,
  headers
})
