import { actionTypes } from '.'

const localStorageKey = 'address'

const initialState = {
	latitude: null,
	longitude: null,
	cep: null,
  address: null,
	addressByCEP: {}
}

const reducer = (state = initialState, action) => {
  const localStorageData = JSON.parse(localStorage.getItem(localStorageKey)) || {}
  const loadedState = {
    ...state,
    ...localStorageData
  }

  const newState = {
    value: loadedState
  }

  switch (action.type) {
    case actionTypes.SET_CEP:
      Object.assign(newState, {
        value: {
          ...newState.value,
          cep: action.payload
        }
      })

      break

    case actionTypes.SET_GEOLOCATION:
      Object.assign(newState, {
        value: {
          ...newState.value,
          ...action.payload
        }
      })

      break

    case actionTypes.SET_ADDRESS_BY_CEP:
      Object.assign(newState, {
        value: {
          ...newState.value,
          addressByCEP: action.payload
        }
      })

      break

    case actionTypes.SET_ADDRESS:
      Object.assign(newState, {
        value: {
          ...newState.value,
          address: action.payload
        }
      })

      break

		case actionTypes.SET_ADDRESS_POSTAL_CODE_REQUEST:
			Object.assign(newState, {
				value: {
					...newState.value,
					postalCodeRequest: action.payload
				}
			})

			break

		case actionTypes.SET_ADDRESS_REQUEST:
			Object.assign(newState, {
				value: {
					...newState.value,
					request: action.payload
				}
			})

			break

		case actionTypes.SET_NO_ADDRESSES_LIST:
			Object.assign(newState, {
				value: {
					...newState.value,
					noList: action.payload
				}
			})

			break

    default:
  }

	localStorage.setItem(localStorageKey, JSON.stringify(newState.value))

  return newState.value
}

export default reducer
