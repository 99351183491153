import React, {
  useEffect
} from 'react'
import PropTypes from 'prop-types'
import {
  connect
} from 'react-redux'

import {
  pipe
} from '../../../domain/helpers'

import {
  CategoryWrapper,
  ListWrapper,
  H2,
  Span,
  EmptyMenu,
  EmptyMenuMessage,
  EmptyMenuTitle
} from './styles'

import {
  handleGTM,
} from '../../../redux/actions/main'

import {
  Product
} from '..'

import { CustomIcon } from '../../components'

function VerticalCardMenuComponent(props) {
  const {
    storeLogo,
    handleViewProduct,
    handleAddProduct,
    cart,
    categories,
    storeImg,
    handleViewCategoriesProducts,
    setVisibleCategories,
    status,
    opened,
    // handleGTM,
    // storeName
  } = props

  useEffect(() => {
    const products = []

    if (categories && categories.length) {
      categories.forEach(function (item) {
        products.push(item.products)
      })
    }

    return () => { }
  }, [
    categories
  ])

  // useEffect(() => {
  //   if (categories && categories.length > 0) {
  //     //GTM 3.0 - Evento de Visualizar produto do carrrinho
  //     const dataGTM = {
  //       'event': `view_item_list`,
  //       'ecommerce': {
  //         'currency': 'BRL',
  //         'store': storeName,
  //         'items': categories && categories.length ? categories.map((item) => {
  //           const {
  //             products,
  //             name: categoryName,
  //           } = item

  //           return products && products.map((mappedItem, index) => {
  //             const {
  //               id,
  //               name,
  //               value,
  //               promotionalValue,
  //             } = mappedItem

  //             return {
  //               'item_name': name,
  //               'item_id': id,
  //               'price': promotionalValue ? promotionalValue : value,
  //               'discount': null,
  //               'categoria': categoryName ?? null,
  //             }
  //           })
  //         }) : null
  //       }
  //     }
  //     console.warn(dataGTM)
  //     handleGTM(dataGTM)
  //   }
  // }, [
  //   categories,
  //   handleGTM,
  //   storeName
  // ])

  function handleCategoriesProducts(name) {
    const params = {
      name,
      selectedProductsCategories: true,
      openModalCategory: true
    }

    handleViewCategoriesProducts(params)
  }

  return <>
    {categories && categories.length ? categories.map((mappedItem, index) => {
      const {
        products,
        name: categoryName,
        id: categoryId
      } = mappedItem

      const key = `stores-products-category-${index}-${categoryId}`

      return <CategoryWrapper className={`store-products-card-list`} key={key}>
        <H2>{mappedItem.name}
          <Span onClick={() => {
            handleCategoriesProducts(mappedItem.name)
            setVisibleCategories(true)
          }}>Ver tudo <CustomIcon name='AngleRight' /></Span>
        </H2>

        <ListWrapper name={mappedItem.name} id={`${mappedItem.name}`} key={key}>
          {products && products.map((mappedItem, index) => {
            const {
              id,
              name,
              description,
              image,
              value,
              additionals,
              promotionalValue,
              tags,
              showFromValueText,
              fromValue,
              loyaltyModelPointsId,
              loyaltyPoints
            } = mappedItem

            const key = `stores-products-category-group-${index}-${id}`
            const formattedPrice = `${Number(value).toFixed(2).replace('.', ',')}`
            const formattedPricePromotional = promotionalValue ? `${Number(promotionalValue).toFixed(2).replace('.', ',')}` : null
            const cartProductPrevious = cart && (cart.products && cart.products.length) ? (cart.products.filter(filteredItem => filteredItem.id === id))[0] : null
            const cartProductPreviousQty = cartProductPrevious && cartProductPrevious.quantity
            const selectedAdditionals = (cartProductPrevious && cartProductPrevious.selectedAdditionals) || []

            const requiredAdditionals = additionals && additionals.length && additionals.filter(item => item.minimum >= 1)
            const hasRequiredAdditionals = requiredAdditionals && requiredAdditionals.length ? true : false
            const formattedFromValue = fromValue ? `${Number(fromValue).toFixed(2).replace('.', ',')}` : null

            return <Product
              direction={'card'}
              key={key}
              quantity={cartProductPreviousQty}
              selectedCategory={{
                name: categoryName,
                id: categoryId
              }}
              status={status}
              handleView={handleViewProduct}
              handleAdd={handleAddProduct}
              id={id}
              name={name}
              description={description}
              image={image}
              storeLogo={storeLogo}
              value={formattedPrice}
              rawPrice={value}
              rawPromocional={promotionalValue}
              additionals={additionals}
              selectedAdditionals={selectedAdditionals}
              promotionalValue={formattedPricePromotional}
              storeImg={storeImg}
              opened={opened}
              hasRequiredAdditionals={hasRequiredAdditionals}
              tags={tags}
              showFromValueText={showFromValueText}
              fromValue={formattedFromValue}
              categorieLength={categories.length}
              loyaltyModelPointsId={loyaltyModelPointsId}
              loyaltyPoints={loyaltyPoints}
            />
          })}
        </ListWrapper>
      </CategoryWrapper>
    }) : <EmptyMenu>
      <EmptyMenuTitle>Cardápio vazio</EmptyMenuTitle>
      <EmptyMenuMessage>No momento o cardápio desta loja não está disponível, tente novamente mais tarde.</EmptyMenuMessage>
    </EmptyMenu>
    }</>
}

VerticalCardMenuComponent.propTypes = {
  storeLogo: PropTypes.string,
  selectedProduct: PropTypes.object,
  handleViewProduct: PropTypes.func,
  cart: PropTypes.shape({
    products: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  categories: PropTypes.arrayOf(PropTypes.shape({})),
  handleAddProduct: PropTypes.func,
  storeImg: PropTypes.string,
  handleViewCategoriesProducts: PropTypes.func,
  setVisibleCategories: PropTypes.func,
  status: PropTypes.number,
  opened: PropTypes.bool
}

const mapStateToProps = (state) => {
  return {
    cart: state.main.cart || {},
    categories: state.menu.categories || [],
    storeName: state.store.store && state.store.store.name,
  }
}

const GetConnection = connect(mapStateToProps, {
  handleGTM
})

export const VerticalCardMenu = React.memo(pipe(
  GetConnection
)(VerticalCardMenuComponent))
