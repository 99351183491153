const withAuthHeader = (module) => {
  const localStorageItem = { value: 'user' }

  if (module === 'main') {
    Object.assign(localStorageItem, { value: 'user'})

    const sessionUser = JSON.parse(localStorage.getItem(localStorageItem.value))

    return {
      headers: {
        Authorization: `bearer ${sessionUser && sessionUser.accessToken}`
      }
    }
  }

  const sessionUser = JSON.parse(localStorage.getItem(localStorageItem.value))   
  
  if (sessionUser) {
    const auth = `${sessionUser.token.type} ${sessionUser.token.accessToken}`
    return {
      headers: {
        Authorization: auth
      }
    }
  }

  return null
}

export default withAuthHeader
