import styled from 'styled-components'

export const Wrapper = styled.div`
  background: #fff;
  flex: 1;
  box-sizing: border-box;
  margin: 0 auto;
  position: relative;
  max-width: 100%;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  ${props => props.maxHeight ?
    `max-height: ${ props.maxHeight }px;
    min-height: ${ props.maxHeight }px;
    overflow: hidden;` :
    ``}

  * {
    font-family: 'Nunito', 'sans-serif';
  }

  > div {
    display: flex;
    min-height: 100%;

    > main {
      background: #fff;
      min-height: 100%;
      max-height: 100%;
      box-sizing: border-box;
      position: relative;

      > div {
        &.row {
          margin: 0;
					padding: 1rem;

          > div {
            &.column {
              display: flex;
              flex-direction: column;

              &.center {
                display: flex;
                align-items: center;
                justify-content: center;
              }

              @media (min-width: 60rem) {
                max-width: 60%;
                min-width: 60%;

                &.center {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  max-width: 40%;
                  min-width: 40%;
                }
              }

              > svg {
                flex: 1;
                height: unset;
                min-height: auto;
                max-height: unset;
                max-width: 30%;
                min-width: 30%;
                opacity: .3;

                path {
                  fill: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#CCC' };
                }

                @media (min-width: 60rem) {
                  max-width: 50%;
                  min-width: 50%;
                }
              }
            }
          }
        }
      }

      > img {
        height: auto;
        max-height: unset;
        max-width: 100%;
        min-height: auto;
        min-width: 100%;
        width: 100%;
        display: none;

        @media (min-width: 60rem) {
          display: unset;
        }
      }
    }
  }

  @media (min-width: 60rem) {
    > div {
      > main {
        min-height: calc(100% - 5.625rem);
        max-height: calc(100% - 5.625rem);
        margin: 0px auto;
        box-sizing: unset;

				> div {
					&.row {
						margin: 0;
						padding: 1rem 2rem;
					}
				}	
      }
    }
  }
`

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  justify-content: space-between;
  position: relative;

  * {
    box-sizing: border-box;
  }

  svg#contactUsIcon {
    margin: 3rem auto;
    flex: 1 0 100%;
    height: 10rem;
    opacity: .5;
    padding: 0 3rem 0 3rem;

    path {
      fill: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#CCC' };
    }
  }

  @media (min-width: 60rem) {
    border-bottom: 1px solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'};
    padding: 1rem 2rem 2rem 2rem;

    svg#contactUsIcon {
      margin: 0;
      flex: 0 0 30%;
      height: 100%;
    }
  }
`

export const ContentHeader = styled.div`
  border-bottom: 1px solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'};
  padding: 1.5rem 1rem;
  width: 100%;
  position: relative;

  svg {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    cursor: pointer;
  }

  @media (min-width: 60rem) {
    margin: 0 0 2rem 0;
    padding: .5rem 0;

    svg {
      display: none;
    }
  }
`

export const PageTitle = styled.h3`
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.688rem;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  text-align: center;
  margin: 0;

  @media (min-width: 60rem) {
    text-align: start;
  }
`

export const PageSubtitle = styled.p`
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.688rem;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  padding: 0 0 1rem 0;
  margin: 0;
`

export const PageParagraph = styled.p`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.688rem;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#000' };
  padding: 0 0 2rem 0;
  margin: 0;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 100%;
  max-width: 45rem;
  order: 1;
  padding: 1rem;

  @media (min-width: 60rem) {
    flex: 1 1 65%;
    padding: 0;
    order: 0;
  }
`

export const Form = styled.form`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  textarea {
    resize: none;
    overflow: hidden;
    height: 10rem;
  }
`

export const SendButton = styled.button `
  background-color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
  color: #fff;
  text-align: center;
  font-weight: 700;
  width: 100%;
  height: 3rem;
  border: 0;
  border-radius: 15px;
  cursor: pointer;
  margin: 2rem 0 0 auto;

  &:hover {
    opacity: .9;
  }

  &:disabled {
    opacity: .4;
  }

  @media (min-width: 60rem) {
    margin: 1rem 0 0 auto;
    width: 12rem;
  }
`

export const ErrorMessage = styled.span`
  font-size: .875rem;
  margin: 1rem 0 0 0;
  color: ${ props => (props.theme && props.theme.colors && props.theme.colors.error) || '#ff0000' };

  @media (min-width: 60rem) {
    margin: 0;
  }
`

export const SuccessMessage = styled.span`
  font-size: .875rem;
  margin: 1rem 0 0 0;
  color: ${ props => (props.theme && props.theme.colors && props.theme.colors.success) || '#00ff00' };

  @media (min-width: 60rem) {
    margin: 0;
  }
`

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  min-width: 100%;
`

export const BackButton = styled.button`
  margin: 0;
  padding: 0;
  background: none;
  border: 0 none;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  font-size: 1rem;
  position: absolute;
  cursor: pointer;
  left: 1.25rem;
  top: 1.25rem;
  z-index: 1;

  @media (min-width: 60rem) {
    display: none;
  }
`

export const H1 = styled.h1`
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  margin: 0;
  padding: 1rem 0;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: bold;
  min-width: 100%;
  text-transform: none;
  text-align: center;
  border-bottom: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#000' } .063rem solid;
  padding-bottom: 1.25rem;

  @media (min-width: 60rem) {
    text-align: left;
    font-size: 1.25rem;
    text-transform: uppercase;
    padding: 1rem 0;
    margin: 1.5rem 2rem 0 2rem;
    min-width: calc(100% - 4rem);
  }
`

export const Row = styled.div.attrs({
  className: 'row'
})`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: 40rem) {
    flex-direction: row;
  }
`

export const Column = styled.div.attrs({
  className: 'column'
})`
  flex: 1;
  min-width: 100%;
  margin-bottom: 2rem;

  @media (min-width: 40rem) {
    min-width: unset;
    margin-bottom: 0;
  }
`

export const Message = styled.div`
  padding: .5rem .85rem;
  margin: 1.5rem 1.5rem 0 1.5rem; 
  background-color: #f4f4f4;
  min-height: 3rem;
  max-height: 7rem;
  box-sizing: border-box;
  border-radius: 1.15rem;
  border: 1px solid #dedede;
  color: #b9babc;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1rem;

  span {
    max-width: 100%;
    position: relative;
    max-height: calc(1rem * 3);
    overflow: hidden;
    padding-right: .5rem;
    text-overflow: ellipsis;
  }

  &.success{
    svg {
      width: 1.5rem;
      height: 1.5rem;
      min-width: 1.5rem;
      min-height: 1.5rem;
      background: #31bf6e;
      border-radius: 50%;
      padding: .25rem;
      box-sizing: border-box;
      margin: 0 0 0 1rem;
    }
  }

  &.error{
    svg {
      width: 1.5rem;
      height: 1.5rem;
      min-width: 1.5rem;
      min-height: 1.5rem;
      background: #fd5b5d;
      border-radius: 50%;
      padding: .25rem;
      box-sizing: border-box;
      margin: 0 0 0 1rem;
    }
  }
  
`
