
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import { TextField } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  root: {
    minWidth: '100%',
    "& .MuiOutlinedInput-root": {
      borderRadius: '1rem',
      height: '2.5rem',
    },
    "& .MuiFormLabel-root": {
      fontSize: '12px',
      transform: 'translate(14px, 14px) scale(1)'
    },
    "& .MuiInputBase-root": {
      fontSize: '12px',
      color: '#AEAEAE',
    }
  },
  filledRoot: {
    minWidth: '100%',
    "& .MuiOutlinedInput-root": {
      background: 'none',
      borderRadius: '1rem',
      height: '2.5rem',
    },
    "& .MuiFormLabel-root": {
      fontSize: '12px'
    },
    "& .MuiInputBase-root": {
      fontSize: '12px',
      color: '#AEAEAE',
    },
    "& .MuiSelect-select:focus":{
      background: 'none',
    }
  },
  input: {
    color: '#AEAEAE',
    fontSize: '12px',
    "& .MuiMenuItem-root": {
      fontSize: '12px'
    }
  },
}))

export function CustomSelect({
  id,
  variant,
  options,
  selectedOption,
  setSelectOption,
  handleCategories,
  all
}) {
  const classes = useStyles()

  const handleChange = (event) => {
    setSelectOption(event.target.value)
    handleCategories(event.target.value)
  }

  return (
    <div className={`select-${id}-wrapper`}>
      <TextField
        id={`select-${id}`}
        select
        className={selectedOption ? classes.filledRoot : classes.root}
        value={selectedOption}
        onChange={handleChange}
        InputLabelProps={{ shrink: false, disableAnimation: true }}
        SelectProps={{
          MenuProps: {
            className: classes.input,
          },
        }}
        variant="outlined"
        focused={false}
        label={selectedOption ? '' : 'Escolha uma categoria'}
      >
        <MenuItem
          key={all ? all.toLowerCase() : ''}
          value='all'
        >
          {all}
        </MenuItem>
        {options.map(option => (
          <MenuItem key={option.id} value={option.name}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>
    </div>
  )
}
