import styled from 'styled-components'

export const Wrapper = styled.div`
	font-family: ${props => props.theme.newFontFamily};
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	box-sizing: border-box;
	margin: 0 auto;
	min-width: 100%;
	max-width: 100%;
	padding-top: 1.875rem;
	flex: 1;
`

export const ListWrapper = styled.ul`
  list-style: inside none;
  margin: 0;
  padding: 0;
  min-width: 100%;
  flex: 0;
  display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
`

export const ListItemWrapper = styled.li`
  background: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textFifthColor) || '#CCC' };
  border: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#000' } .063rem solid;
  border-radius: .625rem;
  margin-bottom: 1.25rem;
  flex: 1;
  min-width: 100%;
  display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;

  &:last-child {
    margin-bottom: 0;
  }

  &.selected {
    background: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
    border: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textFifthColor) || '#CCC' } .063rem solid;
    color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.invertedPrimaryColor) || '#fff' };

    > button {
      color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.invertedPrimaryColor) || '#fff' };
    }
  }

  > button {
    flex: 1;
    min-width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    > img {
      margin-right: .625rem;
      height: 1.25rem;
      width: auto;
    }
  }
`

export const Image = styled.img``

export const Paragraph = styled.p`
  margin: 0;
  padding: 0;
`

export const Span = styled.span``

export const Button = styled.button`
  background: none;
  border: 0;
  padding: .625rem;
  margin: 0;
`

export const H1 = styled.h1`
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.invertedPrimaryColor) || '#333' };
  font-size: 1.25rem;
  line-height: 1.25rem;
  padding: 0;
  margin: 0;
	margin-bottom: 1.25rem;
  min-width: 100%;
`
