import styled from 'styled-components'

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border: #eee .063rem solid;
  padding: 1.25rem;
  margin: 1.25rem;
`

export const H4 = styled.h4``
export const Div = styled.div`
  overflow-wrap: anywhere;
`
export const Paragraph = styled.p``
export const Strong = styled.strong``
