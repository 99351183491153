import React, {
  useEffect
} from 'react'
import styled from 'styled-components'

import {
  CustomIcon
} from '../../components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  min-width: 100%;
  max-width: 100%;
  position: relative;
  padding: 0 1.25rem;

  > button {
    color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textSecondaryColor) || '#333' };
    position: absolute;
    right: 1.25rem;
    top: 0;
    padding: 0.625rem;
  }
`
const Input = styled.input`
  background: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textFifthColor) || '#E2E2E2' };
  height: 2.5rem;
  line-height: 2.5rem;
  font-size: 0.875rem;
  border: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#DDD' } 0.063rem solid;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textSecondaryColor) || '#333' };
  padding: 0 0.938rem;
  border-radius: 0.625rem;
  flex: 1;
`

const Button = styled.button`
  margin: 0;
  padding: 0;
  background: none;
  border: 0 none;
`

export function Search(props) {
  const {
    handleSearch,
    handleSearchText,
    searchText
  } = props

  useEffect(() => {
    return () => {}
  }, [])

  return <Wrapper id='store-search-category'>
    <Input placeholder='Buscar algo' value={ searchText } onKeyDown={ (event) => {
      const {
        keyCode
      } = event
      
      if (keyCode === 13) {
        handleSearch()
      }
    } } onChange={ (event) => {
      const {
        value
      } = event.target

      handleSearchText(value)
    }} />

    <Button onClick={ () => {
      handleSearch()
    } }>
      <CustomIcon name='Search' />
    </Button>
  </Wrapper>
}
