import styled from 'styled-components'

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  min-width: 100%;
`

export const Wrapper = styled.div`
  background: #fff;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  margin: 0;
  max-width: 100%;
  min-width: 100%;
  padding: 0;
  animation: slideUp 1s;
  animation-fill-mode: forwards;
  top: 0;
  position: relative;

  * {
    font-family: 'Nunito', 'sans-serif';
  }

  &.slide-down {
    animation: slideDown 1s;

    > div {
      > div {
        &.fixed {
          animation: finishButtonFadeOut .5s;
        }
      }
    }
  }

  > div {
    &#cart-list {
      border-bottom: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'} .063rem solid;
      margin-bottom: 1.25rem;
      flex: none;
    }

    > div {
      &.fixed {
        background: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#FF0'};
        padding: 1.25rem;
        position: fixed;
        top: calc(100% - 6.875rem);
        left: 0;
        width: 100%;
        box-shadow: 0 0 .625rem ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#999'};
        transform: translate(0, 0);
        animation: finishButtonFadeIn .5s;
        animation-delay: 1s;
        animation-fill-mode: forwards;
        opacity: 0;
        z-index: 1000;
      }
    }
  }

  @keyframes finishButtonFadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  @keyframes finishButtonFadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }
`

export const AddressWrapper = styled.div`
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.invertedPrimaryColor) || '#333'};
  display: flex;
  flex-direction: row;
  padding: 1.25rem;
  flex: 0;
  min-width: 100%;
  justify-content: flex-start;
  align-items: center;
  border-bottom: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.dividerColor) || '#CCC'} .063rem solid;

  > p {
		color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.invertedPrimaryColor) || '#333'};
    margin-right: .625rem;
    font-size: .938rem;
    line-height: 1.25rem;
  }

  > div {
    margin-right: .625rem;
    color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.invertedPrimaryColor) || '#000'};

    > svg {
      width: 1rem;
      height: 1rem;

      * {
        fill: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.invertedPrimaryColor) || '#000'};
      }
    }
  }
`

export const Paragraph = styled.p`
	color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
  margin: 0;
  padding: 0;
`

export const H1 = styled.h1`
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000'};
  margin: 0;
  padding: 1.25rem;
  font-size: 1.2rem;
  line-height: 1.2rem;
  font-weight: bold;
  margin-bottom: 1.25rem;
  text-align: center;
  border-bottom: #d2d2d2 .063rem solid;
`

export const CloseButton = styled.button`
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000'};
  background: none;
  border: 0 none;
  padding: 0;
  margin: 0;
`

export const ContentWrapper = styled.div`
  opacity: 0;
  min-width: 100%;
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  &.motion {
    opacity: 1;
  }

  > div {
		&.notification {
			@media (min-width: 40rem) {
				position: absolute;
				left: 1rem;
				top: 1rem;
				min-width: calc(100% - 2rem);
				right: 1rem;
				z-index: 3;
			}
		}

    &#cart-address {
      padding: 1.25rem;
      border-bottom:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#333'} .063rem solid;
      display: flex;
      flex-direction: column;
      min-width: calc(100% - 2.5rem);
      
    }
  }
`

export const LoadingImage = styled.img`
  width: 6.25rem;
  height: auto;
`

export const Div = styled.div``

export const H3 = styled.h3`
  font-size: 1.125rem;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000'};
  margin: 0;
  padding: 0;
  margin-bottom: .1rem;
  min-width: 100%;
  display: flex;
  align-items: center;

  > button {
    display: flex;
    align-items: center;
  }
`

export const OverlayWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 3;
  opacity: 0;

  &.motion {
    opacity: 1;
    pointer-events: unset;
  }

  > div:not(.background) {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0);
    z-index: 2;
  }

  > div.background {
    background: rgba(150, 150, 150, .5);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
`

export const CartHeaderWrapper = styled.header`
  width: 100%;

  > button {
    &.close {
      background: none;
      padding: 0;
      margin: 0;
      position: absolute;
      right: 1.25rem;
      top: 1.25rem;
      font-size: 1.25rem;
      font-weight: 100;
    }
  }

  @media (min-width: 50rem) {
    display: none;
  }
`

export const CartFooterWrapper = styled.footer`
  display: flex;
  flex-direction: column;
  padding: 0;
	padding-bottom: .5rem;
  font-size: 1.2rem;

  > div {
    svg {
      width: .65rem;
      margin: 0 .5rem 0 0;
      height: 1rem;
      box-sizing: border-box;
  
      path {
        fill: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
      }
    }

    > p{
      color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000'};
    }
    
    &#cart-address {
      padding: 1rem 1.5rem;
      border-bottom: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'} .063rem solid;
      display: flex;
      flex-direction: row;
      min-width: calc(100% - 1.5rem); 
      margin-bottom: 1rem;

      > div {
				&.content-pickup{
          width: 100%;
          > p{
            color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000'};
          }
        }

        &.content{
          width: 80%;
          > p{
            color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000'};
          }
        }
        
        &.edit{
          display: flex;
          flex-direction: column;
          justify-content: center;

          svg {
            width: 1rem;
          }
        }
      }
      
      > p {
        display: flex;
        align-items: center;

        > svg {
          height: 35px;
          top: -2px;
          position: relative;
        }
      }
    }

    &#cart-scheduling-select {
      padding: .8rem 1.5rem;
      border-bottom: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'} .063rem solid;
      display: flex;
      flex-direction: row;
      margin-bottom: 1rem;
      justify-content: space-between;

      >h4{
        font-size: 1.125rem;
        color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000'};
      }
      
      > p {
        display: flex;
        align-items: center;
        color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
        font-weight: bold;

        > svg {
          height: 30px;
          top: -5px;
          position: relative;
        }
      }

      @media(min-width:60rem){
        display:none;
      }
    }

    &#cart-scheduling {
      padding: 1rem 1.5rem;
      border-bottom: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'} .063rem solid;
      display: flex;
      flex-direction: row;
      min-width: calc(100% - 1.5rem); 
      margin-bottom: 1rem;
      
      > div {
        &.content{
          width: 80%;
          > p{
            color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000'};
          }
        }

        &.edit{
          display: flex;
          flex-direction: column;
          justify-content: end;
          
          svg {
            width: 1rem;
          }
        }
      }

      > p {
        display: flex;
        align-items: center;

        > svg {
          height: 30px;
          top: -5px;
          position: relative;
        }
      }

      @media(min-width:60rem){
        display:none;
      }
    }

    &.cart-cards {
      padding: 1.25rem;
      border-bottom: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'} .063rem solid;
      display: flex;
      flex-direction: column;
      
      > p{
        color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
        text-decoration: underline;
        text-underline-offset: 2px;
        text-decoration-thickness: 1.5px;
        text-align: center;
        cursor: pointer;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;

        > button {
          display: flex;
          align-items: center;
          justify-content: center;
          max-height: 2rem;
          min-height: 2rem;

          > svg {
            width: 1rem;
            margin: 0;
          }
        }
      }
    }
  }

  #finish-purchase-button {
    margin-top: 1.5rem;
  }
`

export const ButtonIcon = styled.button`
  display: flex;
  align-items: center;
  background: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
  justify-content: center;
  color: #fff;
  padding: 1.5rem 1rem;
  border-radius: 15px;
  height: 3.5rem;
  position: relative;
  cursor: pointer;
  margin: 1rem;
  min-width: calc(100% - 2rem);
  max-width: calc(100% - 2rem);

  &:disabled {
    opacity: .5;
    cursor: default;
  }

  svg {
    width: 1.5rem;
  }

  @media (min-width: 40rem) {
    margin: 0rem;
    min-width: 100%;
    max-width: 100%;
  }
`

export const ButtonIconLabel = styled.span`
  font-weight: 700;
  font-size: .875rem;
  line-height: 1.25rem;
  margin: 0 2rem;

  @media (min-width: 60rem) {
    margin: 0;
  }
`

export const IconButtonContainer = styled.div`
  top: 50%;
  position: absolute;
  left: 1rem;
  margin: 0 .5rem 0 0;
  transform: translate(0, -50%);
`

export const IconButtonCartsItemsAmount = styled.span`
  border: 2px solid #F9F9F9;
  border-radius: 50%;
  width: .1rem;
  height: .1rem;
  padding: .35rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -5px;
  right: -15px;
  font-size: .55rem;
`

export const Divider = styled.div`
  border-bottom: 1px solid #F9F9F9;
`

export const EditButton = styled.button`
  background-color: transparent;
  cursor: pointer;
  min-height: 30px;
  max-height: 30px;
  //font-size: 1.5rem
`

export const ContainerCoupon = styled.div`
  background: #fff;
  text-align: start;
  margin-bottom: 1rem;

  @media(min-width: 60rem){
    display:none;
  }
`

export const BoxFreeShipping = styled.div`
	font-family: 'Roboto';
	display: inline-block;
	flex-direction: row;
	color: #737373;
	border-radius: 4px;
	background: #fff;
	font-size: 13px;
	padding: 2px 2px;
	margin-bottom: 30px;
	padding: 8px;
	font-weight: bold;
	display: flex;
	gap: 5px;
	align-items: center;
	position: relative;
	
	svg {
		width: 22px !important;
		height: 17px !important;
		margin-right: 5px !important;	

		path {
			fill: #007141 !important;
		}
	}	

	p {
			margin: 0px;
			b {
				color: #007141;
			}
		}

	&::before {
		content: '';
		display: block;
		width: 95%;
		height: 4px;
		background-color: #D9D9D9;
		position: absolute;
		bottom: -5px;
		border-radius: 4px;
	}


	&::after {
		content: '';
		display: block;
		width: ${props => props.percent < 100 ? props.percent - 5 :  95 }%;
		height: 4px;
		background-color: #007141;
		position: absolute;
		bottom: -5px;
		transition: width 0.5s ease;
		border-radius: 4px;
	}
`;

