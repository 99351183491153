import styled from 'styled-components'

export const Wrapper = styled.div`
	background: #fff;
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	box-sizing: border-box;
	margin: 0 auto;
	border-radius: .938rem;
	padding: 1.25rem;
	min-width: 100%;

	> p {
		margin: 0;
		margin-bottom: 1.25rem;

		&:last-child {
			margin: 0;
		}
	}
`

export const H1 = styled.h1`
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  font-size: 1.25rem;
  padding: 0;
  margin: 0;
	margin-bottom: 1.25rem;
	flex: 1;
	text-align: center;
`

export const Button = styled.button`
	color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.invertedPrimaryColor) || '#fff' };
  background: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
  border-radius: .938rem;
  font-size: .875rem;
  font-weight: bold;
  flex: 1;
  max-height: 3.75rem;
  min-height: 3.75rem;
  line-height: 3.75rem;
  min-width: 100%;
  padding: 0;
  margin: 0;
  font-size: 1rem;
  box-sizing: border-box;
`

export const Input = styled.input`
	border: 0 none;
	border-bottom: #E1E1E1 .063rem solid;
	font-size: 1.125rem;
	text-align: center;
	height: 1.875rem;
	line-height: 1.875rem;
	color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#000' };
	margin: 0 auto;
	flex: 0;
	margin-bottom: 1.25rem;
	min-width: 50%;
`
