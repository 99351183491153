import React from 'react'
import styled from 'styled-components'
import {
  FontAwesomeIcon
} from '@fortawesome/react-fontawesome'
import {
  faHome,
  faArrowLeft,
  faArrowRight,
  faTrash,
  faTrashAlt,
  faPen,
  faInfo,
  faEllipsisV,
  faHeart,
  faBriefcase,
  faTimes,
  faCalendar,
  faMinus,
  faPlus,
  faAngleDown,
  faAngleRight,
  faAngleLeft,
  faEdit,
  faClock,
  faSearch,
  faPhone,
  faCommentDots,
  faGift,
	faTimesCircle
} from '@fortawesome/free-solid-svg-icons'

const Span = styled.span``

export function CustomIcon(props) {
  const {
    name,
    className
  } = props

  const icons = {
    faHome,
    faArrowLeft,
    faArrowRight,
    faTrash,
    faTrashAlt,
    faPen,
    faInfo,
    faEllipsisV,
    faHeart,
    faBriefcase,
    faTimes,
		faTimesCircle,
    faCalendar,
    faMinus,
    faPlus,
    faAngleDown,
    faAngleRight,
    faAngleLeft,
    faEdit,
    faClock,
    faSearch,
    faPhone,
    faCommentDots,
    faGift
  }

  const iconName = icons[`fa${ name }`]
  
  return <Span className={`${ className } custom-icon`}>
    <FontAwesomeIcon icon={ iconName } />
  </Span>
}
