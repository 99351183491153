import styled from 'styled-components'

import {
  Button as DefaultButton
} from '../../components'

export const Wrapper = styled.div`
  display : flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 1rem;
  box-sizing: border-box;
  background: #fff;
  border-radius: 1rem;
  bottom: 0 !important;
  top: auto !important;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#000' };
`

export const H2 = styled.h2`
  font-size: 1.25rem;
  font-weight: bold;
  color: ${props => props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor };
  text-align: left;
  margin: 0 .8rem;
  padding: 0;
`

export const H3 = styled.h3`
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: bold;
  color: ${props => props.theme.main && props.theme.main.general && props.theme.main.general.dark };
  text-align: left;
  min-width: 100%;
  margin: 0;
  padding: 0;
`

export const Button = styled(DefaultButton)`
  flex: 0;
  max-height: 3rem;
  min-height: 3rem;
  min-width: 100%;
  max-width: 100%;
`

export const Paragraph = styled.div`
  color: ${props => props.theme.main && props.theme.main.general && props.theme.main.general.blue01Color };
  min-width: 100%;
  max-width: 100%;
  text-align: left;
  font-size: 1rem;
  margin: 0;
  padding: 0;

  &.quick-description {
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  &.description {
    font-size: .8rem;
    margin-bottom: 1rem;
  }

  &.created-at {
    font-size: .8rem;
  }

  &.expires-at {
    font-size: .8rem;
    margin-bottom: 1rem;
  }

	&.expired {
    font-size: .8rem;
    margin-bottom: 1rem;
		color: ${props => props.theme.main && props.theme.main.general && props.theme.main.general.red02 };
  }

  &.highlight {
    font-size: 1.4rem;
    color: ${props => props.theme.main && props.theme.main.general && props.theme.main.general.blue06Color };
    font-weight: bold;
  }
`

export const Title = styled.div`
  display : flex;
  flex: 1;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;

  svg {
    width: 1.425rem;
    height: 1.425rem;
    align-self: center;
    cursor: pointer;

    g {
      stroke: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
    }

    path {
      fill: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
    }
  }
`

export const CloseButton = styled.button`
  padding: 1rem;
  background: none;
  border: 0 none;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#000'};
  text-align: end;
  position: absolute;
	right: 0;
	top: 0;
	font-size: 1.5rem;
	z-index: 1;

	@media (min-width: 40rem) {
		right: -1rem;
		top: -3.5rem;
	}

  @media (max-width: 40rem) {
		display: none;
	}
`
