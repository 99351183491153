import { actionTypes } from '.'

const localStorageKey = 'menu'

const initialState = {
	categories: null,
	allCategories: null,
	selectedProduct: null,
	searchTerm: null
}

const reducer = (state = initialState, action) => {
	const localStorageData = JSON.parse(localStorage.getItem(localStorageKey)) || {}
	const loadedState = {
		...state,
		...localStorageData
	}

	const newState = {
		value: loadedState
	}

	const {
		payload,
		type
	} = action

	switch (type) {
		case actionTypes.SET_MENU:
			Object.assign(newState, {
				value: {
					...newState.value,
					allCategories: payload,
					categories: payload
				}
			})

			break

		case actionTypes.SET_MENU_CATEGORIES:
			Object.assign(newState, {
				value: {
					...newState.value,
					categories: payload
				}
			})

			break

		case actionTypes.SET_SEARCH_TERM:
			Object.assign(newState, {
				value: {
					...newState.value,
					searchTerm: payload
				}
			})

			break

		case actionTypes.SET_SELECTED_PRODUCT:
			if (!action.payload) {
				Object.assign(newState, {
					value: {
						...newState.value,
						selectedProduct: null
					}
				})
			} else {
				const categories = newState.value.categories && newState.value.categories.filter(filteredItem => filteredItem.name === action.payload.category.name)
				const category = categories[0]
				const products = category.products && category.products.filter(filteredItem => filteredItem.id === action.payload.id)
				const product = products && products[0]

				const newProduct = {
					...product,
					...action.payload
				}

				Object.assign(newState, {
					value: {
						...newState.value,
						selectedProduct: newProduct
					}
				})
			}

			break

		case actionTypes.SET_PRODUCT_ADDITIONALS:
			const product = newState.value.selectedProduct ? newState.value.selectedProduct : []
			const accomps = newState.value.selectedProduct && newState.value.selectedProduct.accompaniments ? newState.value.selectedProduct.accompaniments : []
			const {
				code: selectedAccompanimentId,
				quantity: quantityIncrementB,
			} = action.payload

			const newAccompaniments = { value: accomps }

			if (!quantityIncrementB) {
				Object.assign(newAccompaniments, {
					value: accomps.filter((filteredItem) => (filteredItem.id !== selectedAccompanimentId))
				})
			}

			if (quantityIncrementB) {
				Object.assign(newAccompaniments, {
					value: accomps.map((mappedItem) => {
						const extras = mappedItem.Itens
						return {
							...mappedItem,
							Itens: extras.map((item) => {
								const { Codigo, Quantidade } = item

								if (Codigo === selectedAccompanimentId) {
									const newQuantity = Quantidade + quantityIncrementB
									if (newQuantity === 0) {
										return {
											...item,
											Quantidade: newQuantity,
											selectedAccompaniment: false
										}
									}

									return {
										...item,
										Quantidade: newQuantity,
										selectedAccompaniment: true
									}
								}

								return {
									...item,
									selectedAccompaniment: false
								}
							})
						}
					})
				})
			}

			Object.assign(newState, {
				value: {
					...newState.value,
					store: {
						...newState.value.store,
						selectedProduct: {
							...product,
							accompaniments: newAccompaniments.value
						}
					}
				}
			})

			break

		case actionTypes.REMOVE_SELECTED_PRODUCT_ADDITIONAL:
			const selectedAdditionals = (newState.value && newState.value.selectedProduct && newState.value.selectedProduct.selectedAdditionals && newState.value.selectedProduct.selectedAdditionals.length && newState.value.selectedProduct.selectedAdditionals.map((mappedItem, index) => {
				if (mappedItem.groupId === action.payload.groupId && mappedItem.calcType === action.payload.calcType) {
					const newItems = mappedItem && mappedItem.items && mappedItem.items.filter(filteredItem => filteredItem.code !== action.payload.code)

					const newMappedItem = {
						...mappedItem,
						items: newItems
					}

					return newMappedItem
				}

				return mappedItem
			})) || []

			Object.assign(newState, {
				value: {
					...newState.value,
					selectedProduct: {
						...newState.value.selectedProduct,
						selectedAdditionals
					}
				}
			})

			break


		default:
	}

	localStorage.setItem(localStorageKey, JSON.stringify(newState.value))

	return newState.value
}

export default reducer
