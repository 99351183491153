import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  padding: 1.25rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > div {
    flex: 1;

    &.header {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 12.5rem;
      flex: 0;
      min-width: 100%;
      max-width: 100%;

      svg {
        opacity: .2;

        g {
          path {
            fill:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
          }
        }
        path {
          fill:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
        }
        g {
          stroke:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
        }
        path {
          stroke:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
        }
      }
    }

    &.content {
      text-align: center;
      margin: 0 0 2rem 0;

      > h2 {
        color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
        margin: .6rem 0;

        @media (max-width: 60rem) {
          font-size: 1.2rem;
          margin: 0 1rem;
        }
      }

      > p {
        color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#000' };
        font-size: 1rem;

        @media (max-width: 60rem) {
          font-size: .9rem;
          margin: 0 1rem;
        }
      }

      > button {
        max-width: 14rem;
        max-height: 2.5rem;
        border-radius: 8px;
        font-size: .875rem;
        padding: .8rem 3rem;
        margin: 2rem 0;

        @media(max-width: 60rem){
          margin: 2rem 1rem;
          padding: 1rem 3rem;
          max-height: 3rem;
          position: fixed;
          bottom: 0;
          left: 0;
          max-width: 90%;
          min-width: 90%;
        }
      }
    }

    &.footer {
      max-width: 100%;
      min-width: 100%;
      min-height: 4.375rem;
      max-height: 4.375rem;
    }
  }
`

export const PageTitle = styled.h2`
  font-weight: 700;
  font-size: .8rem;
  line-height: 1rem;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  border-bottom: .063rem solid ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#000' };
  padding: 1.5rem 0 .5rem 0;
  margin: 0 1rem 1rem;
  text-transform: uppercase;
  width: 100%;

  @media (min-width: 60rem) {
    font-size: 1.25rem;
    line-height: 1.688rem;
    padding: 2.25rem 0 .5rem 0;
    margin: 0 2rem 2rem;
  }

  @media (max-width: 60rem) {
    display: none;
  }
`
