import React, {
	useEffect,
	useState
} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Dialog, Slide } from '@material-ui/core'
import { Helmet } from 'react-helmet'

import history from '../../history'

import {
	useStoreSettings,
	useDeviceLayout,
	useLayout,
	useSidebar
} from '../hooks'

import {
	handleGTM,
	logout,
	setModality
} from '../../redux/actions/main'

import { cart } from '../../state'

import {
	pipe,
	GetContent,
	caculateProductsQuantity,
} from '../../domain/helpers'

import {
	MainHeader
} from '../main-header'

import {
	PageContainerSide,
	PageContainer,
	PageContainerHeader,
	PageContainerMain,
	Cart,
	CartContainer,
	Navigation,
	Skeleton
} from '..'

import { Header } from '../store/Header'

import { Infos } from './Infos'

import {
	Wrapper,
	LoadingWrapper,
} from './styles'

export const Context = React.createContext({})

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})

export function StoreInfoPage(props) {
	const {
		accessToken,
		loading,
		theme,
		storeName,
		storeImg,
		storeStatus,
		modalityId,
		storeContact,
		openingHours,
		onlineFlags,
		presentialFlags,
		userName,
		address,
		addressDelivery,
		cartProducts,
		setModality,
		URLParameters
	} = props

	const storeLogo = theme.logoImage
	const storeHeaderImage = theme.headerImage
	const storeHeaderImageMobile = theme.headerImageMobile

	const [visibleSignIn, setVisibleSignIn] = useState(false)
	const [viewCards, setViewCards] = useState(false)
	const [fullWidth, setFullWidth] = useState(true)
	const [fullScreen, setFullScreen] = useState(false)
	const [visibleNavigation, setVisibleNavigation] = useState(false)

	const {
		layoutMode,
		layoutModeEnum,
		handleLayoutMode
	} = useLayout()

	const {
		sidebarContent,
		sidebarOpened,
		sidebarContentEnum,
		handleSidebarContent,
		handleSidebarOpened
	} = useSidebar()

	const {
		url,
		title,
		description
	} = useStoreSettings()

	const isMobile = useDeviceLayout({
		isMobile: true
	})

	useEffect(() => {
		setFullWidth(true)

		if (isMobile) {
			setFullScreen(true)
		}
	}, [
		isMobile
	])

	async function handleModality(id) {
		setModality({ id })
	}

	function handleContactUs() {
		history.push(`/contact-us${URLParameters}`)
	}

	function handleCloseDialogNavigation() {
		setVisibleNavigation(false)
	}

	return (
		<Wrapper id={'store-info'}>
			<Helmet>
				<title>{`${title} - Informações da loja`}</title>
				<meta name='description' content={description} />
				<link rel='canonical' href={`${url}/store-info`} />
			</Helmet>

			{loading.includes('store-info') && <LoadingWrapper id='store-info-loading'>
				<Skeleton module='store' />
			</LoadingWrapper>}

			{!loading.includes('store-info') && <PageContainer
				isSideOpen={sidebarOpened}
				mode={layoutMode}
			>
				<PageContainerHeader>
					<MainHeader
						mode={isMobile ? 'mobile' : 'full'}
						theme={theme}
						storeLogo={storeLogo}
						name={storeName}
						storeImg={storeImg}
						userName={userName}
						accessToken={accessToken}
						setVisibleSignIn={setVisibleSignIn}
						visibleSignIn={visibleSignIn}
						handleModality={handleModality}
						modality={modalityId}
						addressDelivery={address ? address : addressDelivery}
						cartItemsQuantity={caculateProductsQuantity(cartProducts)}
						homeOnClick={() => {
							handleLayoutMode(layoutModeEnum.default)
							handleSidebarContent(sidebarContentEnum.default)
							handleSidebarOpened(false)
							setViewCards(false)
						}}
						userClicked={() => {
							if (sidebarOpened && sidebarContent === sidebarContentEnum.nav) {
								handleLayoutMode(layoutModeEnum.default)
								handleSidebarContent(sidebarContentEnum.default)
								handleSidebarOpened(false)
							} else {
								handleLayoutMode(layoutModeEnum.headerFullContent)
								handleSidebarContent(sidebarContentEnum.nav)
								handleSidebarOpened(true)
							}
						}}
						cartClicked={() => {
							if (sidebarOpened && sidebarContent === sidebarContentEnum.cart) {
								handleLayoutMode(layoutModeEnum.default)
								handleSidebarContent(sidebarContentEnum.default)
								handleSidebarOpened(false)
							} else {
								handleLayoutMode(layoutModeEnum.sideFullContent)
								handleSidebarContent(sidebarContentEnum.cart)
								handleSidebarOpened(true)
							}
						}}
						isSidebarOpen={sidebarOpened}
					/>
				</PageContainerHeader>
				<PageContainerMain opened={sidebarOpened}>
					<>
						<Header
							handleModality={handleModality}
							status={storeStatus}
							image={storeHeaderImage}
							imageMobile={storeHeaderImageMobile}
							setVisibleNavigation={setVisibleNavigation}
							isMenu={false}
						/>

						<Infos
							storeContact={storeContact}
							openingHours={openingHours}
							onlineFlags={onlineFlags}
							presentialFlags={presentialFlags}
						/>
					</>
				</PageContainerMain>
				<PageContainerSide opened={sidebarOpened}>
					{!isMobile && layoutMode === layoutModeEnum.sideFullContent &&
						<CartContainer logo={storeLogo}>
							<Cart
								mode='simple'
								screenMobile={isMobile}
								setViewCards={setViewCards}
								viewCards={viewCards}
								handleFinish={() => { }}
								handleClose={() => { }}
							/>
						</CartContainer>}

					{layoutMode === layoutModeEnum.headerFullContent && <Navigation
						contactUs={() => handleContactUs()}
						userName={userName}
						currentRoute='contact-us'
					/>}
				</PageContainerSide>

				{isMobile && <Dialog
					onClose={() => handleCloseDialogNavigation()}
					aria-labelledby="navigation-dialog"
					open={visibleNavigation}
					maxWidth={'xs'}
					fullWidth={fullWidth}
					fullScreen={fullScreen}
					TransitionComponent={Transition}
				>
					<Navigation
						contactUs={() => handleContactUs()}
						userName={userName}
						currentRoute='store'
						userClicked={() => {
							handleLayoutMode(layoutModeEnum.headerFullContent)
							handleSidebarContent(sidebarContentEnum.cart)
							handleSidebarOpened(true)
						}}
						setVisibleNavigation={setVisibleNavigation}
					></Navigation>
				</Dialog>}
			</PageContainer>}
		</Wrapper>
	)
}

const mapStateToProps = (state) => {
	return {
		accessToken: state.user.accessToken,
		addressDelivery: state.address.addressByCEP || '',
		cartProducts: (state.main.cart && state.main.cart.products) || [],
		loading: state.loading.loading || [],
		registeredCoupons: state.main.coupons || { coupons: [] },
		address: state.address.address || '',
		userAddresses: state.userAddress.userAddresses || [],
		userHistory: state.main.userHistory || {},
		userName: state.user.name || '',
		storeImg: (state.store.store && state.store.store.logo) || '',
		modalityId: state.main.modality && state.main.modality.id,
		storeName: state.store.store && state.store.store.name,
		storeId: state.main.store && state.main.store.id,
		storeStatus: state.store.store && state.store.store.status,
		deliveryFee: state.store.store && state.store.store.deliveryFee,
		deliveryMinimumValue: state.store.store && state.store.store.deliveryMinimumValue,
		pickupMinimumValue: state.store.store && state.store.store.pickupMinimumValue,
		deliveryTime: state.store.store && state.store.store.deliveryTime,
		storeContact: state.store.store && state.store.store.contact,
		openingHours: state.store.store && state.store.store.openingHours,
		onlineFlags: state.store.store && state.store.store.onlineFlags,
		presentialFlags: state.store.store && state.store.store.presentialFlags,
		storePickup: (state.store.store && state.store.store.pickup) || null,
		storeDelivery: (state.store.store && state.store.store.delivery) || null,
		scheduling: state.store.store && state.store.store.scheduling,
		URLParameters: (state.main.URLParameters) || ''
	}
}

StoreInfoPage.propTypes = {
	accessToken: PropTypes.string,
	loading: PropTypes.arrayOf(PropTypes.shape({})),
	theme: PropTypes.shape({
		headerImage: PropTypes.string,
		logoImage: PropTypes.string,
		headerImageMobile: PropTypes.string
	}),
	storeName: PropTypes.string,
	storeImg: PropTypes.string,
	storeId: PropTypes.number,
	storeStatus: PropTypes.number,
	modalityId: PropTypes.number,
	deliveryFee: PropTypes.number,
	deliveryMinimumValue: PropTypes.number,
	headerBackgroundImage: PropTypes.string,
	pickupMinimumValue: PropTypes.string,
	deliveryTime: PropTypes.string,
	storeContact: PropTypes.string,
	openingHours: PropTypes.string,
	onlineFlags: PropTypes.arrayOf(PropTypes.shape({})),
	presentialFlags: PropTypes.arrayOf(PropTypes.shape({})),
	userName: PropTypes.string,
	address: PropTypes.shape({}),
	addressDelivery: PropTypes.string,
	cartProducts: PropTypes.arrayOf(PropTypes.shape({})),
	setModality: PropTypes.func,
	storeDelivery: PropTypes.bool,
	storePickup: PropTypes.bool,
	scheduling: PropTypes.bool,
	URLParameters: PropTypes.string
}

const GetConnection = connect(mapStateToProps, {
	fetchCart: cart.fetchCart,
	logout,
	setModality,
	handleGTM
})

export const StoreInfo = React.memo(pipe(
	GetConnection,
	GetContent({ context: Context, id: 'store-info' })
)(StoreInfoPage))
