
import PrismaZoom from 'react-prismazoom';
import styled from 'styled-components';

export const ImageContentZoom = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-right: 1rem;
`

export const CustomPrimaZoom = styled(PrismaZoom)`
    display: flex;
    align-items: center;
    justify-content:  center;
`

export const DefaultImage = styled.img`
    border-radius: ${props => props.removeBorderRadius ? '0' : '0.5rem'};
    margin: auto;
    width: 100%;
    max-width: 100%;
`
export const Paragraph = styled.p`
    font-size: 10px;
    margin: 4px 0px 8px;
`
