import React from 'react'
import PropTypes from 'prop-types'

import {
  Button,
  DefaultImage as Image
} from '../../components'

import {
  Wrapper
} from './styles'

export function Simple(props) {
  const {
    storeLogo,
    storeName,
    handleBack,
    context
  } = props

  const actions = context && context.actions
  const backButton = actions && actions.filter(item => item.id === 'back-button')[0]
  const backButtonLabel = backButton && backButton.label

  return (
    <Wrapper>
      <Image src={storeLogo} alt={`Logo ${storeName}`} />

      <Button className="header" onClick={handleBack}>{backButtonLabel}</Button>
    </Wrapper>
  )
}

Simple.propTypes = {
	storeLogo: PropTypes.string,
	storeName: PropTypes.string,
	handleBack: PropTypes.func,
	context: PropTypes.shape({
		actions: PropTypes.arrayOf(PropTypes.shape({}))
	})
}
