import styled from 'styled-components'

export const Wrapper = styled.div`
  background: ${ props => {
    if (props.loading === true) { return (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' }

    return '#FFF'
  } };
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  margin: 0;
  max-width: 100%;
  min-width: 100%;
  padding: 1.25rem;
  position: relative;
  min-height: 100%;

  * {
    font-family: 'Nunito', 'sans-serif';
  }
`

export const ContentWrapper = styled.div`
  opacity: 0;
  transition: all .25s ease-in;
  min-width: 100%;
  max-width: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &.motion {
    opacity: 1;
  }
`

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  min-width: 100%;
`

export const LoadingImage = styled.img`
  width: 6.25rem;
  height: auto;
`

export const H1 = styled.h1`
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
  margin: 0;
  padding: 0;
  font-size: 1.125rem;
  line-height: 1.125rem;
  min-width: 100%;
  text-transform: uppercase;
  text-align: center;
  padding-left: 1.25rem;
  margin-bottom: 1.25rem;
`

export const BackButton = styled.button`
  margin: 0;
  padding: 0;
  background: none;
  border: 0 none;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
  font-size: 1rem;
  position: absolute;
  cursor: pointer;
  left: 1.25rem;
  top: 1.25rem;
  z-index: 1;
`

export const NewPaymentButton = styled.button`
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.invertedPrimaryColor) || '#fff'};
  background: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
  border-radius: .938rem;
  font-weight: bold;
  flex: 1;
  max-height: 4.375rem;
  min-height: 4.375rem;
  line-height: 4.375rem;
  min-width: 100%;
  max-width: 100%;
  padding: 0;
  margin: 0;
  font-size: 1rem;
  box-sizing: border-box;
`

export const OverlayWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 3;
  opacity: 0;
  transition: all .25s ease-in;

  &.motion {
    opacity: 1;
    pointer-events: unset;
  }

  > div:not(.background) {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0);
    z-index: 2;
  }

  > div.background {
    background: rgba(150, 150, 150, .5);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
`

export const Div = styled.div``
