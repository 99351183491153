import styled from 'styled-components'

import { fadeIn } from '../components'

export const Div = styled.div``

export const Wrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  
  padding: 1.25rem;
  box-sizing: border-box;

  ${fadeIn}

  &.motion {
    opacity: 1;
  }

  &.home {
    > div {
      &:not(.background) {
        top: 11.875rem;
        transform: translate(-50%, 0);
      }

      &.background {
        top: 11.25rem;
        border-radius: .625rem .625rem 0 0;
        max-height: calc(100% - 11.25rem);
      }
    }
  }

  > div{
    &:not(.background) {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
    }

    &.background {
      background: rgba(100, 100, 100, .7);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }
`
