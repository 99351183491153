import { actionTypes } from '.'

export const setContent = (content) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_CONTENT,
    payload: content
  })
}

export const setOpened = (opened) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_OPENED,
    payload: opened
  })
}
