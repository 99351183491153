import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  min-width: 100%;
  max-width: 100%;
	

  &.store-horizontal-menu-list{
    overflow: hidden;
    overflow-x: hidden;
    min-height: 280px;
    max-height: 280px;
    padding-left: 0;
    padding-right: 0;
    justify-content: flex-start;
		overflow-x: auto;

		::-webkit-scrollbar {
			display: none;
		}

    ::-webkit-scrollbar {
      height: 4px;
    }
    
    ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'}; 
      border-radius: 10px;
    }
    
    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#000'}; 
      -webkit-box-shadow: inset 0 0 6px ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'}; 
    }

    > .product {
      min-width: unset;

      &.center {
        text-align: none;
      }

      &.right {
        text-align: none;
      }
    }
  }

  &.store-horizontal-menu-list:hover{
    overflow-x: auto;
  }
  
  &.store-horizontal-menu-list-full{
    min-height: 100%;
    max-height: 100%;
    flex-wrap: wrap;
    margin-top: 1.25rem;
    justify-content: space-between;
    padding: 2rem;
  }
`
export const Button = styled.button`
  background: none;
  padding: 0;
  margin: 0;

  &.disabled{
    color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.greyMedium) || '#333'};
  }
`
