import React from 'react'
import PropTypes from 'prop-types'

import { Wrapper } from './styles'
import { containerMode } from './config'

export function PageContainer({
	isSideOpen,
	mode,
	children,
	styles,
	orderId
}) {
	const modeClass = isSideOpen && mode !== 'default' ? mode : ''

	return (
		<Wrapper
			orderId={orderId}
			isSideOpen={isSideOpen}
			className={modeClass}
			style={styles}
		>
			{ children }
		</Wrapper>
	)
}

PageContainer.propTypes = {
	isSideOpen: PropTypes.bool,
	mode: PropTypes.oneOf(Object.values(containerMode)),
	children: PropTypes.node,
	styles: PropTypes.arrayOf(PropTypes.shape({})),
	orderId: PropTypes.number
}
