import React, {
	useEffect
} from 'react'
import PropTypes from 'prop-types'

import {
  Wrapper,
  H1,
  Button,
  Input
} from './styles'

export function CVV(props) {
	const {
		cvv,
		handleCVV,
		handleNext
	} = props

	useEffect(() => {
    return () => {}
	}, [])

	return <Wrapper id='payment-cvv'>
		<H1>Informe o CVV do cartão selecionado</H1>

		<Input maxLength={ 9 } placeholder='' value={cvv} onKeyDown={ (event) => {
			const { keyCode } = event

			if (!(
				(keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105) ||
				keyCode === 8 ||
				keyCode === 9 || 
				keyCode === 46 || 
				keyCode === 37 || 
				keyCode === 39)) { 
				event.preventDefault()
			}
		} } onChange={ (event) => {
			const { value } = event.target

			handleCVV(value)
		} } />

		<Button onClick={ () => {
			handleNext()
		}}>Continuar</Button>
	</Wrapper>
}

CVV.propTypes = {
	cvv: PropTypes.string,
	handleCVV: PropTypes.func,
	handleNext: PropTypes.func
}
