export default ({ externalApiService }) => {
	const strategy = {
		fetchAddressByGeolocation: async ({
			actions,
			data,
			dispatch,
			getState,
			maps,
		}) => {
			const latitude = getState().address && getState().address.latitude;
			const longitude = getState().address && getState().address.longitude;

			const { setLoading } = actions;

			const { loading, key } = data;

			const { mapAddressByGeolocationData } = maps;

			if (loading && loading.includes("address-by-geolocation")) {
				return false;
			}

			dispatch(setLoading("address-by-geolocation"));

			const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${key}`;
			const result = await externalApiService.get(url);
			const mappedResult = mapAddressByGeolocationData(result.data);

			return mappedResult;
		},
		fetchAddressByCEPBackup: async ({ extraData, maps }) => {
			const { cep } = extraData;

			const formattedCep = cep.replace(/\.|-/g, "");

			const { mapAddressByCEPDataBrasilApi } = maps;

			const url = `https://brasilapi.com.br/api/cep/v2/${formattedCep}`;
			const result = await externalApiService.get(url);
			const mappedResult = mapAddressByCEPDataBrasilApi(result.data);

			return mappedResult;
		},
		fetchAddressByCEP: async ({ extraData, maps }) => {
			const { cep } = extraData;

			const formattedCep = cep.replace(/\.|-/g, "");

			const { mapAddressByCEPData } = maps;

			const url = `https://viacep.com.br/ws/${formattedCep}/json/`;
			const result = await externalApiService.get(url);
			const mappedResult = mapAddressByCEPData(result.data);

			return mappedResult;
		},
		fetchGeolocationByAddress: async ({ data, maps }) => {
			const { address, key } = data;

			const { mapGeolocationByAddressData } = maps;

			const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${key}`;
			const result = await externalApiService.get(url);
			const mappedResult = mapGeolocationByAddressData(result.data);

			return mappedResult;
		},
	};

	return {
		fetchAddressByGeolocation: async (payload) => {
			return await strategy["fetchAddressByGeolocation"](payload);
		},
		fetchAddressByCEP: async (payload) => {
			return await strategy["fetchAddressByCEP"](payload);
		},
		fetchGeolocationByAddress: async (payload) => {
			return await strategy["fetchGeolocationByAddress"](payload);
		},
	};
};
