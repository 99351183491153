import React from 'react'

import { Coupon } from '../coupon'

import {
  Wrapper
} from './styles'

export function Content({
  data,
  handleCoupon,
  couponId,
  mode
}) {
  return <Wrapper mode={mode}>
    {data ? data.map((data, index) => {
      const key = `coupons-content-list-item-${index}}`

      const {
				id,
        code,
				quickDescription,
        description,
        value,
				type,
        minValue,
        typeValueModalityDelivery,
        valueModalityDelivery,
        valueMaxModalityDelivery,
        valueMinModalityDelivery
      } = data

      return (
        <Coupon
          key={key}
					id={id}
          code={code}
          quickDescription={quickDescription}
					description={description}
          handleClick={handleCoupon}
          value={value}
          minValue={minValue}
					type={type}
          typeValueModalityDelivery={typeValueModalityDelivery}
          valueModalityDelivery={valueModalityDelivery}
          valueMaxModalityDelivery={valueMaxModalityDelivery}
          valueMinModalityDelivery={valueMinModalityDelivery}
        />
      )
    }) : null}
  </Wrapper>
}
