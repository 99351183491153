import styled from 'styled-components'

export const ModalityContainer = styled.div`
  position: absolute;
  left: auto;
  top: 50%;
  right: 10rem;
  transform: translate(0, -50%); 
  margin: 0 2rem;
  display: flex;
  ${props => {
    if (props.storeDelivery) {
      return `border: 1px solid #CECECE;`
    } else {
      return `border: none;`
    }
  }}
  background-color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textFourthColor) || '#000'};
  border-radius: .5rem;

  &.btn-group button {
    color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#000'};
    padding: 5px 15px;
    cursor: pointer;
    float: left;
    font-weight: bold;
    border-radius: .5rem;
    font-size: .8rem;

    &.active{
      background-color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.modalityButtonColor) || '#000'};
      border-radius: .5rem;
      color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.colorTextHeader) || '#000'};
      box-shadow: 2px 2px 4px 1px ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.shadowModalityButtonColor) || '#000'};
      z-index: 1;
    }
  }
`
export const Button = styled.button``
