import styled from 'styled-components'

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	background: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.invertedPrimaryColor) || '#000'};

	&.cart{
			margin-bottom:6rem;
	}
`
export const WrapperButtons = styled.div`
display: flex;
flex-direction: row;
`

export const AppDownload = styled.div`
	padding: .7rem;
	display: flex;
	flex-direction: column;
	align-items: center;

	>p{
		font-size: 14px;
		padding: 0;
		margin: 0;
		text-align: center;
		color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#000'};
		font-weight: bold;

		.noBold{
			font-weight: 400;
		}
	}

	@media (min-width: 40rem) {
		display:none;
	  }
`
export const ButtonApp = styled.div`
  cursor: pointer;
  padding: .4rem 1rem;
  background: none;
  font-size: .9rem;
  border: 1px solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
  display: flex;
  align-items: center;
  border-radius: 8px;
  margin: 10px 5px 0 0;
   
  >span{
	>p{
		font-size: .7rem;
		margin: 0;
		padding: 0;
	}
  }

  svg {
    margin-right: 10px;
    path {
      fill:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
    }
  }
`

export const Copy = styled.div`
	padding: .7rem;
	display: flex;
	flex-direction: column;
	align-items: center;

	>p{
		font-size: 14px;
		padding: 0;
		margin: 0;
		text-align: center;
		color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#000'};
		font-weight: bold;

		.noBold{
			font-weight: 400;
		}
	}
	
	>img{
		margin: .4rem 0 1rem 0;
		cursor: pointer;
	}
`
export const Link = styled.div`
	text-decoration: underline;
	cursor: pointer; 
`
