import styled from 'styled-components'

export const Wrapper = styled.div`
  grid-area: details;
  width: 100%;
  box-sizing: border-box;
  padding: 1.5rem 0;

  > div {
    display: flex;
    flex-direction: column-reverse;
  }

  @media (min-width: 50rem) {
    padding: 0 2rem 2rem 0;
    border-right: .15rem solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.grey00) || '#CCC'};

    > div {
      display: flex;
      flex-direction: column;
    }
  }
`

export const H3 = styled.h3`
  font-weight: 700;
  font-size: 1rem;
  line-height: 1rem;
  color: #6B6B6B;
  margin: 0;
  max-width: 15rem;
  text-transform: uppercase;
  display: none;

  @media (min-width: 50rem) {
    font-size: 1.25rem;
    line-height: 1.5rem;
    display: flex;
  }
`

export const Button = styled.button`
  align-items: center;
  background: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
  justify-content: center;
  color: #fff;
  padding: 1.5rem 1rem;
  border-radius: 15px;
  height: 3.5rem;
  position: relative;
  cursor: pointer;
  display: flex;
  margin: 0 1.5rem;
  min-width: calc(100% - 3rem);
  box-sizing: border-box;

  &:disabled {
    opacity: .5;
    cursor: default;
  }

  svg {
    width: 1.5rem;
  }

  @media (min-width: 50rem) {
    display: none;
  }
`

export const ButtonLabel = styled.span`
  font-weight: 700;
  font-size: .875rem;
  line-height: 1.25rem;
  margin: 0 2rem;

  @media (min-width: 60rem) {
    margin: 0;
  }
`
