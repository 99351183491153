import React, {
  useContext,
  useState
} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'

import {
	useStoreSettings,
	useDeviceLayout,
	useLayout,
	useSidebar
} from '../hooks'

import {
  logout,
  setModality
} from '../../redux/actions/main'

import { cart } from '../../state'

import {
  pipe,
  GetContent,
  caculateProductsQuantity
} from '../../domain/helpers'

import history from '../../history'

import {
  DefaultImage as Image,
  CustomIcon
} from '../components'

import {
  Cart,
  CartContainer,
  MainHeader,
  PageContainer,
  PageContainerHeader,
  PageContainerMain,
  PageContainerSide,
  Skeleton,
  Navigation,
  Cards
} from '..'

import {
  H1,
  Wrapper,
  LoadingWrapper,
  BackButton
} from './styles'

export const Context = React.createContext({})

export function MyCardsPage(props) {
  const {
    accessToken,
    addressDelivery,
    cartProducts,
    loading,
    theme,
    address,
    userName,
    storeName,
    storeImg,
    modalityId,
    setModality,
    payment,
    URLParameters
  } = props

  const storeImage = theme.headerImage
  const storeLogo = theme.logoImage

	const {
		layoutMode,
		layoutModeEnum,
		handleLayoutMode
	} = useLayout()

	const {
		sidebarContent,
		sidebarOpened,
		sidebarContentEnum,
		handleSidebarContent,
		handleSidebarOpened
	} = useSidebar()

  const context = useContext(Context)

  const newCardContent = context.sections.filter(item => item.id === 'new-card-content')[0]
  const newCardContentTitle = newCardContent.data.header.title

	const {
		url,
		title,
		description
	} = useStoreSettings()

  const isMobile = useDeviceLayout({
    isMobile: true
  })

  const [visibleSignIn, setVisibleSignIn] = useState(false)
  const [viewCards, setViewCards] = useState(false)
  const [newCard, setNewCard] = useState(false)

  async function handleModality(id) {
    setModality({ id })
  }

  function handleContactUs() {
    history.push(`/contact-us${URLParameters}`)
  }

  function handleNewCard() {
    if (!newCard) {
      setNewCard(true)
    } else {
      setNewCard(false)
    }
  }

  return (
    <Wrapper id={'my-cards'}>
			<Helmet>
				<title>{`${title} - Meus cartões`}</title>
				<meta name='description' content={description} />
				<link rel='canonical' href={`${url}/my-cards`} />
			</Helmet>

      {loading.includes('my-cards') && <LoadingWrapper id='my-cards-loading'>
        <Skeleton module='store' />
      </LoadingWrapper>}

      {!loading.includes('my-cards') && <PageContainer
        isSideOpen={sidebarOpened}
				mode={layoutMode}
      >
        <PageContainerHeader isMobile={isMobile}>
          <MainHeader
            mode='full'
            theme={theme}
            storeLogo={storeLogo}
            name={storeName}
            storeImg={storeImg}
            userName={userName}
            accessToken={accessToken}
            setVisibleSignIn={setVisibleSignIn}
            visibleSignIn={visibleSignIn}
            handleModality={handleModality}
            modality={modalityId}
            addressDelivery={address ? address : addressDelivery}
            cartItemsQuantity={caculateProductsQuantity(cartProducts)}
            homeOnClick={() => {
							handleLayoutMode(layoutModeEnum.default)
							handleSidebarContent(sidebarContentEnum.default)
							handleSidebarOpened(false)
							setViewCards(false)
						}}
						userClicked={() => {
							if (sidebarOpened && sidebarContent === sidebarContentEnum.nav) {
								handleLayoutMode(layoutModeEnum.default)
								handleSidebarContent(sidebarContentEnum.default)
								handleSidebarOpened(false)
							} else {
								handleLayoutMode(layoutModeEnum.headerFullContent)
								handleSidebarContent(sidebarContentEnum.nav)
								handleSidebarOpened(true)
							}
						}}
						cartClicked={() => {
							if (sidebarOpened && sidebarContent === sidebarContentEnum.cart) {
								handleLayoutMode(layoutModeEnum.default)
								handleSidebarContent(sidebarContentEnum.default)
								handleSidebarOpened(false)
							} else {
								handleLayoutMode(layoutModeEnum.sideFullContent)
								handleSidebarContent(sidebarContentEnum.cart)
								handleSidebarOpened(true)
							}
						}}
						isSidebarOpen={sidebarOpened}
          />
        </PageContainerHeader>
        <PageContainerMain opened={sidebarOpened}>
          <Image src={storeImage} alt="" />

          <H1>{!newCard ? context.title : newCardContentTitle}</H1>

          <BackButton className='back-button' onClick={() => {
            if (!newCard) {
              window.history.back()
            } else {
              handleNewCard(false)
            }
          }}>
            <CustomIcon name='AngleLeft' />
          </BackButton>

          <Cards payment={payment || false} newCard={newCard} handleNewCard={handleNewCard} />
        </PageContainerMain>
        <PageContainerSide opened={sidebarOpened}>
					{!isMobile && layoutMode === layoutModeEnum.sideFullContent &&
            <CartContainer logo={storeLogo}>
              <Cart
                mode='simple'
                screenMobile={isMobile}
                setViewCards={setViewCards}
                viewCards={viewCards}
                handleFinish={() => { }}
                handleClose={() => { }}
              />
            </CartContainer>}

					{layoutMode === layoutModeEnum.headerFullContent && <Navigation
						contactUs={() => handleContactUs()}
						userName={userName}
						currentRoute='my-cards'
					/>}
        </PageContainerSide>
      </PageContainer>}
    </Wrapper>
  )
}

MyCardsPage.propTypes = {
	accessToken: PropTypes.string,
	addressDelivery: PropTypes.string,
	cartProducts: PropTypes.arrayOf(PropTypes.shape({})),
	loading: PropTypes.arrayOf(PropTypes.string),
	theme: PropTypes.shape({
		headerImage: PropTypes.string,
		logoImage: PropTypes.string
	}),
	address: PropTypes.shape({}),
	userName: PropTypes.string,
	storeName: PropTypes.string,
	storeImg: PropTypes.string,
	modalityId: PropTypes.number,
	setModality: PropTypes.func,
	payment: PropTypes.bool,
	URLParameters: PropTypes.string
}

const mapStateToProps = (state) => {
  return {
    accessToken: state.user.accessToken,
    addressDelivery: state.address.addressByCEP || '',
    cartProducts: (state.main.cart && state.main.cart.products) || [],
    loading: state.loading.loading || [],
    registeredCoupons: state.main.coupons || { coupons: [] },
    address: state.address.address || '',
    userAddresses: state.userAddress.userAddresses || [],
    userHistory: state.main.userHistory || {},
    userName: state.user.name || '',
    storeImg: (state.store.store && state.store.store.logo) || '',
    modalityId: state.main.modality && state.main.modality.id,
    storeName: state.store.store && state.store.store.name,
    URLParameters: (state.main.URLParameters) || ''
  }
}

const GetConnection = connect(mapStateToProps, {
  fetchCart: cart.fetchCart,
  logout,
  setModality
})

export const MyCards = React.memo(pipe(
  GetConnection,
  GetContent({ context: Context, id: 'my-cards' })
)(MyCardsPage))
