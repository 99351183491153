import styled from 'styled-components'

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	margin: 0 auto;
	flex: 1;
	background: #fff;
	border-radius: .938rem;
	padding: 1.25rem;
	min-width: 100%;

  &.dialog {
	  min-width: calc(100% - 2.5rem);

    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &.actions {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        min-width: 100%;
        flex: 1;
        gap: 1.25rem;

        > button {
          flex: 1;
        }
      }
    }
  }

	> p {
		margin: 0;
		margin-bottom: .625rem;

		&:last-child {
			margin: 0;
		}
	}

  > div {
    min-width: 100%;
  }
`

export const H4 = styled.h1`
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  font-size: .875rem;
  line-height: .875rem;
  padding: 0;
  margin: 0;
	margin-bottom: .625rem;
	flex: 1;
	text-align: left;
`

export const Paragraph = styled.p`
	color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textTenthColor) || '#000' };
	padding: 0;
	margin: 0;
	font-size: .75rem;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	flex: 1;
	min-width: 100%;

  &.warning {
    color: #F00;
    justify-content: center;
    align-items: center;
  }
`

export const Button = styled.button`
	color: #fff;
  background: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
  border-radius: .938rem;
  font-weight: bold;
  flex: 1;
  max-height: 2.5rem;
  min-height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 .938rem;
  margin: 0;
  font-size: .875rem;
  line-height: .875rem;
  box-sizing: border-box;
`

export const Input = styled.input`
	border: 0 none;
	border-bottom: #E1E1E1 .063rem solid;
	font-size: 1.125rem;
	text-align: center;
	height: 1.875rem;
	min-height: 1.875rem;
	max-height: 1.875rem;
	line-height: 1.875rem;
	color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
	margin: 0 auto;
	flex: 0;
	margin-bottom: 1.25rem;
	min-width: 50%;
  flex: 1;

  &[type=number]::-webkit-inner-spin-button,
  &[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

export const Div = styled.div`
  min-width: 100%;
  display: flex;

  &.warning {
    > input {
      color: #f00;
    }
  }

  &.input {
    margin-bottom: 1.25rem;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    > input {
      margin-bottom: .313rem;
    }

    > p {
      font-size: .875rem;
    }
  }
`
