interface ContactUsData {
  id: number
  value: number
  type: number
  message: string
  success: boolean
}

interface ContactUsRequest {
  Mensagem: string
}

export const mapContactUsData = (data: any) : ContactUsData => {
  return {
    id: data['CupomId'],
    type: data['TipoValorCupomId'],
    value: data['Valor'],
    message: data['Mensagem'],
    success: data['Sucesso']
  }
}

export const mapContactUsRequest = (data: any) : ContactUsRequest => {
  const {
    message
  } = data

  return {
    Mensagem: message
  }
}
