import { actionTypes } from '.'

const localStorageKey = 'store'

const initialState = {
	stores: [],
	store: {},
	selectedStoreId: null,
	selectedModalities: {
		delivery: false,
		pickup: false
	},
	loyaltyPoints: false
}

const reducer = (state = initialState, action) => {
	const localStorageData = JSON.parse(localStorage.getItem(localStorageKey)) || {}
	const loadedState = {
		...state,
		...localStorageData
	}

	const newState = {
		value: loadedState
	}

	switch (action.type) {
		case actionTypes.SET_STORE:
			if (action.payload === null) {
				Object.assign(newState, {
					value: {
						...newState.value,
						store: {
							id: newState.value.store.id
						}
					}
				})
			}

			if (action.payload !== null) {
				Object.assign(newState, {
					value: {
						...newState.value,
						store: {
							...newState.value.store,
							...action.payload
						}
					}
				})
			}

			break

		case actionTypes.SET_STORE_FEES:
			if (!action.payload) {
				Object.assign(newState, {
					value: {
						...newState.value,
						store: {
							...newState.value.store,
							fees: null,
							pickupFee: null,
							deliveryFee: null,
							pickupMinimumValue: null,
							deliveryMinimumValue: null,
							deliveryTime: null
						}
					}
				})
			} else {
				Object.assign(newState, {
					value: {
						...newState.value,
						store: {
							fees: {
								loaded: true
							},
							...newState.value.store,
							...action.payload
						}
					}
				})
			}

			break

		case actionTypes.SET_STORES:
			Object.assign(newState, {
				value: {
					...newState.value,
					stores: {
						...newState.value.stores,
						list: action.payload
					}
				}
			})

			break
		case actionTypes.SET_LOYALTY_POINTS:
			Object.assign(newState, {
				value: {
					...newState.value,
					loyaltyPoints: action.payload
				}
			})

			break
		case actionTypes.SET_STORES_LIST_REQUEST:
			Object.assign(newState, {
				value: {
					...newState.value,
					stores: {
						...newState.value.stores,
						request: action.payload
					}
				}
			})

			break

		case actionTypes.SET_SELECTED_STORE_ID:
			Object.assign(newState, {
				value: {
					...newState.value,
					selectedStoreId: action.payload
				}
			})

			break

		case actionTypes.SET_STORE_MODALITIES:
			Object.assign(newState, {
				value: {
					...newState.value,
					selectedModalities: action.payload
				}
			})

			break

		case actionTypes.SET_SELECTED_CATEGORY:

			const filteredProducts = newState.value && newState.value.store && newState.value.store.categories && newState.value.store.categories.filter(filteredItem => filteredItem.name === action.payload.name)
			const newProducts = filteredProducts && filteredProducts[0] && filteredProducts[0].products

			console.log(action.payload.name)
			console.log(newState.value.categories)
			Object.assign(newState, {
				value: {
					...newState.value,
					store: {
						...newState.value.store,
						products: newProducts,
						selectedCategory: action.payload
					}
				}
			})


			break

		default:
	}

	try {
		localStorage.setItem(localStorageKey, JSON.stringify(newState.value))
	} catch (err) {
		console.error(`Nao foi possivel salvar item no localstore de chave: ${localStorageKey}`)
	}

	return newState.value
}

export default reducer
