import React, {
  useContext,
  useEffect,
  useState
} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { Dialog } from '@material-ui/core'

import {
  useStoreSettings,
  useLayout,
  useSidebar
} from '../hooks'

import {
  logout,
  setUserHistory,
  setModality,
  setReceipt
} from '../../redux/actions/main'

import {
  useDeviceLayout
} from '../hooks'

import {
  cart,
  order
} from '../../state'

import {
  pipe,
  GetContent,
  caculateProductsQuantity
} from '../../domain/helpers'

import history from '../../history'

import {
  DefaultImage as Image,
  CustomIcon
} from '../components'

import {
  MainHeader,
  PageContainer,
  PageContainerHeader,
  PageContainerMain,
  PageContainerSide,
  Cart,
  CartContainer,
  Skeleton,
  Navigation,
} from '..'

import { Content } from './content'
import { EmptyContent } from './empty-content'
import { Summary } from './summary'

import {
  LoadingWrapper,
  H2,
  Wrapper,
  H1,
  BackButton
} from './styles'

const Context = React.createContext({})

export function PlacedOrdersPage(props) {
  const {
    accessToken,
    addressDelivery,
    cartProducts,
    fetchOrderHistory,
    loading,
    order,
    orderCpf,
    orderHistory,
    setOrder,
    storeName,
    theme,
    address,
    userHistory,
    userName,
    match,
    setUserHistory,
    storeImg,
    modalityId,
    setModality,
    URLParameters
  } = props

  const storeImage = theme.headerImage
  const storeLogo = theme.logoImage

  const {
    layoutMode,
    layoutModeEnum,
    handleLayoutMode
  } = useLayout()

  const {
    sidebarContent,
    sidebarOpened,
    sidebarContentEnum,
    handleSidebarContent,
    handleSidebarOpened
  } = useSidebar()

  const context = useContext(Context)

  const {
    url,
    title,
    description
  } = useStoreSettings()

  const isMobile = useDeviceLayout({
    isMobile: true
  })

  const stateOrderId = order && order.id
  const orderId = match.params && Number(match.params.orderId)
  const { sections } = useContext(Context)
  const [visibleSignIn, setVisibleSignIn] = useState(false)
  const [viewCards, setViewCards] = useState(false)
  const [visiblePlacedOrder, setVisiblePlacedOrder] = useState(false)

  const { back } = userHistory
  const { orders = [] } = orderHistory

  const inProgressOrders = orders && orders.filter(filteredItem => filteredItem.inProgress === true)
  const finishedOrders = orders && orders.filter(filteredItem => filteredItem.inProgress === false)

  const inProgressOrdersSection = sections && sections.filter(item => item.id === 'in-progress-orders')[0]
  const finishedOrdersSection = sections && sections.filter(item => item.id === 'finished-orders')[0]

  const inProgressOrdersSelectedSectionTitle = inProgressOrdersSection.title

  const finishedOrdersSelectedSectionName = finishedOrders && finishedOrders.length ? 'main-content' : 'empty-content'
  const finishedOrdersSelectedSectionTitle = finishedOrdersSection.title
  const finishedOrdersSelectedSection = finishedOrdersSection.content.filter(item => item.id === finishedOrdersSelectedSectionName)[0]

  useEffect(() => {
		setOrder(null)
    setTimeout(() => fetchOrderHistory(), 500)
  }, [
		setOrder,
    fetchOrderHistory
  ])

	useEffect(() => {
		if (isMobile && orderId > 0) {
			if (orderId && orderId !== stateOrderId) {
				const selectedOrder = orderHistory && orderHistory.orders && orderHistory.orders.filter(item => item.id === orderId)[0]

				setOrder({
					id: orderId,
					...selectedOrder
				})
			}
			
			setVisiblePlacedOrder(true)

			return
		}
	}, [
		stateOrderId,
		orderId, 
		isMobile, 
		orderHistory,
		setOrder
	])

  useEffect(() => {
    if (orderId && orderId !== stateOrderId) {
      handleLayoutMode(layoutModeEnum.headerFullContent)
      handleSidebarContent(sidebarContentEnum.order)
      handleSidebarOpened(true)

      const selectedOrder = orderHistory && orderHistory.orders && orderHistory.orders.filter(item => item.id === orderId)[0]

      setOrder({
        id: orderId,
        ...selectedOrder
      })
    }
  }, [
    isMobile,
    orderCpf,
    orderId,
    orderHistory,
    setOrder,
    handleLayoutMode,
    handleSidebarContent,
    handleSidebarOpened,
    sidebarContentEnum,
    layoutModeEnum,
    stateOrderId
  ])

  async function handleModality(id) {
    setModality({ id })
  }

  function handleCloseDialogPlace() {
    setVisiblePlacedOrder(false)
  }

  function handleContactUs() {
    history.push(`/contact-us${URLParameters}`)
  }

  function handleOrder(id) {
    setUserHistory({
      back: 'placed-orders'
    })
    history.push(`/placed-orders/${id}${URLParameters}`)
  }

  const summary = () => {
    return <Summary
      screenMobile={isMobile}
      modalityId={order.modalityId}
      inProgress={order.inProgress}
      storeLogo={order.store && order.store.logoUrl}
      storeName={order.store && order.store.name}
      deliveryAddress={order.deliveryPlace}
      pickupAddress={order.store && order.store.address}
      date={order.store && order.store.date}
      whatsapp={order.store && order.store.whatsapp}
      phone={order.store && order.store.phone}
			payOnDelivery={order.store && order.store.payOnDelivery}
      products={{
        list: order.items && order.items.map(item => ({
          product: item.product,
          description: item.description,
          value: item.value,
          quantity: item.quantity
        })),
        summary: {
          total: order.amountPaid,
          deliveryFee: order.serviceFee,
          discount: order.discount,
          subtotal: order.amountPaid - order.serviceFee
        }
      }}
      cpf={orderCpf}
      hasCpf={order.hasCpf}
      cardBrand={order.cardBrand}
      cardNumber={order.cardNumber}
      handleTrackOrder={() => {
        setOrder({
          id: orderId
        })

        history.push(`/receipt${URLParameters}`)
      }}
    />
  }

  return (
    <Wrapper id={'placed-orders'}>
      <Helmet>
        <title>{`${title} - Meus pedidos`}</title>
        <meta name='description' content={description} />
        <link rel='canonical' href={`${url}/placed-orders`} />
      </Helmet>

      {loading.includes('orders-history') && <LoadingWrapper id='placed-orders-loading'>
        <Skeleton module='store' />
      </LoadingWrapper>}

      {!loading.includes('orders-history') && <PageContainer
        orderId={orderId}
        isSideOpen={sidebarOpened}
        mode={layoutMode}
      >
        <H1>{context.title}</H1>

        <BackButton className='back-button' onClick={() => {
          if (back === 'placed-orders' && !orderId && !isMobile) {
            const back = userHistory.navigation && userHistory.navigation[userHistory.navigation.length - 1]
            history.push(`/${back}${URLParameters}`)

            return
          }

          if (back && back !== 'placed-orders') {
            history.push(`/${back}${URLParameters}`)

            return
          }

          history.push(`/`)
        }}>
          <CustomIcon name='AngleLeft' />
        </BackButton>

        <PageContainerHeader itemId={orderId} isMobile={isMobile}>
          <MainHeader
            mode='full'
            theme={theme}
            storeLogo={storeLogo}
            name={storeName}
            modality={modalityId}
            storeImg={storeImg}
            userName={userName}
            accessToken={accessToken}
            setVisibleSignIn={setVisibleSignIn}
            handleModality={handleModality}
            visibleSignIn={visibleSignIn}
            addressDelivery={address ? address : addressDelivery}
            cartItemsQuantity={caculateProductsQuantity(cartProducts)}
            homeOnClick={() => {
              handleLayoutMode(layoutModeEnum.default)
              handleSidebarContent(sidebarContentEnum.default)
              handleSidebarOpened(false)
              setViewCards(false)
            }}
            userClicked={() => {
              if (sidebarOpened && sidebarContent === sidebarContentEnum.nav) {
                handleLayoutMode(layoutModeEnum.default)
                handleSidebarContent(sidebarContentEnum.default)
                handleSidebarOpened(false)
                history.push('/placed-orders')
                setOrder({ id: 0 })

              } else {
                handleLayoutMode(layoutModeEnum.headerFullContent)
                handleSidebarContent(sidebarContentEnum.nav)
                handleSidebarOpened(true)
                history.push('/placed-orders')
                setOrder({ id: 0 })
              }
            }}
            cartClicked={() => {
              if (sidebarOpened && sidebarContent === sidebarContentEnum.cart) {
                handleLayoutMode(layoutModeEnum.default)
                handleSidebarContent(sidebarContentEnum.default)
                handleSidebarOpened(false)
                history.push('/placed-orders')
                setOrder({ id: 0 })
              } else {
                handleLayoutMode(layoutModeEnum.sideFullContent)
                handleSidebarContent(sidebarContentEnum.cart)
                handleSidebarOpened(true)
                history.push('/placed-orders')
                setOrder({ id: 0 })
              }
            }}
            isSidebarOpen={sidebarOpened}
          />
        </PageContainerHeader>
        <PageContainerMain opened={sidebarOpened}>
          <Image src={storeImage} alt="" />

          {inProgressOrders && inProgressOrders.length ? <>
            <H2>{inProgressOrdersSelectedSectionTitle}</H2>
            <Content data={inProgressOrders} orderId={orderId} handleOrder={handleOrder} />
          </> : null}

          {finishedOrders && finishedOrders.length ? <>
            <H2>{finishedOrdersSelectedSectionTitle}</H2>

            <Content data={finishedOrders} orderId={orderId} handleOrder={handleOrder} />
          </> : null}

          {inProgressOrders && finishedOrders && !inProgressOrders.length && !finishedOrders.length ?
            <EmptyContent URLParameters={URLParameters} data={{ ...finishedOrdersSelectedSection }} /> :
            null}
        </PageContainerMain>
        {!isMobile && <PageContainerSide orderId={orderId} opened={sidebarOpened} className={orderId ? 'placed-order' : ''}>
          {!isMobile && sidebarContent === sidebarContentEnum.cart && !orderId &&
            <CartContainer logo={storeLogo}>
              <Cart
                mode='simple'
                screenMobile={isMobile}
                setViewCards={setViewCards}
                viewCards={viewCards}
                handleFinish={() => { }}
                handleClose={() => { }}
              />
            </CartContainer>}

          {!isMobile && layoutMode === layoutModeEnum.headerFullContent && sidebarContent === sidebarContentEnum.nav && !orderId && <Navigation
            contactUs={() => handleContactUs()}
            userName={userName}
            currentRoute='placed-orders'
          />}

          {orderId && sidebarContent === sidebarContentEnum.order && !isMobile && summary()}
        </PageContainerSide>}
				<Dialog
            onClose={() => handleCloseDialogPlace()}
            aria-labelledby="place-order-dialog"
            open={visiblePlacedOrder}
            maxWidth={'sm'}
            fullWidth={true}
            fullScreen={true}
          >
            {summary()}
          </Dialog>
      </PageContainer>}
    </Wrapper>
  )
}

PlacedOrdersPage.propTypes = {
  accessToken: PropTypes.string,
  addressDelivery: PropTypes.shape({}),
  cartProducts: PropTypes.arrayOf(PropTypes.shape({})),
  fetchOrderHistory: PropTypes.func,
  loading: PropTypes.arrayOf(PropTypes.string),
  order: PropTypes.shape({
    id: PropTypes.number,
    modalityId: PropTypes.number,
    inProgress: PropTypes.bool,
    store: PropTypes.shape({
      logoUrl: PropTypes.string,
      name: PropTypes.string,
      address: PropTypes.string,
      phone: PropTypes.string,
      date: PropTypes.string,
      whatsapp: PropTypes.string
    }),
    deliveryPlace: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({})),
    amountPaid: PropTypes.number,
    serviceFee: PropTypes.number,
    discount: PropTypes.number,
    hasCpf: PropTypes.bool,
    cardBrand: PropTypes.string,
    cardNumber: PropTypes.string
  }),
  orderCpf: PropTypes.string,
  orderHistory: PropTypes.shape({
    orders: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  setOrder: PropTypes.func,
  storeName: PropTypes.string,
  theme: PropTypes.shape({
    headerImage: PropTypes.string,
    logoImage: PropTypes.string
  }),
  address: PropTypes.shape({}),
  userHistory: PropTypes.shape({
    back: PropTypes.string,
    next: PropTypes.string,
    navigation: PropTypes.arrayOf(PropTypes.string)
  }),
  userName: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      orderId: PropTypes.string
    })
  }),
  setUserHistory: PropTypes.func,
  storeImg: PropTypes.string,
  modalityId: PropTypes.number,
  setModality: PropTypes.func,
  URLParameters: PropTypes.string
}

const mapStateToProps = (state) => {
  return {
    accessToken: state.user.accessToken,
    addressDelivery: state.address.addressByCEP || '',
    cartProducts: (state.main.cart && state.main.cart.products) || [],
    loading: state.loading.loading || [],
    order: state.main.order || {},
    orderCpf: state.main.order && state.main.order.cpf,
    orderHistory: state.main.orderHistory || { orders: [] },
    storeName: (state.store.store && state.store.store.name) || '',
    address: state.address.address || '',
    userAddresses: state.userAddress.userAddresses || [],
    userHistory: state.main.userHistory || {},
    userName: state.user.name || '',
    storeImg: (state.store.store && state.store.store.logo) || '',
    modalityId: state.main.modality && state.main.modality.id,
    URLParameters: (state.main.URLParameters) || ''
  }
}

const GetConnection = connect(mapStateToProps, {
  fetchCart: cart.fetchCart,
  fetchOrderHistory: order.fetchOrderHistory,
  logout,
  setOrder: order.setOrder,
  setUserHistory,
  setModality,
  setReceipt
})

export const PlacedOrders = React.memo(pipe(
  GetConnection,
  GetContent({ context: Context, id: 'placed-orders' })
)(PlacedOrdersPage))
