import React from 'react'
import Logo from './../../assets/VQP_logo.png'
import {
	connect
} from 'react-redux'

import {
	calculateItemTotal,
	pipe
} from '../../../domain/helpers'
import {
	Wrapper,
	Copy,
	Link,
	AppDownload,
	ButtonApp,
	WrapperButtons
} from './styles'

import { ReactComponent as IconIOS } from '../../assets/icon_ios.svg'
import { ReactComponent as IconAndroid } from '../../assets/google_play.svg'
import { APP_SHOW_BUTTON, URL_DOWNLOAD_APP } from '../../../config'

const openApp = () => {
	window.open(URL_DOWNLOAD_APP);
}

const openInstitutional = () => {
	window.open(`https://institucional.voceqpad.com.br/`);
}

const openTerms = () => {
	window.open(`https://institucional.voceqpad.com.br/termoscondicoes.html/`)
}

const calculateCartTotal = (items) => {
	const total = items && items.reduce((acc, item) => acc + calculateItemTotal(item), 0)

  return total
}

function FooterComponent(props) {
	const { data } = props
	const valueTotal = calculateCartTotal(data)

	return <Wrapper id='footer' className={valueTotal ? 'cart' : ''}>
	{APP_SHOW_BUTTON === 'true' && (
			<AppDownload>
						Baixe nosso app:

						<WrapperButtons>
							<ButtonApp onClick={() => openApp()}>
								<span>
									<IconIOS />
								</span>
								<span>
									<p>Available on the</p>
									<strong>App Store</strong>
								</span>
							</ButtonApp>
							<ButtonApp onClick={() => openApp()}>
								<span>
									<IconAndroid />
								</span>
								<span>
									<p>Available on the</p>
									<strong>Google Play</strong>
								</span>
							</ButtonApp>
						</WrapperButtons>

					</AppDownload>
	)}
		<Copy>
			<p>Desenvolvido por</p>
			<img src={Logo} width="110px" alt="Logo Voceqpad" onClick={() => openInstitutional()} />
			<p className='noBold'>Termo de uso <br />
				<Link onClick={() => openTerms()}>Termos e políticas VocêQpad</Link>
			</p>
		</Copy>
	</Wrapper>
}

const mapStateToProps = (state) => {
	return {
		data: (state.main.cart && state.main.cart.products) || []
	}
}

const GetConnection = connect(mapStateToProps, {})

export const Footer = React.memo(pipe(
	GetConnection
)(FooterComponent))
