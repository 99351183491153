interface HighlightsList  {
  description: string
  url: string
  image: string
  deepLink: boolean
}

export const mapHighlightsList = (data: any) : HighlightsList[] | [] => {
  const highlights = (data['Destaques'] && data['Destaques'].length && data['Destaques'].map((mappedItem: any) => {
    return {
      description: mappedItem['Descricao'],
      url: mappedItem['Url'],
      image: mappedItem['Imagem'],
      deepLink: mappedItem['DeepLink']
    }
 })) || []

	return highlights
}
