export const PAYMENT_TYPES = {
    PAYPAL: 1,
    CREDIT_CARD: 2,
    TICKET_VR: 3,
    ALELO: 4,
    VR: 5,
    VISA_CHECKOUT: 6,
    PROMOTIONAL: 7,
    VOCE_Q_PAD: 8,
    PROMOTIONAL_COUPON: 9,
    DEBIT_CARD: 10,
    CASH: 11,
    BANK_TRANSFER: 12,
    BEN: 13,
    SODEXO: 14
}

export const CARD_TYPES = {
    CREDIT_CARD: 1,
    VISA_CHECKOUT: 2,
    VOUCHER: 3
}

export const PAYMENT_METHOD_NAMES = {
    [PAYMENT_TYPES.PAYPAL]: 'PayPal',
    [PAYMENT_TYPES.CREDIT_CARD]: "Cartão de Crédito",
    [PAYMENT_TYPES.TICKET_VR]: "Ticket Restaurante",
    [PAYMENT_TYPES.ALELO]: "Alelo",
    [PAYMENT_TYPES.VR]: "VR",
    [PAYMENT_TYPES.VISA_CHECKOUT]: "Visa Checkout",
    [PAYMENT_TYPES.PROMOTIONAL]: "Promocional",
    [PAYMENT_TYPES.VOCE_Q_PAD]: "VoceQPad",
    [PAYMENT_TYPES.PROMOTIONAL_COUPON]: "Promocional Cupom",
    [PAYMENT_TYPES.DEBIT_CARD]: "Cartão de Débito",
    [PAYMENT_TYPES.CASH]: "Dinheiro",
    [PAYMENT_TYPES.BANK_TRANSFER]: "Transferência Bancária",
    [PAYMENT_TYPES.BEN]: "Ben",
    [PAYMENT_TYPES.SODEXO]: "Sodexo"
}

export const CARD_BRANDS_IDS = {
    DINERS: 2,
    VISA: 3,
    MASTERCARD: 4,
    AMEX: 5,
    DISCOVER: 15,
    ELO: 16,
    AURA: 18,
    JCB: 19,
    VR: 27,
    ALELO: 28,
    BEN: 29,
    SODEXO: 30,
    HIPERCARD: 31,
    TICKET_VR: 50,
    TICKET_VA: 51,
    CASH: 0
}

export const CARD_BRANDS_NAMES = {
    [CARD_BRANDS_IDS.DINERS]: "Diners Club",
    [CARD_BRANDS_IDS.VISA]: "Visa",
    [CARD_BRANDS_IDS.MASTERCARD]: "Mastercard",
    [CARD_BRANDS_IDS.AMEX]: "American Express",
    [CARD_BRANDS_IDS.DISCOVER]: "Discover",
    [CARD_BRANDS_IDS.ELO]: "Elo",
    [CARD_BRANDS_IDS.AURA]: "Aura",
    [CARD_BRANDS_IDS.JCB]: "JCB",
    [CARD_BRANDS_IDS.VR]: "VR Refeição",
    [CARD_BRANDS_IDS.ALELO]: "Alelo",
    [CARD_BRANDS_IDS.BEN]: "Ben",
    [CARD_BRANDS_IDS.SODEXO]: "Sodexo",
    [CARD_BRANDS_IDS.HIPERCARD]: "Hipercard",
    [CARD_BRANDS_IDS.TICKET_VR]: "Ticket Refeição",
    [CARD_BRANDS_IDS.TICKET_VA]: "Ticket Alimentação"
}

export const CARD_BRANDS_TAG = {
    [CARD_BRANDS_IDS.DINERS]: "diners",
    [CARD_BRANDS_IDS.VISA]: "visa",
    [CARD_BRANDS_IDS.MASTERCARD]: "mastercard",
    [CARD_BRANDS_IDS.AMEX]: "amex",
    [CARD_BRANDS_IDS.DISCOVER]: "discover",
    [CARD_BRANDS_IDS.ELO]: "elo",
    [CARD_BRANDS_IDS.AURA]: "aura",
    [CARD_BRANDS_IDS.JCB]: "jcb",
    [CARD_BRANDS_IDS.VR]: "vr",
    [CARD_BRANDS_IDS.ALELO]: "alelo",
    [CARD_BRANDS_IDS.BEN]: "ben",
    [CARD_BRANDS_IDS.SODEXO]: "sodexo",
    [CARD_BRANDS_IDS.HIPERCARD]: "hipercard",
    [CARD_BRANDS_IDS.TICKET_VR]: "ticket",
    [CARD_BRANDS_IDS.TICKET_VA]: "ticket"
}

export const COUPON_TYPES = {
    ABSOLUTE: 1,
    PERCENTAGE: 2
}
