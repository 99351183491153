import React from 'react'

import {
  VerticalProduct,
  HorizontalProduct,
  VerticalCardProduct
} from '..'
import { createUuid } from '../../../domain/helpers/Uuid'

export function Product(props) {
  const {
    direction,
    index,
    id,
    name,
    description,
    image,
    storeLogo,
    value,
    rawPrice,
    rawPromocional,
    handleQuantity,
    handleView: propsHandleView,
    handleAdd: propsHandleAdd,
    additionals,
    selectedAdditionals,
    selectedCategory,
    quantity,
    promotionalValue,
    status,
    className,
    storeImg,
    categories,
    opened,
		hasRequiredAdditionals,
    tags,
    showFromValueText,
    fromValue,
    categorieLength,
    loyaltyModelPointsId,
    loyaltyPoints
  } = props

  function handleView(event) {
    event.preventDefault()

    propsHandleView({
      index,
      id,
      name,
      description,
      image,
      value: rawPrice,
      quantity: 1,
      additionals,
      selectedAdditionals,
      category: selectedCategory,
      promotionalValue: rawPromocional,
      showFromValueText,
      fromValue,
      loyaltyModelPointsId,
      loyaltyPoints
    })
  }

  function handleAdd(event) {
    event.preventDefault()

    propsHandleAdd({
      index,
      id,
			uuid: createUuid(),
      name,
      description,
      image,
      value: rawPrice,
      quantity: 1,
      additionals,
      selectedAdditionals,
      category: selectedCategory,
      promotionalValue: rawPromocional,
      loyaltyModelPointsId,
      loyaltyPoints
    })
  }

  const newProps = {
		index,
    id,
    name,
    description,
    image,
    value,
		rawPrice,
    quantity,
    handleQuantity,
    promotionalValue,
    handleView,
    handleAdd,
    storeLogo,
    status,
    className,
    storeImg,
    categories: categories ? categories : false,
    opened,
		hasRequiredAdditionals,
		additionals,
		selectedAdditionals,
		category: selectedCategory,
    tags,
    showFromValueText,
    fromValue,
    categorieLength,
    loyaltyModelPointsId,
    loyaltyPoints
  }

  return <>
    {direction === 'vertical' ? <VerticalProduct {...newProps} /> : null}
    {direction === 'horizontal' ? <HorizontalProduct {...newProps} /> : null}
    {direction === 'card' ? <VerticalCardProduct {...newProps} /> : null}
  </>
}
