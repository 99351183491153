import {
	loading,
	store
} from '..'

import { actionTypes } from '.'

export const set = (payload) => async (dispatch) => {
  dispatch({
    type: actionTypes.SET_MENU,
    payload
  })
}

export const setSearchTerm = (payload) => async (dispatch) => {
  dispatch({
    type: actionTypes.SET_SEARCH_TERM,
    payload
  })
}

export const setCategories = (payload) => async (dispatch) => {
  dispatch({
    type: actionTypes.SET_MENU_CATEGORIES,
    payload
  })
}

export const setSelectedProduct = (product) => async (dispatch) => {
  dispatch({
    type: actionTypes.SET_SELECTED_PRODUCT,
    payload: product
  })
}

export const removeSelectedProductAdditional = (productAdditional) => async (dispatch) => {
  dispatch({
    type: actionTypes.REMOVE_SELECTED_PRODUCT_ADDITIONAL,
    payload: productAdditional
  })
}

export const fetchSearch = (callback) => (dispatch, getState, container) => {
	const callbackErrorFilter = callback && callback.filter(filteredItem => filteredItem.name === 'menu-search' && filteredItem.type === 'error')
	const callbackError =  callbackErrorFilter && callbackErrorFilter[0] && callbackErrorFilter[0].callback
	const callbackSuccessFilter = callback && callback.filter(filteredItem => filteredItem.name === 'menu-search' && filteredItem.type === 'success')
	const callbackSuccess =  callbackSuccessFilter && callbackSuccessFilter[0] && callbackSuccessFilter[0].callback

	const stateLoading = (getState().loading && getState().loading.loading) || []
	
	const searchTerm = (getState().menu && getState().menu.searchTerm) || null
	const storeId = (getState().main.store && getState().main.store.id) || null
	const modalityId = (getState().main.modality && getState().main.modality.id) || null

	const done = () => {
		dispatch(loading.setLoading({item: 'menu-search', delete: true}))
	}

  try {
		if (stateLoading && stateLoading.includes('menu-search')) {
			throw new Error({loading: true})
		}
		
		dispatch(loading.setLoading('menu-search'))

    const data = {
      searchTerm,
			storeId,
			modalityId
    }

		if (!searchTerm) {
			dispatch(store.fetch([{
				name: 'store',
				type: 'error',
				callback: () => {
					done()
				}
			}, {
				name: 'store',
				type: 'success',
				callback: () => {
					done()
				}
			}]))

			return
		}

    return container.fetchSearch({
      onSuccess: ({search}) => {
				const {
					categories,
					success
				} = search

				if (success) {
					dispatch(setCategories(categories))

					callbackSuccess && callbackSuccess()
					done()
				} else {
					throw new Error()
				}
      },
      onError: () => {
        callbackError && callbackError()
				done()
      },
      onEmpty: async () => {
        callbackError && callbackError()
				done()

				await dispatch(store.fetch())
      },
      data
   })
  } catch (e) {
		console.log(e)

		if (e.loading === true) {
			console.warn(`menuActions.fetchSearch is already loading`)
			
			return
		} else {
			callbackError && callbackError()
			done()
		}
  }
}
