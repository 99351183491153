import React, {
	useEffect
} from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import {
	pipe
} from '../../../domain/helpers'

import {
	menuActions
} from '../../../state'

import {
	CustomIcon
} from '../../components'

import {
	Wrapper,
	Input,
	Button
} from './styles'

export function SearchComponent(props) {
	const {
		searchTerm,
		setSearchTerm,
		fetchSearch
	} = props

	function handleSearch() {
		fetchSearch()
	}

	function handleSearchText(args) {
		setSearchTerm(args)
	}

	useEffect(() => {
		return () => { }
	}, [])

	return <Wrapper id='store-search'>
		<Input placeholder='Buscar algo' value={searchTerm} onKeyDown={(event) => {
			const {
				keyCode
			} = event

			if (keyCode === 13) {
				handleSearch()
			}
		}} onChange={(event) => {
			const {
				value
			} = event.target

			handleSearchText(value)
		}} />

		<Button onClick={() => {
			handleSearch()
		}}>
			<CustomIcon name='Search' />
		</Button>
	</Wrapper>
}

SearchComponent.propTypes = {
	searchTerm: PropTypes.string,
	setSearchTerm: PropTypes.func,
	fetchSearch: PropTypes.func
}

const mapStateToProps = (state) => {
	return {
		allCategories: state.menu.allCategories || [],
		searchTerm: state.menu.searchTerm || ''
	}
}

const GetConnection = connect(mapStateToProps, {
	setCategories: menuActions.setCategories,
	fetchSearch: menuActions.fetchSearch,
	setSearchTerm: menuActions.setSearchTerm
})

export const Search = React.memo(pipe(
	GetConnection
)(SearchComponent))
