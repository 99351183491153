import { actionTypes } from '..'

export const setStoreSettings = (storeSettings) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_STORE_SETTINGS,
    payload: storeSettings
 })
}

export const fetchStoreSettings = (callback) => (dispatch, getState, container) => {
  try {
		return container.fetchStoreSettings({
      onSuccess: ({ storeSettings }) => {
				dispatch(setStoreSettings(storeSettings))
      },
      onError: () => {
        dispatch(setStoreSettings({
					error: true
				}))
      },
      onEmpty: () => {
        dispatch(setStoreSettings({}))
      }
   })
  } catch (e) {
    console.log(e)

    dispatch(setStoreSettings({}))
  } finally {}
}
