import React, {
  useEffect
} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'

import {
  pipe,
  GetContent
} from '../../../domain/helpers'

import {
  setUserHistory
} from '../../../redux/actions/main'

import {
  store,
  loading
} from '../../../state'

import {
  CustomLoading
} from '../../components'

import AddressPinIcon from '../../assets/ipiranga/address-pin-icon.png'

import {
  Wrapper,
  ContentWrapper,
  Image,
  Paragraph,
  Span
} from './styles'
import { ESTABELECIMENTO_ID, MULTI_STORE } from '../../../config'

export const Context = React.createContext({})

function SplashPage(props) {
  const {
    loading,
    setStore,
    currentStep,
    stores,
    addressByCEP,
		address
  } = props

  useEffect(() => {
    if (MULTI_STORE === 'false' && currentStep === 2) {
      setStore({ id: ESTABELECIMENTO_ID })
    }

    if (MULTI_STORE === 'true' && stores && stores.list && currentStep === 2) {
      stores.list && stores.list[0] && stores.list[0].id && setStore({
        id: stores.list[0].id
      })
    }

    return () => ({})
  }, [
    stores,
    setStore,
    currentStep,
  ])

  return <Wrapper>
    {loading.includes('welcome') &&
      <CustomLoading
        color={'#fff'}
        type={'spin'}
        id='default-loading'
        height={40}
        width={40}
      />}

    {!loading.includes('welcome') && ((addressByCEP && addressByCEP.main) || (address && address.main)) && <ContentWrapper id='welcome-splash-content'>
      <Paragraph>
        <Image src={AddressPinIcon} alt='Ícone de mapa' />
        <Span>{((addressByCEP && addressByCEP.main) || (address && address.main)) || '…'}</Span>
      </Paragraph>
    </ContentWrapper>}
  </Wrapper>
}

SplashPage.propTypes = {
  loading: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string])),
  storeId: PropTypes.number,
	setStore: PropTypes.func,
	currentStep: PropTypes.number,
	stores: PropTypes.shape({
		list: PropTypes.arrayOf(PropTypes.shape({
			id: PropTypes.number
		}))
	}),
	addressByCEP: PropTypes.shape({
		main: PropTypes.shape({})
	}),
	address: PropTypes.shape({
		main: PropTypes.shape({})
	})
}

const mapStateToProps = (state) => {
  return {
		address: (state.address.address) || {},
    addressByCEP: (state.address.addressByCEP) || {},
    loading: state.loading.loading || [],

    stores: state.store.stores || [],
    storeId: (state.main.store && state.main.store.id) || 0,
    URLParameters: (state.main.URLParameters) || ''
  }
}

const GetConnection = connect(mapStateToProps, {
  fetchStores: store.fetchStores,
  setStore: store.setStore,
  setLoading: loading.setLoading,

  setUserHistory
})

export const Splash = React.memo(pipe(
  withTheme,
  GetConnection,
  GetContent({ context: Context, id: 'welcome' })
)(SplashPage))
